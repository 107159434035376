.landing__solution {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    margin: 4rem 6rem;
    background: var(--color-blue-main-2);
    background: -moz-radial-gradient(circle at 30% -100%, var(--color-blue-main-2) 25%, rgba(4, 44, 84, 1) 85%, var(--color-blue-main-3) 100%);
    background: -webkit-radial-gradient(circle at 30% -100%, var(--color-blue-main-2) 25%, rgba(4, 44, 84, 1) 85%, var(--color-blue-main-3) 100%);
    background: -o-radial-gradient(circle at 30% -100%, var(--color-blue-main-2) 25%, rgba(4, 44, 84, 1) 85%, var(--color-blue-main-3) 100%);
    background: -ms-radial-gradient(circle at 30% -100%, var(--color-blue-main-2) 25%, rgba(4, 44, 84, 1) 85%, var(--color-blue-main-3) 100%);
    background: radial-gradient(circle at 30% -100%, var(--color-blue-main-2) 25%, rgba(4, 44, 84, 1) 85%, var(--color-blue-main-3) 100%);
}

a.anchor {
    display: block;
    position: relative;
    top: -150px;
    visibility: hidden;
}

/* TOPLINE */
.landing__solution_topline_container {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; */
    margin: 4rem 0 2rem;
}

.landing__solution_topline_container h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
}

/* LIST */
.landing__solution_list_container {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    justify-content: space-between;
}

.landing__solution_list_item {
    width: 20%;
}

.landing__solution_list_item p {
    margin-top: 1rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-blue-secondary-1);
}


/* RESPONSIVE */

@media screen and (max-width: 1050px) {

    .landing__solution_list_item p {
        font-size: 12px;
    }
}

@media screen and (max-width: 700px) {

    .landing__solution {
        margin: 2rem 3rem;
    }

    .landing__solution_list_container {
        flex-direction: column;
        /* justify-content: space-between; */
    }

    .landing__solution_list_item {
        width: 100%;
    }

    .landing__solution_list_item p {
        margin-top: 0.2rem;
        margin-bottom: 1rem;
    }

}

@media screen and (max-width: 550px) {

    .landing__solution {
        margin: 2rem 2rem;
    }

    .landing__solution_topline_container h1 {
        font-size: 28px;
        line-height: 32px;
    }

}