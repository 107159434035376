.landing__cta_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 2rem;
    /* margin: 4rem; */
    border-radius: 1rem;
    background: var(--gradient-green-bar)
}

.landing__cta_content {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #062134;
}

.landing__cta_content p {
    /* font-family: var(--font-family); */
    width: fit-content;
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
    cursor: pointer;
}

.landing__cta_content h3 {
    /* font-family: var(--font-family); */
    font-size: 24px;
    line-height: 30px;
    font-weight: 800;
}

.landing__cta_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    min-width: 232px;
}

.landing__cta_btn button {
    background: #0E1B25;
    color: #FFF;
    /* font-family: var(--font-family); */
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1.5rem;
    border-radius: 2rem;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}



/* RESPONSIVE */

@media screen and (max-width: 1050px) {
    .landing__cta_container {
        flex-direction: column;
        align-items: flex-start;
    }

    .landing__cta_btn {
        margin-left: 0;
        margin-top: 1.5rem;
    }
}

/* @media screen and (max-width: 700px) {} */

@media screen and (max-width: 550px) {
    .landing__cta_content p {
        display: none;
    }
}