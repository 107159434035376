.app__geocoder {
    position: absolute;
    top: 15px;
    left: 670px;
    width: 450px;
    max-width: calc(100% - 115px);
    z-index: 10001;
    transition: all 0.8s;
}

.app__geocoder_left {
    transform: translateX(-635px);
}

.app___geocoder_container {
    height: 36px !important;
    min-height: auto !important;
    border-radius: 0.4rem !important;
    padding: 0px 10px !important;
    display: flex;
    align-items: center;
}

.app__geocoder_close {
    margin-left: 6px;
    height: 12px;
    opacity: 0.6;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.app__geocoder_close:hover {
    opacity: 1;
}

.app__geocoder_close_hidden {
    height: 0px;
    margin-left: 0px;
}

/* LOADER */
.app__geocoder_loader_container {
    position: relative;
    height: 18px;
    width: 18px;
    margin-right: 10px;
}

.app__geocoder_loader_circle {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 13px;
    height: 13px;
    background-color: #fff;
    border: 2px solid grey;
    border-right-color: grey;
    border-radius: 50%;
    transform: rotate(45deg);
    transition: width 0.2s ease 0.1s, height 0.2s ease 0.1s;
}

.app__geocoder_loader_circle_loading {
    width: 18px;
    height: 18px;
    border-right-color: var(--color-green-main-1);
    transform: rotate(45deg);
    animation: rotating 2s 0.5s linear infinite;
    transition: border-color 0.3s ease 0.2s, width 0.2s ease 0.3s, height 0.2s ease 0.3s;
}

@keyframes rotating {
    from {
        transform: rotate(45deg);
    }

    to {
        transform: rotate(405deg);
    }
}

.app__geocoder_loader_line {
    background-color: grey;
    position: absolute;
    top: 10px;
    left: 9px;
    height: 11px;
    width: 2px;
    border-radius: 2px;
    transform-origin: top left;
    transform: rotate(315deg);
    transition: background-color 0.1s, height 0.2s ease 0.1s;
}

.app__geocoder_loader_line_loading {
    background-color: var(--color-green-main-1);
    height: 1px;
}

/* RESULTS */

.app__geocoder_results_container {
    /* background-color: bisque; */
    width: 100%;
    /* height: 200px; */
    margin-top: 10px;
}

.app___geocoderresult_container {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 14px rgb(100 100 100 / 10%);
    border-radius: 0.4rem;
    padding: 8px 10px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    backdrop-filter: blur(3px);
    transition: background-color 0.2s ease;
}

.app___geocoderresult_container:hover {
    background-color: rgba(255, 255, 255, 1);
}

.app__geocoderresult_tag_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__geocoderresult_tag {
    color: white;
    font-size: 11px;
    padding: 1px 4px;
    margin-right: 6px;
    border-radius: 5px;
    background-color: var(--color-green-main-1);
}


/* RESPONSIVE */
@media screen and (max-width: 1050px) {

    .app__geocoder {
        top: 10px;
        left: 30px;
    }

}