.popup__container {
    position: fixed;
    z-index: 10000;
    opacity: 1;
}

.popup__container_hidden {
    -webkit-animation: popup_anim_fade_out_left 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both !important;
    animation: popup_anim_fade_out_left 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both !important;
}

/* STYLES */

/* STYLE = RELEASE */
.popup__container_release {
    bottom: 10px;
    left: 70px;
    max-width: 360px;
    /* background-color: var(--color-blue-main-0); */
    background-color: rgba(6, 33, 52, 0.9);
    backdrop-filter: blur(6px);
    border-radius: 6px;
    /* border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px; */
    color: #FFF;
    /* overflow: hidden; */
    /* padding: 0px 0px 2px; */
    -webkit-animation: popup_anim_fade_in_left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: popup_anim_fade_in_left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.popup__title {
    font-size: 15px;
    line-height: 16px;
    font-weight: 700;
    margin: 10px 0px 7px 0px;
    padding: 0px 12px;
}

.popup__text {
    font-size: 13px;
    line-height: 14px;
    font-weight: 400;
    margin-bottom: 12px;
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0px 12px;
}

.popup__text_note {
    font-style: italic;
    font-size: 12px;
    line-height: 13px;
    color: lightgrey;
}

.popup__text_link {
    text-decoration: underline;
}

.popup__bottom_part {
    width: calc(100% - 24px);
    /* min-height: 20px; */
    display: flex;
    justify-content: center;
    border-top: 1PX solid rgba(211, 211, 211, 0.3);
    padding: 5px 0 7px;
    margin: 0 12px;
}

.popup__bottom_btn {
    font-size: 11px;
    line-height: 13px;
    font-weight: 400;
    color: rgba(211, 211, 211, 0.6);
    transition: all 0.2s ease;
    cursor: pointer;
}

.popup__bottom_btn:hover {
    color: rgba(211, 211, 211, 1);
}

.popup__angle {
    position: absolute;
    bottom: 12px;
    left: -7px;
    width: 8px;
}


/* ANIMATIONS */

@-webkit-keyframes popup_anim_fade_in_left {
    0% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes popup_anim_fade_in_left {
    0% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes popup_anim_fade_out_left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }
}

@keyframes popup_anim_fade_out_left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }
}


/* RESPONSIVE */

@media screen and (max-width: 550px) {
    .popup__container_release {
        width: calc(100% - 20px);
        max-width: none;
        bottom: 90px;
        left: 10px;
    }

    .popup__angle {
        position: absolute;
        bottom: -8px;
        left: 16px;
        width: 8px;
        transform: rotateZ(-90deg);
    }
}