/* CHART CONTAINERS */
.app__context_chart_container_160 {
    width: 100%;
    height: 160px;
    position: relative;
    display: flex;
}

.app__context_chart_container_200 {
    width: 100%;
    height: 200px;
    position: relative;
    display: flex;
}

.app__context_chart_container_half {
    width: 160px;
    height: 100%;
    position: relative;
}

/* LEGEND */
.app__context_chart_legend_container {
    /* background-color: blueviolet; */
    margin-left: 45px;
    width: calc(100% - 45px);
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 4px;
}

.app__context_chart_legend_item {
    flex: 1;
    text-align: center;
    color: grey;
    font-size: 11px;
    line-height: 12px;
    font-weight: 600;
}

.app__context_chart_legend_full_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 6px;
}

.app__context_chart_legend_full_color {
    height: 16px;
    width: 16px;
    border-radius: 10px;
}

.app__context_chart_legend_full_item {
    opacity: 1;
    transition: all 0.3s ease;
}

.app__context_chart_legend_full_item_hidden {
    opacity: 0.2;
}

.app__context_chart_legend_full_item_title_container {
    display: flex;
    gap: 6px;
    align-items: center;
}

.app__context_chart_legend_full_item_title {
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
}

.app__context_chart_lagend_full_item_body_container {
    font-size: 11px;
    line-height: 12px;
    margin-left: 22px;
}

/* FILTER */
.app__context_filter {
    margin-bottom: 4px;
    line-height: 26px;
}

.app__context_filter_button {
    color: #FFF;
    background-color: grey;
    padding: 2px 8px;
    border-radius: 23px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.app__context_filter_button_active {
    background-color: var(--color-green-main-1);
}

.app__context_filter_button>img {
    margin-left: 6px;
    height: 9px;
    transform: rotateZ(45deg);
    transition: all 0.2s ease;
}

.app__context_filter_button_active>img {
    transform: rotateZ(0deg);
}

/* DETAIL */

.app__context_population_detail_container {
    overflow: hidden;
    max-height: 730px;
    transition: max-height 0.6s ease;
}

.app__context_population_detail_container_hidden {
    max-height: 0px;
}

.app__context_housing_detail_container {
    overflow: hidden;
    max-height: 1400px;
    transition: max-height 0.6s ease;
}

.app__context_housing_detail_container_hidden {
    max-height: 0px;
}

.app__context_housing_detail_button_container2 {
    position: relative;
}

.app__context_housing_detail_button_container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    opacity: 0.7;
    transition: opacity 0.2s ease;
}

.app__context_housing_detail_button_container:hover {
    opacity: 1;
}

.app__context_housing_detail_button {
    height: 16px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    color: grey;
}

.app__context_housing_detail_button_up {
    height: 11px;
    display: flex;
    justify-content: center;
}

.app__context_housing_detail_button_up>img {
    height: 9px;
}

.app__context_housing_detail_button_down {
    height: 11px;
    display: flex;
    justify-content: center;
}

.app__context_housing_detail_button_down>img {
    height: 9px;
    transform: rotateZ(180deg);
}

/* ISO */
.app__context_iso_container {
    display: flex;
}

.app__context_iso_container>img {
    height: 34px;
    margin-right: 10px;
}

.app__context_iso_container_small {
    display: flex;
    align-items: center;
}

.app__context_iso_container_small>img {
    height: 24px;
    margin-right: 6px;
}

.app__context_iso_value_container {
    margin: 6px 0px 0px 6px;
    max-height: 340px;
    overflow: hidden;
    transition: all 0.4s ease;
}

.app__context_iso_value_container_hidden {
    max-height: 0px;
}

.app__context_iso_value_main {
    font-size: 16px;
    line-height: 17px;
    font-weight: 700;
}

.app__context_iso_value_main_small {
    font-size: 16px;
    line-height: 14px;
    font-weight: 700;
}

.app__context_iso_value_second {
    line-height: 14px;
}

.app__context_iso_value_second_small {
    line-height: 13px;
    font-size: 12px;
}

.app__context_iso_value_second_strong {
    font-size: 13px;
    line-height: 14px;
    font-weight: 600;
}

.app__context_iso_value_third {
    font-size: 12px;
    line-height: 13px;
    margin-top: 4px;
}


.app__sectiondata_transaction_container,
.app__sectiondata_merimee_container,
.app__sectiondata_sitadel_container {
    max-height: 20000px;
    overflow: hidden;
    transition: max-height 0.6s ease;
}

.app__sectiondata_transaction_container_small {
    max-height: 180px;
}

.app__sectiondata_merimee_container_small {
    max-height: 240px;
}

.app__sectiondata_sitadel_container_small {
    max-height: 350px;
}

.app__fade {
    position: absolute;
    bottom: 25px;
    width: 100%;
}
