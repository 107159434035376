/* HEADING */
.landing__steps_heading {
    margin-bottom: 40px;
}

.landing__steps h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
}

/* STICKY */
.landing__steps_sticky__box {
    width: 100%;
    display: flex;
}

.landing__steps_sticky__img_box {
    /* background-color: blue; */
    flex: 1;
}

.landing__steps_sticky__img_container {
    /* background-color: brown; */
    position: sticky;
    top: 30vh;
}

.landing__steps_sticky__img_mockup {
    width: 100%;
    z-index: 2;
    position: relative;
}

.landing__steps_sticky__img {
    width: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

.landing__steps_sticky__img_section_container {
    position: relative;
    margin-bottom: 50px;
    display: none;
    justify-content: right;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.landing__steps_sticky__img_section_container_show {
    opacity: 1;
}

.landing__steps_sticky__img_section_mockup {
    width: 100%;
    /* width: calc(100% - 250px); */
    z-index: 2;
    position: relative;
}

.landing__steps_sticky__img_section {
    width: 100%;
    /* width: calc(100% - 250px); */
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
}

.landing__steps_sticky__text_box {
    /* background-color: blueviolet; */
    /* flex: 1; */
    width: 40vw;
    color: #fff;
    font-family: var(--font-family);
    padding: 0 0 0 30px;
}

.landing__steps_sticky__text_section {
    display: flex;
    height: auto;
    /* min-height: 400px; */
    min-height: calc((60vw - 200px) / 1.77 - 20px);
    margin-bottom: 20px;
    opacity: 0.2;
    transition: opacity 0.2s ease-in-out;
    /* border: 1px solid black; */
}

.landing__steps_sticky__text_section_show {
    opacity: 1;
}

.landing__steps_sticky__text__description_box {
    flex: 1;
}

.landing__steps_sticky__text__description_box_title {
    font-size: 20px;
    line-height: 18px;
    font-weight: 900;
    letter-spacing: 0.7px;
    margin-bottom: 16px;
}

.landing__steps_sticky__text__description_box_body {
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 8px;
}

.landing__steps_sticky__text_num_box {
    position: relative;
    width: 250px;
}

.landing__steps_sticky__text_num_title {
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    margin-left: 10px;
}

.landing__steps_sticky__text_num {
    position: absolute;
    top: -10px;
    color: rgba(255, 255, 255, 0.2);
    font-size: 180px;
    line-height: 180px;
    font-weight: 700;
}

@media screen and (max-width: 900px) {
    .landing__steps_sticky__img_box {
        display: none;
    }

    .landing__steps_sticky__text_box {
        padding: 0;
        width: 100%;
    }

    .landing__steps_sticky__text_section {
        height: auto;
        min-height: 170px;
    }

    .landing__steps_sticky__img_section_container {
        display: flex;
    }

}

/* @media screen and (max-width: 700px) {} */

@media screen and (max-width: 550px) {
    .landing__steps h1 {
        font-size: 28px;
        line-height: 32px;
    }

    .landing__steps_sticky__text_section {
        flex-direction: column;
        gap: 125px;
        height: auto;
        margin-bottom: 15px;
    }
}