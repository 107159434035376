.buildable__levels {
    display: flex;
    /* margin-bottom: 26px; */
}

.buildable__floorplan_part {
    position: relative;
    flex: 1;
    margin-left: 6px;
    /* width: 60%; */
    /* min-height: 180px; */
}

.buildable__floorplan_box {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    /* bottom: 0px; */
    /* bottom: -39%; */
    transition: all 0.3s ease 0.2s;
}

.buildable__floorplan_box_selected {
    /* top: 0px; */
    /* bottom: 0px;
    transition: all 0.3s ease 0.2s; */
}

.buildable__floorplan_container {
    position: absolute;
    display: flex;
    width: 100%;
    /* border: 2px solid red; */
    transform: rotateX(79deg);
    /* left: -50px; */
    top: calc(100% - 159px);
    transition: opacity 0.2s ease 1s, transform 0.6s ease, top 0.5s ease;
}

.buildable__floorplan_container:nth-child(3) {
    top: calc(100% - 213px);
}

.buildable__floorplan_container:nth-child(4) {
    top: calc(100% - 268px);
}

.buildable__floorplan_container:nth-child(5) {
    top: calc(100% - 323px);
}

.buildable__floorplan_container:nth-child(6) {
    top: calc(100% - 378px);
}

.buildable__floorplan_container:nth-child(7) {
    top: calc(100% - 433px);
}

.buildable__floorplan_container:nth-child(8) {
    top: calc(100% - 488px);
}

.buildable__floorplan_container:nth-child(9) {
    top: calc(100% - 543px);
}

.buildable__floorplan_container:nth-child(10) {
    top: calc(100% - 598px);
}

.buildable__floorplan_container:nth-child(11) {
    top: calc(100% - 653px);
}

.buildable__floorplan_container:nth-child(12) {
    top: calc(100% - 708px);
}

.buildable__floorplan_container:nth-child(13) {
    top: calc(100% - 763px);
}

.buildable__floorplan_container:nth-child(14) {
    top: calc(100% - 818px);
}

.buildable__floorplan_container:nth-child(15) {
    top: calc(100% - 873px);
}

.buildable__floorplan_container:nth-child(16) {
    top: calc(100% - 928px);
}

.buildable__floorplan_container:nth-child(17) {
    top: calc(100% - 983px);
}

.buildable__floorplan_container:nth-child(18) {
    top: calc(100% - 1038px);
}

.buildable__floorplan_container:nth-child(19) {
    top: calc(100% - 1093px);
}

.buildable__floorplan_container:nth-child(20) {
    top: calc(100% - 1148px);
}

.buildable__floorplan_container>svg {
    width: 100%;
}

.buildable__floorplan_container_selected {
    transform: rotateX(0deg);
    top: max(calc(50% - 120px), 0px) !important;
    transition: transform 0.6s ease 0.3s, top 0.5s ease 0.3s;
}

.buildable__floorplan_container_unselected {
    opacity: 0;
    transform: rotateX(0deg);
    top: max(calc(50% - 120px), 0px) !important;
    transition: opacity 0.3s ease, transform 0.6s ease 0.5s, top 0.5s ease 0.5s;
}

.buildable__floorplan_text {
    opacity: 0;
    transition: all 0.4s ease 0.3s;
}

.buildable__floorplan_text_selected {
    opacity: 1;
}



.buildable__listpart {
    width: 55%;
    display: flex;
    flex-direction: column;
}

.buildable__listpart_title {
    display: flex;
    margin-bottom: 10px;
    padding: 0px 10px;
    width: calc(100% - 6px);
}

.buildable__listpart_title_item {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: grey;
}

.buildable__listpart_item_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.buildable__listpart_item {
    position: relative;
    padding: 6px 10px;
    margin-bottom: 7px;
    width: calc(100% - 6px);
    /* display: flex;
    align-items: center; */
    min-height: 40px;
    max-height: 48.07px;
    border-radius: 6px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 0.7rem;
    box-shadow: 0 0 14px rgb(100 100 100 / 10%);
    opacity: 1;
    transition: all 0.2s ease;
}

.buildable__listpart_item_title {
    font-size: 16px;
    line-height: 17px;
    font-weight: 700;
}

.buildable__listpart_item_body {
    display: flex;
    align-items: flex-end;
}

.buildable__listpart_item_subvalue {
    display: flex;
}

.buildable__listpart_item_subvalue_title {
    color: grey;
    width: 28px;
}

.buildable__listpart_item_selected {
    background-color: #062134;
    color: #fff;
    /* box-shadow: 0 0 14px rgb(100 100 100 / 20%); */
}

.buildable__listpart_item_unselected {
    /* opacity: 0.6;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0 0 14px rgb(100 100 100 / 2%); */
}

/* .buildable__listpart_item:hover {
    background-color: #06213411;
} */

.buildable__col0 {
    width: 50px;
}

.buildable__col1 {
    width: 30%;
}

.buildable__col2 {
    width: 35%;
}

.buildable__col3 {
    width: 35%;
}



.buildable__message_green_container {
    background-color: #99c7ce;
    display: flex;
    /* gap: 3px; */
    align-items: center;
    padding: 3px 5px;
    border-radius: 6px;
    margin-top: 6px;
    position: relative;
}

.buildable__message_green_arrow {
    background-color: #99c7ce;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 20px;
    width: 20px;
    transform: rotateZ(45deg);
}

.buildable__message_green_ico {
    font-size: 20px;
    z-index: 89;
}

.buildable__message_green_text {
    color: #007384;
    font-size: 11.8px;
    line-height: 12px;
    font-weight: 600;
    z-index: 89;
}



@media screen and (max-width: 550px) {
    .buildable__levels {
        margin-bottom: 0px;
    }

    .buildable__listpart {
        width: 100%;
    }

    .buildable__listpart_title {
        margin-bottom: 3px;
    }
}



.buildable__input {
    outline: none;
    border: none;
    font-family: var(--font-family-Manrope);
    font-size: 40px;
    line-height: 38px;
    font-weight: 600;
    letter-spacing: -2px;
    height: 38px;
    width: 116px;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease;
    color: #007384;
    background-color: transparent;
}

.buildable__input:hover {
    /* border-bottom: 1px solid lightgrey; */
    border-bottom: 1px solid #007384;
}

.buildable__input:focus {
    /* border-bottom: 1px solid lightgrey; */
    border-bottom: 1px solid #007384;
}

.buildable__legend_container {
    position: absolute;
    left: 10px;
    top: calc(100% + 5px);
    transition: top 0.5s ease;
}

.buildable__legend_container_selected {
    top: max(calc(50% + 143px), 260px) !important;
    transition: top 0.5s ease 0.3s;
}

.buildable__legend_item {
    display: flex;
    gap: 4px;
    /* align-items: center; */
}

.buildable__legend_square {
    height: 9px;
    width: 9px;
    border: 1px solid #1ECD97;
}

.buildable__legend_square_text {
    font-size: 9px;
    line-height: 10px;
}




.app__sectiondata_widget_value_info {
    position: absolute;
    right: 0px;
    top: calc(100% - 16px);
    /* top: 38px; */
    z-index: 85;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    background: #909fa7;
    /* background: #99c7ce; */
    /* background: #007384; */
    /* width: 100%; */
    width: 20px;
    min-height: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 10px rgba(6, 33, 52, 0);
    animation: pulse-animation 3s infinite;
    transition: all 0.4s 0.2s ease-in-out;
}

.app__sectiondata_widget_value_info:hover {
    width: 100%;
    /* min-height: 100%; */
    animation: none;
    transition: width 0.2s ease-in-out, min-height 0.2s 0.2s ease-in-out;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(6, 33, 52, 0.3);
    }

    60% {
        box-shadow: 0 0 0 10px rgba(6, 33, 52, 0);
    }

    100% {
        box-shadow: 0 0 0 10px rgba(6, 33, 52, 0);
    }
}

.app__sectiondata_widget_value_info_ico {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 20px;
    max-height: 20px;
    overflow: hidden;
    opacity: 1;
    transition: all 0.4s 0.7s ease-in-out;
}

.app__sectiondata_widget_value_info:hover>div.app__sectiondata_widget_value_info_ico {
    max-width: 0px;
    max-height: 0px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.app__sectiondata_widget_value_info_text {
    /* position: absolute; */
    padding: 0px;
    color: #062134;
    /* color: #007384; */
    font-size: 12px;
    line-height: 13px;
    font-weight: 500;
    overflow: hidden;
    max-width: 100%;
    max-height: 0px;
    opacity: 0;
    transition: all 0.4s 0.2s ease-in-out, max-height 0.3s ease-in-out, opacity 0.2s ease-in-out;
}

.app__sectiondata_widget_value_info:hover>div.app__sectiondata_widget_value_info_text {
    /* max-width: 100%; */
    max-height: 400px;
    padding: 8px 6px 6px;
    opacity: 1;
    transition: padding 0s 0.2s ease-in-out, max-height 0.2s 0.2s ease-in-out, opacity 0.2s 0.4s ease-in-out;
}

.app__sectiondata_widget_value_info_text_title {
    font-size: 13px;
    font-weight: 900;
    margin-bottom: 5px;
}



.buildable__legend_margin {
    width: 100%;
    height: 4px;
    background-color: transparent;
    transition: all 0.5s ease;
}

.buildable__legend_margin_large {
    height: 20px;
    transition: all 0.5s ease 0.3s;
}

.buildable__legend_margin2 {
    width: 100%;
    height: 10px;
    background-color: transparent;
    transition: all 0.5s ease;
}

.buildable__legend_margin_large2 {
    height: 10px;
    transition: all 0.5s ease 0.3s;
}


.app__sectiondata_widget_value_container {
    position: relative;
}