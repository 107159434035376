/* SECTION MASTER CONTAINER */
.app__section_master_container {
    background-color: cyan;
    width: var(--width-section);
    position: absolute;
    top: 80px;
    left: var(--width-navbar);
    height: calc(100vh - var(--width-navbar) - 40px);
    overflow-x: hidden;
    overflow-y: auto;
}

/* SECTION */
.app__section {
    height: 100vh;
}

.app__section_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}


/* SECTION BODY */
.app__section_body_container {
    background-color: red;
    padding: 0px 20px 20px 20px;
}

.app__section_body {
    /* background-color: rebeccapurple; */
    height: 100%;
    width: 100%;
    display: flex;
    overflow: visible;
}


/* SECTION TITLE */
.app__section_title_container {
    margin-bottom: 20px;
    padding: 0px 20px;
    height: 30px;
    background-color: var(--color-grey-main-1);
    position: sticky;
    top: 0px;
    z-index: 90;
}

.app__section_title_container h3 {
    font-family: var(--font-family-Manrope);
    color: var(--color-blue-main-1);
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
    letter-spacing: 0.04em;
}

.app__section__title_bar {
    background-color: var(--color-green-main-1);
    height: 4px;
    width: 30px;
    margin: 10px 0;
}




.app_section_container_side {
    /* background-color: blueviolet; */
    width: 560px;
}

.app__section_body2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.app__section_body_title_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.app__section_body_user_container {
    width: 100%;
    display: flex;
    /*flex-direction: column;
    gap: 4px; */
    margin-top: 10px;
    font-size: 12px;
    line-height: 20px;
}

.app__section_body_user_container img {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    margin: 0px 4px;
}

.app__section_body_user_container div {
    display: flex;
}

.app__section_body_user_container_img {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    overflow: hidden;
    margin-left: 4px;
}

.app__section_body_user_container_img img {
    height: 20px;
    width: auto;
}

.app__section_body_subtitle {
    font-weight: 600;
    /* margin-bottom: 10px; */
}

.app__section_body2_message {
    margin-bottom: 20px;
}

.app__widget2 {
    background-color: #fff;
    border-radius: 0.7rem;
    -webkit-box-shadow: 0 0 14px rgba(100, 100, 100, 0.1);
    box-shadow: 0 0 14px rgba(100, 100, 100, 0.1);
    padding: 14px;
}

.app__widget2_title {
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: grey;
    margin: 0 0 10px 0;
}

.app__section_body_23_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* margin-bottom: 10px; */
}

.app__section_body_23_container_first {
    width: 70%;
    margin-right: 10px;
}

.app__section_body_23_container_first_body {
    display: flex;
}

.app__section_body_23_container_second {
    width: 30%
}

/* WIDGET INDICATOR */

.app__widget2_indicator_container {
    flex: 1;
}

.app__widget2_indicator_value {
    font-size: 50px;
    line-height: 40px;
    font-weight: 700;
    letter-spacing: -2px;
    margin-right: 30px;
}

.app__widget2_indicator_value_small {
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    letter-spacing: -2px;
}

.app__widget2_indicator_unit {
    font-size: 13px;
    font-weight: 700;
}

/* WIDGET LIST */

.app__widget2_list_titles_container {
    display: flex;
    /* justify-content: space-between; */
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: grey;
    margin: 0 0 10px 0;
}

.app__widget2_list_titles_container div {
    width: 20%;
}

.app__widget2_list_titles_container :nth-child(1) {
    width: 40%;
}

/* .app__widget2_list_titles_container :nth-child(2) {
    width: 35%;
}

.app__widget2_list_titles_container :nth-child(3) {
    width: 15%;
}

.app__widget2_list_titles_container :nth-child(4) {
    width: 15%;
}

.app__widget2_list_titles_container :nth-child(5) {
    width: 20%;
} */

.app__widget2_list_item_container {
    display: flex;
    align-items: center;
    height: 50px;
    border-top: 1px solid #F5F5F5;
    /* justify-content: space-between; */
}

.app__widget2_list_item_container div {
    width: 20%;
}

.app__widget2_list_item_container :nth-child(1) {
    width: 40%;
}

/* .app__widget2_list_item_container :nth-child(2) {
    width: 35%;
}

.app__widget2_list_item_container :nth-child(3) {
    width: 15%;
}

.app__widget2_list_item_container :nth-child(4) {
    width: 15%;
}

.app__widget2_list_item_container :nth-child(5) {
    width: 20%;
} */

/* WIDGET INPUT */

.app__widget2_input {
    width: 100%;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 1px solid #FFF;
    border-left: 0px;
    outline: 0;
    font-size: 16px;
    font-weight: 600;
}

.app__widget2_input:focus {
    border-top: 0px;
    border-right: 0px;
    border-bottom: 1px solid var(--color-blue-main-0);
    border-left: 0px;
}

/* WIDGET MULTI INPUT */
.app__widget2_multinput {
    width: 100%;
    /* height: 20px; */
    padding: 0;
    resize: none;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 1px solid #FFF;
    border-left: 0px;
    outline: 0;
    font-size: 14px;
    font-family: var(--font-family-Manrope);
    overflow-y: hidden;
}

.app__widget2_multinput:focus {
    border-top: 0px;
    border-right: 0px;
    border-bottom: 1px solid var(--color-blue-main-0);
    border-left: 0px;
}


/* MAP BUTTON */
.app__capamap_button_container {
    z-index: 11000;
    /* background-color: red; */
    height: 40px;
    width: calc(100vw - var(--width-navbar) - var(--width-section) - 40px);
    position: fixed;
    left: calc(var(--width-navbar) + var(--width-section));
    bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__capamap_button {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    border-radius: 40px;
    background: #1ecd97;
    border: 2px solid #1ecd97;
    color: #fff;
    /* color: var(--color-blue-main-0); */
    letter-spacing: 1px;
    font-size: 13px;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.25s ease;
}


/* PLACEHOLDER */
.app__placeholder_line_container {
    background-color: green;
    /* background-color: var(--color-grey-main-1); */
    width: 100%;
    height: 30px;
    padding-left: var(--width-navbar);
    position: fixed;
    top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 91;
}

.app__placeholder_line_container_transotion {
    transition: top ease 1s;
}

.app__placeholder_line {
    background-color: grey;
    height: 4px;
    width: 100px;
    border-radius: 2px;
}

/* RESPONSIVE */

@media screen and (max-width: 1050px) {
    .app__section_master_container {
        width: calc(100% - var(--width-navbar));
        top: 50%;
        left: var(--width-navbar);
        height: 50%;
    }

    .app__section {
        height: auto;
    }

    .app__placeholder_line_container {
        display: flex;
        top: calc(50% - 30px);
    }

}