/* BODY */
.auth__authform {
    background-color: var(--color-blue-secondary-2);
    width: 400px;
    position: relative;
    border-radius: 10px;
    padding: 20px;
    /* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    z-index: 100;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s 1s, opacity 0.5s 0.5s linear;
}

.auth__authform_hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 0.5s 0.5s linear;
}

/* CLOSE */
.auth__close_container {
    position: absolute;
    top: 12px;
    right: 12px;
    display: none;
}

.auth__close_container img {
    height: 12px;
}

/* TITLE */
.auth__authform h5 {
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 0.6px;
    color: #fff;
    text-align: center;
    margin: 20px 0;
}

/* FORM */
.auth__authform form {
    display: flex;
    flex-direction: column;
}

.auth__authform_box_name {
    margin-top: 16px;
    display: flex;
    gap: 10px;
}

.auth__authform_box_mail {
    margin-top: 16px;

}

.auth__authform_box_password {
    margin-top: 16px;
}

.auth__authform_box_password_check {
    font-size: 11px;
    color: grey;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: flex-start;
}

.auth__authform_box_password_check_circle {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 7px;
    color: transparent;
    margin-top: 2px;
    transition: all 0.2s ease-in-out;
}

.auth__authform_box_password_check_circle_valid {
    background-color: #1ecd97;
    border: 1px solid #1ecd97;
    color: #062134;
}

.auth__authform_box_visible {
    align-items: flex-end;
    visibility: visible;
    opacity: 1;
    height: 48px;
    transition: opacity 0.2s linear 0.5s, height 0.5s ease;
}

.auth__authform_box_visible2 {
    align-items: flex-end;
    visibility: visible;
    opacity: 1;
    height: 125px;
    margin-top: 5px;
    transition: opacity 0.2s linear 0.5s, height 0.5s ease;
}

.auth__authform_box_hidden {
    align-items: flex-end;
    visibility: hidden;
    opacity: 0;
    margin-top: 0px;
    height: 0px;
    transition: visibility 0s 0.2s, opacity 0.2s linear, height 0.5s ease 0.3s, margin 0.5s ease 0.3s;
}

.auth__authform_forget {
    position: absolute;
    color: #9b9b9b;
    cursor: pointer;
    font-size: 12px;
    text-align: left;
    margin: 2px 0 0 0;
}

.auth__authform_forget:hover {
    color: #1ecd97;
}

/* BUTTON */
.auth__authform_box_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0 0 0;
}

.auth__authform_button {
    outline: none;
    height: 40px;
    text-align: center;
    width: 100%;
    border-radius: 40px;
    background: #1ecd97;
    border: 2px solid #1ecd97;
    color: #fff;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.25s ease;
}

.loading {
    background: transparent;
    width: 40px;
    border-color: #9b9b9b;
    border-width: 3px;
    font-size: 0;
    border-left-color: #1ecd97;
    animation: rotating 2s 0.25s linear infinite;
}


.loadedSuccess {
    /* width: 40px; */
    font-size: 13px;
    color: white;
    background: #1ecd97;
    border-color: #1ecd97;
}

.loadedFail {
    /* width: 40px; */
    font-size: 13px;
    color: white;
    background: #FF5964;
    border-color: #FF5964;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.auth__authform_message {
    color: #FF5964;
    font-size: 12px;
    font-weight: 800;
    text-align: center;
    margin: 10px 0 0 0;
}

/* LINK */
.auth__authform_link {
    color: #9b9b9b;
    cursor: pointer;
    font-size: 12px;
    text-align: center;
    margin: 20px 0 0 0;
}

.auth__authform_link:hover {
    color: #1ecd97;
}

/* FINAL */
.auth__authform_final {
    /* background-color: #FF5964; */
    position: absolute;
    z-index: 99;
    /*left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    opacity: 0;
}

.auth__authform_final h1 {
    color: #1ecd97;
}

.auth__authform_final h4 {
    text-align: center;
    margin-top: 0;
}

.auth__authform_final_visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.4s linear 0.5s;
}


/* RESPONSIVE */

@media screen and (max-width: 1050px) {}

@media screen and (max-width: 700px) {}

@media screen and (max-width: 550px) {
    .auth__authform {
        width: 90vw;
    }

    .auth__close_container {
        display: block;
    }
}