.admin__teams_container {
    /* background-color: aqua; */
    max-height: calc(100% - 33px);
    overflow-y: auto;
    opacity: 1;
    transition: all 0.2s ease;
    padding-right: 4px;
    position: relative;
}

.admin__teams_container_hide {
    opacity: 0.3;
}

.admin__teams_container:hover::-webkit-scrollbar-track,
.admin__capas_container:hover::-webkit-scrollbar-track,
.admin__users_container:hover::-webkit-scrollbar-track {
    background-color: rgba(100, 100, 100, 0.2);
}

.admin__teams_container:hover::-webkit-scrollbar-thumb,
.admin__capas_container:hover::-webkit-scrollbar-thumb,
.admin__users_container:hover::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.5);
}

.admin__teams_container::-webkit-scrollbar,
.admin__capas_container::-webkit-scrollbar,
.admin__users_container::-webkit-scrollbar {
    width: 4px;
    height: 0px;
}

.admin__teams_container::-webkit-scrollbar-track,
.admin__capas_container::-webkit-scrollbar-track,
.admin__users_container::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: rgba(100, 100, 100, 0);
    transition: all 0.3s ease;
}

.admin__teams_container::-webkit-scrollbar-thumb,
.admin__capas_container::-webkit-scrollbar-thumb,
.admin__users_container::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(100, 100, 100, 0);
    transition: all 0.3s ease;
}

.app__admin_teams_title_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app__admin_teams_modal {
    position: absolute;
    top: 28px;
    left: -10px;
    backdrop-filter: blur(8px);
    /* background-color: #040c188f; */
    width: calc(100% + 20px);
    height: calc(100% - 30px);
    font-size: 15px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__admin_teams_inactive {
    color: lightgrey;
    font-size: 12px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0.5;
    transition: all 0.2s ease;
}

.app__admin_teams_inactive:hover {
    opacity: 1;
}


/* RESPONSIVE */

@media screen and (max-width: 1200px) {

    .admin__teams_container {
        padding-right: 0px;
        max-height: calc(100% - 33px);
    }
}