.mockup_container {
    text-align: center;
}

.mockup {
    display: inline-block;
    position: relative;
    z-index: 3;
    text-align: center;
    font-size: 0;
    perspective: 2400px;
    perspective-origin: 50% 100%;
    opacity: 0;
    transition: 500ms opacity;
}

.mockup.loaded {
    opacity: 1;
}

.mockup .part .top,
.mockup .part .bottom {
    position: absolute;
    top: 0;
    left: 0;
}

.mockup .part.top {
    transform: translate3d(0, 0, 0) rotateX(-90deg);
}

.mockup:hover .part.top {
    transform: translate3d(0, 0, 0) rotateX(0deg);
}

.mockup .part {
    display: inline-block;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform-origin: 50% 100%;
    transition: 900ms;
}

.mockup.opened .part .top {
    transform: translate3d(0, 0, -11px) rotateX(90deg) scale(1, 1);
}

.mockup .part .top {
    transform-origin: 50% 0;
    transform: translate3d(0, 0, -11px) rotateX(90deg);
    transition: 900ms;
}

.mockup img {
    display: block;
    max-width: 100%;
    backface-visibility: hidden;
}

.mockup .part .cover {
    position: relative;
}

.mockup video {
    display: block;
    position: absolute;
    top: 8%;
    left: 4%;
    width: 92%;
    border-radius: 6px;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 1px);
}

.mockup .part.bottom {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0) rotateX(-90deg);
}

.mockup .part .bottom {
    transform-origin: 50% 0;
    transform: translate3d(0, 0, 0) rotateX(90deg);
}