.app__exportation_container {
    position: absolute;
    z-index: 3;
    top: 39px;
    right: 39px;
    width: 325px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app__exportation_title {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    color: #333333;
}

.app__exportation_3d_container {
    background-color: aqua;
    width: 100%;
    height: 180px;
    margin-top: 10px;
}





.app__exportation_maincontainer {
    display: flex;
    gap: 10px;
    position: relative;
}

.app__exportation_submaincontainer {
    width: 45%;
    position: relative;
    display: flex;
    gap: 10px;
}

.app__exportation_submaincontainer > div.app__exportation_subcontainer {
    width: 50%;
    position: relative;
}

.app__exportation_subcontainer {
    position: relative;
    background-color: #fff;
    border-radius: 0.7rem;
    box-shadow: 0 0 14px rgba(100, 100, 100, 0.1);
    padding: 14px;
    flex: 1;
    min-height: 82px;
    /* height: 60px; */
    /* width: 60px; */
}

.app__exportation_button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 14px;
    background-color: var(--color-green-main-1);
    color: #FFF;
    border-radius: 30px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    text-align: center;
}

.app__exportation_button_small {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 7px 14px; */
    background-color: var(--color-green-main-1);
    border: 2px solid var(--color-green-main-1);
    color: #FFF;
    border-radius: 30px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    text-align: center;
    height: 30px;
    width: 130px;
    transition: all 0.2s ease;
}

.app__exportation_button_small_hidden {
    pointer-events: none;
    background-color: grey;
    border: 2px solid grey;
}

.app__exportation_button_small_loading {
    border-top: 2px solid var(--color-green-main-1);
    border-left: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    height: 30px;
    width: 30px;
    background-color: transparent !important;
    animation: rotating 2s 0.25s linear infinite;
    pointer-events: none;
}

.app__exportation_button_small_done {
    height: 30px;
    width: 30px;
    pointer-events: none;
}

.app__exportation_button > img {
    height: 16px;
    margin-right: 4px;
}

.app__exportation_toogle_container {
    display: flex;
    align-items: center;
}

.app__exportation_toogle_label {
    font-size: 13px;
    line-height: 13px;
    margin-left: 5px;
}

.app__exportation_share_box {
    width: 100%;
    margin-top: 6px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    padding: 0 6px 2px 6px;
    font-size: 12px;
}

.app__exportation_modal_background {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(245, 245, 245, 0.6);
    z-index: 10000;
}

.app__exportation_modal_container {
    background-color: #081220;
    /* background: var(--gradient-blue-background); */
    /* height: 400px; */
    width: 400px;
    box-shadow: 0 0 14px rgba(100, 100, 100, 0.6);
    border-radius: 1.3rem;
    padding: 12px 12px 16px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.app__exportation_modal_subcontainer {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__exportation_modal_close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
}

.app__exportation_modal_close>img {
    height: 14px;
}

.app__exportation_modal_title {
    color: #F1F1F1;
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
    letter-spacing: 0.04em;
    text-align: center;
}

.app__exportation_modal_subtitle {
    color: #F1F1F1;
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 0.04em;
    margin-bottom: 4px;
}

.app__exportation_toogle_label_modal {
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    margin-left: 5px;
    color: lightgrey;
    margin-top: 2px;
}

.app__exportation_modal_select {
    color: lightgrey;
    background-color: #081220;;
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    min-height: 14px;
    font-size: 12px;
    width: max-content;
}



.not_available {
    opacity: 0.4;
    pointer-events: none;
}

.not_available_clickable {
    opacity: 0.4;
}


/* RESPONSIVE */

@media screen and (max-width: 550px) {

    .app__exportation_maincontainer {
        flex-direction: column;
    }

    .app__exportation_submaincontainer {
        width: 100%;
    }

}