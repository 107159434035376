.landing__header {
    height: 100vh;
    min-height: 500px;
    /* background-image: url('../../../assets/background_right.png');
    background-size: 110%;
    background-repeat: no-repeat;
    background-position: top right; */
    padding: 100px 6rem 1rem 6rem;
    background-color: var(--color-blue-main-1);
    overflow: hidden;
    position: relative;
}

.landing__header_body {
    position: relative;
    top: 30%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

/* TITLE */
.landing__header_body img {
    width: 60%;
}

/* SUBTITLE */
.landing__header_body p {
    font-weight: 400;
    font-size: 2.3vw;
    color: #fff;
    margin-top: 1.5vw;
}

/* IMAGE */
.landing__header_background_image {
    position: absolute;
    top: 0;
    right: 0;
}

.landing__header_background_image img {
    height: auto;
    max-width: 80vw;
}

/* CTA */
.landing__header_body_cta button {
    margin-top: 2.5vw;
    padding: 0.8rem 1.4rem;
    color: #062134;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.5s;
    background-size: 200% auto;
    background-image: linear-gradient(to right, #88FF9F 0%, #6FFEDB 51%, #88FF9F 100%);
}

.landing__header_body_cta button:hover {
    background-position: right center;
}

.landing__headline_0 {
    overflow: hidden;
    white-space: nowrap;
    animation: anim_headline 4s ease-in-out;
}

@keyframes anim_headline {
    0% {
        max-width: 0px;
        opacity: 1;
    }

    20% {
        max-width: 1000px;
        opacity: 1;
    }

    90% {
        max-width: 1000px;
        opacity: 1;
    }

    100% {
        max-width: 1000px;
        opacity: 0;
    }
}


/* RESPONSIVE */

@media screen and (max-width: 1050px) {

    .landing__header_body_cta {
        flex-direction: column;
        align-items: flex-start;
    }

    .landing__header_body_cta button {
        padding: 0.6rem 1.2rem;
        font-size: 13px;
    }
}

@media screen and (max-width: 700px) {

    .landing__header {
        padding: 100px 3rem 1rem 3rem;
    }

    .landing__header_background_image img {
        height: auto;
        max-width: 120vw;
    }

    .landing__header_body img {
        width: 70%;
    }

    .landing__header_body p {
        font-weight: 400;
        font-size: 3vw;
    }


}

@media screen and (max-width: 550px) {

    .landing__header {
        padding: 80px 2rem 1rem 2rem;
        height: 60vh;
    }

    .landing__header_body img {
        width: 90%;
    }

    .landing__header_body p {
        font-weight: 400;
        font-size: 4vw;
    }

    .landing__header_body_cta button {
        padding: 0.4rem 1rem;
        margin-top: 4vw;
        font-size: 11px;
    }

    .landing__header_scroll {
        display: none;
    }

    .landing__header_background_image img {
        opacity: 0;
    }
}

/* SCROLL ANIMATION */
.landing__header_scroll,
.landing__header_scroll:before {
    position: absolute;
    left: 50%;
}

.landing__header_scroll {
    width: 20px;
    height: 36px;
    margin-left: -10px;
    bottom: 8%;
    margin-top: -20px;
    box-shadow: inset 0 0 0 1px #fff;
    border-radius: 20px;
}

.landing__header_scroll:before {
    content: '';
    width: 6px;
    height: 6px;
    background: #fff;
    margin-left: -3px;
    top: 6px;
    border-radius: 3px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: scroll;
}

@-moz-keyframes scroll {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(20px);
    }
}

@-webkit-keyframes scroll {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(20px);
    }
}

@-o-keyframes scroll {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(20px);
    }
}

@keyframes scroll {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(20px);
    }
}