/* CARD NEW */

.app__newcapa_container {
    background: var(--gradient-blue-background);
    border-radius: 1rem;
    height: 340px;
    width: 260px;
    overflow: hidden;
    -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__newcapa_text {
    color: #fff;
    font-size: 17px;
    line-height: 19px;
    font-weight: 700;
    margin-top: 10px;
}

.app__newcapa_button {
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    letter-spacing: 1px;
    font-size: 40px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.25s ease;
}

.app__newcapa_button:hover {
    background: #1ecd97;
    border: 2px solid #1ecd97;
    color: #fff;
}


/* RESPONSIVE */

@media screen and (max-width: 550px) {
    .app__newcapa_container {
        height: 180px;
    }
}