.app__navbar {
    /* background-color: var(--color-blue-main-1); */
    background: var(--gradient-blue-background);
    width: var(--width-navbar);
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    color: #fff;
    /* font-family: var(--font-family-Manrope); */
    z-index: 100;
}

/* TOP SECTION */
.app__navbar_top_container {
    /* border: 1px solid orange; */
    width: 100%;
    height: 80px;
    /* height: var(--width-navbar); */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
}

.app__navbar_top_container img {
    width: 90%;
    cursor: pointer;
}

/* MIDDLE SECTION */
.app__navbar_middle_container {
    /* border: 1px solid orange; */
    width: 100%;
    position: relative;
}

.app__navbar_middle_indicator {
    z-index: 1;
    position: absolute;
    margin: 5px 1px;
    top: 0px;
    /* position: fixed;
    top: 100px;
    left: 1px; */
    background-color: #fff;
    height: calc(var(--width-navbar) - 10px);
    width: 3px;
    transition: transform 0.25s ease-in-out;
    border-radius: 4px;
    will-change: transform;
    backface-visibility: hidden;
    opacity: 1;
    transition: all 0.2s ease;
}

.app__navbar_middle_indicator_hidden {
    opacity: 0;
}

.app__navbar_middle_item {
    /* border: 1px solid cyan; */
    position: relative;
    height: var(--width-navbar);
    width: var(--width-navbar);
    min-width: var(--width-navbar);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* cursor: pointer; */
    transition: opacity 0.2s ease;
}

.app__navbar_middle_item_btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.app__navbar_middle_item:hover > div.navbar_subcontainer {
    max-width: 180px;
    transition: all 0.1s linear 0.7s;
}

.app__navbar_middle_item:hover > div.navbar_subcontainer > div.navbar_subcontainer_box {
    opacity: 1;
    transition: all 0.3s ease 0.9s;
}

.app__navbar_middle_item_hidden {
    opacity: 0.7;
}

/* .app__navbar_middle_item_hidden:hover > div.navbar_subcontainer {
    max-width: 0px;
} */

.app__navbar_middle_item_disabled {
    opacity: 0.4;
    cursor: default;
}

.app__navbar_middle_item img {
    width: 40%;
    margin-bottom: 0.3rem;
}

.app__navbar_middle_item span {
    /* font-size: 0.55rem; */
    font-size: 8.2px;
    line-height: 9px;
    font-weight: 500;
    text-transform: uppercase;
}

/* BOTTOM SECTION */
.app__navbar_bottom_container {
    /* border: 1px solid orange; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__navbar_bottom_item_container {
    height: 55px;
    display: flex;
    align-items: flex-end;
}

.app__navbar_bottom_item {
    min-height: calc(var(--width-navbar) - 16px);
    width: calc(var(--width-navbar) - 14px);
    background: var(--gradient-green-bar);
    margin: 0px 7px;
    padding: 6px 0px;
    border-radius: 0.4rem;
    box-shadow: 0 0 14px rgb(200 200 200 / 10%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
}

.app__navbar_bottom_item:hover span {
    height: 16px;
    margin-top: 3px;
}

.app__navbar_bottom_item img {
    width: 50%;
    /* margin-bottom: 4px; */
}

.app__navbar_bottom_item span {
    font-size: 8px;
    line-height: 8px;
    font-weight: 800;
    text-transform: uppercase;
    margin-top: 0px;
    color: var(--color-blue-main-0);
    overflow: hidden;
    height: 0px;
    transition: all 0.2s ease;
}

.app__navbar_bottom_info {
    /* color: var(--color-green-main-1); */
    color: #889aa5;
    font-size: 8.5px;
    font-weight: 700;
    margin: 10px 0;
    text-align: center;
    cursor: pointer;
}



.navbar_subcontainer {
    /* background-color: red; */
    position: absolute;
    overflow: hidden;
    /* min-height: 60px; */
    max-width: 0px;
    top: 0px;
    left: 60px;
    transform: translateY(calc(30px - 50%));
    cursor: default;
    transition: all 0.1s linear 0.2s;
}

.navbar_subcontainer_box {
    background: var(--gradient-blue-background);
    position: relative;
    /* min-height: 60px; */
    width: max-content;
    /* width: 160px; */
    margin-left: 6px;
    opacity: 0;
    /* transition: all 0.3s ease; */
    /* transition: all 0.3s ease 0.9s; */
    transition: all 0.1s ease;
    border-radius: 6px;
    padding: 6px 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.navbar_subcontainer_item {
    font-size: 11px;
    line-height: 12px;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
    opacity: 0.9;
    transition: all 0.1s ease;
}

.navbar_subcontainer_item:hover {
    opacity: 1;
}




/* RESPONSIVE */

@media screen and (max-width: 1050px) {}

@media screen and (max-width: 700px) {}

@media screen and (max-width: 550px) {
    .app__navbar {
        height: calc(var(--width-navbar) + 20px);
        width: 100vw;
        position: fixed;
        top: calc(100% - var(--width-navbar) - 20px);
        left: 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-bottom: 20px;
    }

    .app__navbar_top_container {
        display: none;
    }

    .app__navbar_middle_container {
        height: 100%;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        justify-content: space-evenly;
        /* justify-content: flex-start; */
        /* gap: 20px; */
        gap: 10px;
        padding: 0px 10px;
    }

    .app__navbar_middle_indicator {
        z-index: 1;
        margin: 3px 5px;
        top: 0px;
        width: calc(var(--width-navbar) - 10px);
        height: 3px;
    }

    .app__navbar_bottom_container {
        /* display: none; */
        /* width: auto; */
        /* margin-left: 60px; */
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 20px;
        align-items: flex-start;
    }

    .app__navbar_bottom_info {
        /* display: none; */
        display: flex;
        gap: 10px;
        margin-left: 25px;
    }
}

@media screen and (max-width: 475px) {
    .container_app {
        justify-content: flex-start;
    }
}