.landing__footer {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: space-between;
}

.landing__footer_brand {
    font-family: var(--font-family-Prompt);
    color: rgba(255, 255, 255, 0.9);
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 0.1em;
}

.landing__footer_logo_container {
    margin: 10px 0px;
    display: flex;
    gap: 6px;
}

.landing__footer_logo {
    width: 22px;
    opacity: 0.4;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.landing__footer_logo:hover {
    opacity: 0.8;
}

.landing__footer_text {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    line-height: 12px;
}

.landing__footer_title {
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    letter-spacing: 0.04em;
    margin-bottom: 16px;
}

.landing__footer_link {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 7px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.landing__footer_link:hover {
    color: rgba(255, 255, 255, 0.8);
}

.landing__footer_bloc_left {
    width: 120px;
}

.landing__footer_bloc_middle {
    width: calc(100% - 120px);
    /* padding-right: 120px; */
    display: flex;
    /* justify-content: center; */
    justify-content: flex-end;
    gap: 50px;
}

.landing__footer_bloc {
    width: 190px;
}



@media screen and (max-width: 1000px) {

    .landing__footer {
        flex-direction: column;
        gap: 50px;
    }

    .landing__footer_bloc_middle {
        width: 100%;
        justify-content: flex-start;
    }

}

@media screen and (max-width: 550px) {

    .landing__footer {
        gap: 30px;
    }

    .landing__footer_bloc_middle {
        flex-direction: column;
        gap: 20px;
    }

}