.landing__features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

/* HEADING */
.landing__features_heading {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    margin-right: 5rem;
}

.landing__features_heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    margin-bottom: 2rem;
}

.landing__features_heading p {
    color: var(--color-blue-secondary-1);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    cursor: pointer;
}

/* CONATINER */
.landing__features_container {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.landing__features_item {
    display: flex;
    margin-bottom: 2.5rem;
}

.landing__features_item_title {
    width: 40%;
    margin-right: 10px;
}

.landing__features_item_text {
    width: 60%;
}

.landing__features_item p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-blue-secondary-1);
}

/* RESPONSIVE */

@media screen and (max-width: 1050px) {
    .landing__features_item p {
        font-size: 12px;
    }
}

@media screen and (max-width: 700px) {
    .landing__features {
        flex-direction: column;
    }

    .landing__features_heading a {
        display: none;
    }

    .landing__features_heading p {
        display: none;
    }
}

@media screen and (max-width: 550px) {

    .landing__features_heading {
        margin-right: 0;
    }

    .landing__features_heading h1 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 1.5rem;
    }

    .landing__features_item {
        display: flex;
        margin-bottom: 1.5rem;
    }

}