.app__sru_container {
    display: flex;
    gap: 20px
}

@media screen and (max-width: 550px) {
    .app__sru_container {
        display: flex;
        flex-direction: column;
        gap: 15px
    }
}