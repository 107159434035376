.landing__contact_container {
    display: flex;
}


/* TEXT PART */

.landing__contact_text_container {
    /* background-color: aqua; */
    /* width: 60%; */
    flex: 1;
}

.landing__contact_text_container h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    margin-bottom: 30px;
}

.landing__contact_text_container p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
}


/* FORM PART */

.landing__contact_form_container {
    /* background-color: bisque; */
    width: 40%;
    min-width: 400px;
    margin-left: 20px;
}

.landing__contact_form_box {
    background-color: var(--color-blue-secondary-2);
    /* background-color: rgba(255, 255, 255, 0.2); */
    width: 100%;
    border-radius: 10px;
    padding: 20px 20px 10px 20px;
}

/* NAME MAIL & MESSAGE */
.landing__contact_form_box_name,
.landing__contact_form_box_mail,
.landing__contact_form_box_message {
    padding: 10px 0 0;
    width: 100%;
}

.landing__contact_form_box_message textarea {
    resize: vertical;
    min-height: 100px;
}

/* BUTTON */
.landing__contact_form_box_button {
    padding: 30px 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.landing__contact_form_button {
    outline: none;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* width: 100%;
    max-width: 360px; */
    border-radius: 40px;
    background: #1ecd97;
    border: 2px solid #1ecd97;
    color: #fff;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.25s ease;
    padding: 0px 20px 0px 40px;
    overflow: hidden;
}

.landing__contact_form_button_loading {
    background: transparent;
    width: 40px;
    border: 3px solid #9b9b9b;
    font-size: 0;
    border-left-color: #1ecd97;
    animation: rotating 2s 0.25s linear infinite;
    padding: 0px;
    pointer-events: none;
}

.landing__contact_form_button_loading_success{
    background: transparent;
    border: 2px solid transparent;
    pointer-events: none;
    padding: 0px 20px;
}

.landing__contact_form_button_loading_fail{
    background: #FF5964;
    border: 2px solid #FF5964;
    pointer-events: none;
    padding: 0px 20px;
}

.landing__contact_form_button > img {
    height: 20px;
    position: absolute;
    transform: translateX(-222px);
}

.landing__contact_form_button_starting > img {
    animation: sending 0.3s ease-in-out forwards;
}

.landing__contact_form_message {
    color: #FF5964;
    font-size: 12px;
    font-weight: 800;
    text-align: center;
    margin: 5px 0 0 0;
}


@keyframes sending {
    from {
        transform: translateX(-222px);
    }

    to {
        transform: translateX(0px);
    }
}



/* RESPONSIVE */

@media screen and (max-width: 900px) {
    .landing__contact_container {
        flex-direction: column;
    }

    .landing__contact_form_container {
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
        min-width: 0px;
    }

    .landing__contact_text_container h1 {
        margin-bottom: 20px;
    }
}

/* @media screen and (max-width: 700px) {} */

@media screen and (max-width: 550px) {
    .landing__contact_text_container h1 {
        font-size: 28px;
        line-height: 32px;
    }
}