.app__admin_team_container {
    background-color: var(--color-blue-secondary-2);
    border: 1px solid transparent;
    width: calc(100% - 4px);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;
}

.app__admin_team_container_inactive {
    background-color: #673e469d;
    border: 1px solid transparent;
}

.app__admin_team_container_selected {
    border: 1px solid grey;
}

.app__admin_team_container_unselected {
    opacity: 0.4;
}

.app__admin_team_subcontainer_main {
    flex: 1;
    /* margin-right: 20px; */
}

.app__admin_team_subcontainer_actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    border-left: 1px solid rgba(211, 211, 211, 0.5);
}

.app__admin_team_subcontainer_image {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 84px;
    /* height: 84px; */
    /* background-color: #e66260; */
    margin-right: 20px;
}

.app__admin_team_subcontainer_image_box {
    max-width: 84px;
    max-height: 84px;
    border: 1px solid rgba(211, 211, 211, 0.5);
    background-color: rgba(211, 211, 211, 0.2);
    border-radius: 6px;
}

.app__admin_team_subcontainer_image_box_modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: calc(50% - 41px);
    left: 1px;
    height: 82px;
    width: 82px;
    background-color: rgba(29, 46, 60, 0.5);
    backdrop-filter: blur(4px);
    color: lightgrey;
    text-align: center;
    font-size: 11px;
    border-radius: 6px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.app__admin_team_subcontainer_image_box_modal:hover {
    opacity: 1;
}

.app__admin_team_subcontainer_image_box_modal_button {
    cursor: pointer;
    color: lightgrey;
    transition: all 0.2s ease-in-out;
}

.app__admin_team_subcontainer_image_box_modal_button:hover {
    color: #FFF;
}

.app__admin_team_subcontainer_image_placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 84px;
    border: 1px dashed rgba(211, 211, 211, 1);
    border-radius: 6px;
    color: lightgrey;
    text-align: center;
    font-size: 11px;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    padding: 6px;
}

.app__admin_team_subcontainer_image_placeholder:hover {
    opacity: 0.9;
}

.app__admin_team_subcontainer_image_placeholder_loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 84px;
    border: 1px dashed rgba(211, 211, 211, 1);
    border-radius: 6px;
    color: lightgrey;
    text-align: center;
    font-size: 11px;
    opacity: 0.9;
    transition: all 0.2s ease-in-out;
    cursor: default;
    pointer-events: none;
}

.app__admin_team_title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 4px;
}

.app__admin_team_label {
    color: lightgrey;
    font-size: 12px;
}

.app__admin_team_value {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
}

.app__admin_team_value_small {
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
}

.app__admin_team_body {
    display: flex;
}

.app__admin_team_members_container {
    width: 50%;
}

.app__admin_team_capa_container {
    width: 50%;
}

.app__admin_team_capa_container2 {
    /* display: flex; */
    /* justify-content: space-between; */
    width: 50%;
}

.app__admin_team_member {
    border: 1px solid var(--color-green-main-1);
    /* border: 1px solid #FFF; */
    border-radius: 10px;
    font-size: 13px;
    line-height: 13px;
    padding: 1px 8px 2px;
    width: fit-content;
    margin-bottom: 3px;
}

.app__admin_team_member_invalid {
    /* border: 1px solid rgb(231 156 156); */
    border: 1px solid lightgrey;
}

.app__admin_team_member>img {
    height: 8px;
    margin-left: 6px;
    cursor: pointer;
}

.app__admin_team_button_hide {
    opacity: 0 !important;
}

.app__admin_team_button {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 18px;
    height: 24px;
    width: 160px;
    border-radius: 20px;
    /* border: 1px solid var(--color-green-main-1); */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-bottom: 4px;
    opacity: 1;
}

.app__admin_team_button:hover {
    background-color: var(--color-green-main-1);
    color: #fff;
}

.app__admin_team_button>img,
.app__admin_team_button_red>img {
    height: 12px;
    margin-right: 4px;
}

.app__admin_team_button_red {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 18px;
    height: 24px;
    width: 160px;
    border-radius: 20px;
    /* border: 1px solid #DD1C1A; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-bottom: 4px;
}

.app__admin_team_button_red:hover {
    background-color: #DD1C1A;
    color: #fff;
}


/* MODAL */
.app__admin_team_modal {
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(6px);
    /* background-color: #1d2e3c86; */
    background-color: #06213486;
    width: 100%;
    height: 100%;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__admin_team_modal_buttons {
    margin-top: 6px;
    display: flex;
    justify-content: center;
    gap: 10px;
    position: relative;
    width: 100%;
}

.app__admin_team_modal_button {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 18px;
    height: 24px;
    width: 100px;
    border-radius: 20px;
    border: 2px solid var(--color-green-main-1);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-bottom: 4px;
}

.app__admin_team_modal_button:hover {
    background-color: var(--color-green-main-1);
    color: #fff;
}


.app__admin_team_modal_button_red {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 18px;
    height: 24px;
    width: 100px;
    border-radius: 20px;
    border: 2px solid #DD1C1A;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-bottom: 4px;
}

.app__admin_team_modal_button_red:hover {
    background-color: #DD1C1A;
    color: #fff;
}

.app__admin_team_modal_input {
    width: 80%;
}

.app__admin_team_modal_button_loading {
    border-top: 2px solid var(--color-green-main-1);
    border-left: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    height: 24px;
    width: 24px;
    background-color: transparent !important;
    animation: rotating 2s 0.25s linear infinite;
    pointer-events: none;
}

.app__admin_team_modal_button_loading_success {
    background-color: var(--color-green-main-1);
    pointer-events: none;
}

.app__admin_team_modal_button_loading_fail {
    border: 2px solid #DD1C1A;
    background-color: #DD1C1A !important;
    pointer-events: none;
}

.app__admin_team_modal_message {
    color: #e66260;
    font-size: 13px;
    line-height: 14px;
    font-weight: 600;
    text-align: center;
    position: absolute;
    top: 26px;
    width: 100%;
    display: flex;
    justify-content: center;
}



@media screen and (max-width: 550px) {

    .app__admin_team_subcontainer_main {
        margin-right: 0;
    }

    .app__admin_team_container {
        flex-direction: column;
        margin-right: 0;
    }

    .app__admin_team_body {
        flex-direction: column;
    }

    .app__admin_team_members_container {
        width: 100%;
    }

    .app__admin_team_capa_container2 {
        width: 100%;
        justify-content: space-around;
        margin-top: 6px;
    }

    .app__admin_team_capa_container {
        width: fit-content;
    }

    .app__admin_team_subcontainer_actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0px;
        padding-top: 6px;
        margin-top: 6px;
        border-left: none;
        border-top: 1px solid rgba(211, 211, 211, 0.33);
    }
}

.app__admin_team_blocker{
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.app__admin_team_acces_container {
    width: 100%;
    padding: 0 30px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(5, 1fr); */
    /* grid-column-gap: 0px; */
    grid-row-gap: 6px;
    margin: 8px 0;
}

.app__admin_team_acces_item {
    display: flex;
    gap: 6px;
}

.app__admin_team_acces_item_label {
    font-size: 12px;
}

.app__admin_team_acces_item_label_small {
    font-size: 10px;
    color: lightgrey;
}