.release__title_container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 170px;
    /* padding: 100px 6rem 0; */
    padding: 100px 50px 0;
}

.release__title {
    color: #FFF;
    font-size: 36px;
    line-height: 36px;
    font-weight: 600;
}

.release__subtitle {
    color: #ffffffc5;
    font-size: 13px;
    font-weight: 400;
}

.release__data_container {
    width: 100vw;
    height: calc(100vh - 220px);
    margin-top: 50px;
    /* background-color: rgb(58, 77, 70); */
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
}

.release__item_container {
    width: 100%;
    min-height: 60px;
    /* background-color: bisque; */
    position: relative;
    display: flex;
    /* flex-direction: column; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* padding: 0 6rem; */
    padding: 0 50px;
}

.release__item_shapes {
    /* background-color: green; */
    display: flex;
    position: relative;
    width: 22px;
}

.release__item_circle {
    width: 16px;
    height: 16px;
    background-color: rgba(211, 211, 211, 0.5);
    /* border: 2px solid rgba(211, 211, 211, 0.3); */
    border-radius: 30px;
    position: relative;
    top: 0px;
    left: 3px;
}

.fade_in_circle {
    -webkit-animation: fade-in-fwd 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-fwd 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.release__item_line {
    width: 2px;
    height: calc(100% - 16px);
    background-color: rgba(211, 211, 211, 0.5);
    position: relative;
    top: 16px;
    left: -6px;
}

.fade_in_line {
    --webkit-animation: fade-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: fade-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.release__item_box {
    width: calc(50% - 20px);
    height: fit-content;
    position: relative;
    padding-bottom: 50px;  
}

.release__item_box_left {
    padding-right: 10px;
}

.fade_in_box_left {
    -webkit-animation: fade-in-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-left 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.release__item_box_right {
    padding-left: 10px;
    transform: translateX(calc(100% + 20px));
}

.fade_in_box_right {
    -webkit-animation: fade-in-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.release__item_subox {
    width: 100%;
    min-height: 100px;
    background-color: rgba(29, 46, 60, 0.8);
    /* background-color: var(--color-blue-secondary-2); */
    border-radius: 6px;
    position: relative;
    padding: 10px 12px;
}

.release__item_date {
    position: absolute;
    top: -18px;
    left: 0px;
    color: rgba(211, 211, 211, 0.7);
    font-size: 11px;
    font-weight: 600;
}

.release__item_title_group {
    display: flex;
    gap: 10px;
    /* height: 24px; */
    justify-content: flex-start;
    align-items: flex-start;
    /* margin-bottom: 6px; */
}

.release__item_version {
    border: 1px solid #FFF;
    /* border: 1px solid rgba(211, 211, 211, 1); */
    border-radius: 6px;
    padding: 1px 4px;
    font-size: 12px;
    color: #FFF;
}

.release__item_title {
    font-size: 16px;
    line-height: 17px;
    font-weight: 700;
    color: #FFF;
    /* color: rgba(211, 211, 211, 1); */
    margin-top: 1px;
}

.release__item_label {
    font-size: 11px;
    line-height: 14px;
    font-weight: 700;
    color: grey;
    margin-top: 6px;
}

.release__item_text {
    color: rgba(211, 211, 211, 1);
    font-size: 13px;
}

.release__item_detail_container {
    /* margin-top: 6px; */
}

.release__item_detail_box {
    height: fit-content;
    max-height: 1500px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}

.release__item_detail_box_hidden {
    max-height: 0px;
}

.release__item_detail_arrow_button {
    width: 100%;
    position: relative;
    opacity: 0.5;
    cursor: pointer;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    transition: all 0.2s ease;
}

.release__item_detail_arrow_button:hover {
    opacity: 0.98;
}

.release__item_detail_arrow {
    height: 11px;
    transition: all 0.4s ease 0.3s;
}

.release__item_detail_arrow_up {
    
}

.release__item_detail_arrow_down {
    transform: rotateX(180deg);
}

.release__item_detail_label {
    color: rgba(211, 211, 211, 1);
    font-size: 13px;
    font-weight: 600;
    margin-top: 4px;
}

.release__item_detail_description {
    padding-left: 12px;
    color: rgba(211, 211, 211, 0.9);
    font-size: 12px;
    line-height: 14px;
    font-style: italic;
    white-space: pre-line;
}

.release__item_detail_separator {
    width: calc(100% - 12px);
    height: 1px;
    margin: 12px 0 8px 12px;
    background-color: rgba(211, 211, 211, 0.1);
}

.release__item_detail_img {
    margin-top: 4px;
    padding-left: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.release__item_detail_img > img {
    width: 100%;
    border-radius: 6px;
}



/* SCROLLBAR */
.release__data_container:hover::-webkit-scrollbar-track {
    background-color: rgba(100, 100, 100, 0.2);
}

.release__data_container:hover::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.5);
}

.release__data_container::-webkit-scrollbar {
    width: 4px;
    height: 0px;
}

.release__data_container::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: rgba(100, 100, 100, 0);
    transition: all 0.3s ease;
}

.release__data_container::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(100, 100, 100, 0);
    transition: all 0.3s ease;
}


/* ANIMATIONS */

@-webkit-keyframes fade-in-fwd {
    0% {
        -webkit-transform: translateZ(-50px);
        transform: translateZ(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes fade-in-fwd {
    0% {
        -webkit-transform: translateZ(-50px);
        transform: translateZ(-50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@-webkit-keyframes fade-in-top {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        opacity: 1;
    }
}

@keyframes fade-in-top {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        opacity: 1;
    }
}

@-webkit-keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(calc(100% + 20px - 30px));
        transform: translateX(calc(100% + 20px - 30px));
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(calc(100% + 20px));
        transform: translateX(calc(100% + 20px));
        opacity: 1;
    }
}

@keyframes fade-in-right {
    0% {
        -webkit-transform: translateX(calc(100% + 20px - 30px));
        transform: translateX(calc(100% + 20px - 30px));
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(calc(100% + 20px));
        transform: translateX(calc(100% + 20px));
        opacity: 1;
    }
}

@-webkit-keyframes fade-in-left {
    0% {
        -webkit-transform: translateX(30px);
        transform: translateX(30px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-in-left {
    0% {
        -webkit-transform: translateX(30px);
        transform: translateX(30px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.anim_index_0 {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.anim_index_1 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.anim_index_2 {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

.anim_index_3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

/* .anim_index_4 {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
} */

/* .anim_index_5 {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

.anim_index_6 {
    -webkit-animation-delay: 3.5s;
    animation-delay: 3.5s;
} */



/* RESPONSIVE */

@media screen and (max-width: 1050px) {

    .release__title_container {
        /* justify-content: flex-start; */
        align-items: flex-start;
    }

    .release__item_shapes {
        display: flex;
        position: relative;
        left: calc(-100% + 22px);
    }

    .release__item_box {
        width: calc(100% - 20px);
    }

    .release__item_box_left {
        transform: translateX(20px);
        padding-left: 10px;
        padding-right: 0;
    }

    .release__item_box_right {
        transform: translateX(20px);
    }

    @-webkit-keyframes fade-in-left {
        0% {
            -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
            opacity: 0;
        }
    
        100% {
            -webkit-transform: translateX(20);
            transform: translateX(20);
            opacity: 1;
        }
    }
    
    @keyframes fade-in-left {
        0% {
            -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
            opacity: 0;
        }
    
        100% {
            -webkit-transform: translateX(20);
            transform: translateX(20);
            opacity: 1;
        }
    }

    @-webkit-keyframes fade-in-right {
        0% {
            -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
            opacity: 0;
        }
    
        100% {
            -webkit-transform: translateX(20);
            transform: translateX(20);
            opacity: 1;
        }
    }
    
    @keyframes fade-in-right {
        0% {
            -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
            opacity: 0;
        }
    
        100% {
            -webkit-transform: translateX(20);
            transform: translateX(20);
            opacity: 1;
        }
    }
}

/* @media screen and (max-width: 700px) {
    .release__title_container {
        padding: 0 3rem;
    }

    .release__item_container {
        padding: 0 3rem;
    }
} */

@media screen and (max-width: 550px) {
    .release__title {
        font-size: 28px;
        line-height: 30px;
    }

    .release__title_container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .release__item_container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .release__data_container {
        margin-top: 30px;
    }
}