.landing__navbar {
    /* background-color: brown; */
    background: transparent;
    padding: 1rem 6rem;
    position: fixed;
    width: 100%;
    height: 97px;
    z-index: 100;
    transition: background-color 0.4s ease, height 1s ease;
}

.landing__navbar_bg {
    background: var(--color-blue-main-1);
}

.landing__navbar_fullsize {
    background: var(--gradient-blue-background);
    height: 100%;
}

.landing__navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.landing__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.landing__navbar-links_logo {
    margin-right: 2rem;
}

.landing__navbar-links_logo img {
    width: 60px;
}

.landing__navbar-links_container {
    display: flex;
    flex-direction: row;
    opacity: 1;
    transition: opacity 0.2s ease 0.8s;
}

.landing__navbar-links_container_fullsize {
    opacity: 0;
}

.landing__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    opacity: 1;
    transition: opacity 0.2s ease 0.8s;
}

.landing__navbar-sign_fullsize {
    opacity: 0;
}

.landing__navbar-links_container p,
.landing__navbar-sign p,
.landing__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.landing__navbar-sign button,
.landing__navbar-menu_container button {
    padding: 0.8rem 1.4rem;
    color: #062134;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.5s;
    background-size: 200% auto;
    background-image: linear-gradient(to right, #88FF9F 0%, #6FFEDB 51%, #88FF9F 100%);
}

.landing__navbar-sign button:hover,
.landing__navbar-menu_container button:hover {
    background-position: right center;
}

.landing__navbar-sign_button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.landing__navbar-sign_userpp {
    width: 26px;
    height: 26px;
    object-fit: cover;
    border-radius: 50%;
}

.landing__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.landing__navbar-menu svg {
    cursor: pointer;
}

.landing__navbar-menu img {
    height: 30px;
}

.landing__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-blue-main-0);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.landing__navbar-menu_container p {
    margin: 1rem 0;
}

.landing__navbar-menu_container-links-sign {
    display: none;
}


/* RESPONSIVE */

@media screen and (max-width: 1050px) {
    .landing__navbar-links_container {
        display: none;
    }

    .landing__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .landing__navbar {
        padding: 1rem 3rem;
    }
}

@media screen and (max-width: 550px) {
    .landing__navbar {
        padding: 1rem 2rem;
        height: 76px;
    }

    .landing__navbar_fullsize {
        height: 100%;
    }

    .landing__navbar-sign {
        display: none;
    }

    .landing__navbar-menu_container {
        top: 20px;
    }

    .landing__navbar-menu_container-links-sign {
        display: block;
    }

    .landing__navbar-links_logo img {
        width: 40px;
    }
}