.title__container {
    flex: 1;
    max-width: 180px;
    margin-right: 2rem;
}

.title__container h1 {
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
}

.title__container div {
    width: 38px;
    height: 3px;
    background: var(--gradient-green-bar);
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    margin-bottom: 0.25rem;
}

/* RESPONSIVE */

/* @media screen and (max-width: 1050px) {} */

/* @media screen and (max-width: 700px) {} */

@media screen and (max-width: 550px) {

    .title__container h1 {
        font-size: 16px;
        line-height: 20px;
    }

}