.logo__container path:nth-child(1) {
    stroke-dasharray: 2700px;
    stroke-dashoffset: 2700px;
}

.logo__path1-animate {
    animation: line-anim 1.4s ease forwards 0.6s;
}

.logo__container path:nth-child(2) {
    stroke-dasharray: 2400px;
    stroke-dashoffset: 2400px;
}

.logo__path2-animate {
    animation: line-anim 1.7s ease forwards 0.3s;
}

.logo__container path:nth-child(3) {
    stroke-dasharray: 2100px;
    stroke-dashoffset: 2100px;
}

.logo__path3-animate {
    animation: line-anim 2s ease forwards 0s;
}

.logo__fill-body-stop1 {
    stop-color: transparent;
    transition: 0.5s ease 2s;
}

.animate-stop1 {
    stop-color: #88ff9f;
}

.logo__fill-body-stop2 {
    stop-color: transparent;
    transition: 0.5s ease 2s;
}

.animate-stop2 {
    stop-color: #1ecd97;
}

@keyframes line-anim {
    to {
        stroke-dashoffset: 0;
    }
}