.error__container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: var(--gradient-blue-background);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error__content_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: var(--font-family);
    margin: 0px 10px;
}

.error__content_title {
    font-size: 40px;
}

.error__content_title_img {
    max-width: 500px;
    width: 80vw;
}

.error__content_subtitle {
    font-size: 26px;
    margin: 20px 0px 30px;
}

.error__content_text {
    text-align: center;
    margin: 20px 0px 30px 0px;
}

.error__link {
    color: #FFF;
    margin: 0px 2px;
    text-decoration: underline;
    transition: all 0.2s ease;
    cursor: pointer;
}

.error__link:hover {
    color: var(--color-green-main-1);
}

.error__content_button_container {
    display: flex;
    gap: 10px;
}

.error__content_button {
    background-color: transparent;
    color: var(--color-green-main-1);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    height: 32px;
    width: 200px;
    border-radius: 20px;
    border: 1px solid var(--color-green-main-1);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
}

.error__content_button:hover {
    background-color: var(--color-green-main-1);
    color: var(--color-blue-main-1);
}

.error__version {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-green-main-1);
    font-size: 8.5px;
    font-weight: 700;
    margin: 10px 0;
    text-align: center;
}

@media screen and (max-width: 550px) {

    .error__content_subtitle {
        font-size: 20px;
    }

    .error__content_button_container {
        flex-direction: column;
    }

}