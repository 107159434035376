/* CONTAINER */
.app__landsvalidation_container {
    /* background-color: red; */
    position: relative;
    left: 0px;
    bottom: 50px;
    width: 100%;
    height: 0px;
    z-index: 10003;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transition: all 0.8s;
}

.app__landsvalidation_container_extanded {
    width: calc(100% - var(--width-section) - 15px);
    left: calc(var(--width-section) + 15px);
}

/* BUTTON */
.app__landsvalidation_button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-green-main-1);
    color: #FFF;
    border-radius: 36px;
    height: 36px;
    padding: 0px 18px;
    font-weight: 600;
    box-shadow: 0 0 14px rgb(100 100 100 / 20%);
}

.app__landsvalidation_error {
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDACAC;
    color: #DD1C1A;
    border-radius: 36px;
    height: 36px;
    padding: 0px 18px;
    font-weight: 600;
    box-shadow: 0 0 14px rgb(100 100 100 / 20%);
}

/* POPUP */
.app__landsvalidation_popup_container {
    position: absolute;
    bottom: 0px;
    background-color: var(--color-blue-main-0);
    color: #FFF;
    /* height: 300px; */
    width: 350px;
    padding: 20px;
    border-radius: 0.7rem;
    box-shadow: 0 0 14px rgb(100 100 100 / 20%);
    z-index: 11000;
    display: flex;
    flex-direction: column;
}

.app__landsvalidation_popup_title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
}

.app__landsvalidation_popup_description {
    font-size: 13px;
    margin-bottom: 30px;
    text-align: center;
}

.app__landsvalidation_popup_button_container {
    display: flex;
    justify-content: space-between;
}

.app__landsvalidation_popup_button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    background-color: var(--color-green-main-1);
    color: #FFF;
    border-radius: 30px;
    height: 30px;
    font-size: 13px;
    font-weight: 600;
}

.app__landsvalidation_popup_button_second {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    color: #FFF;
    background-color: #DD1C1A;
    border-radius: 30px;
    height: 30px;
    font-size: 13px;
    font-weight: 600;
}

.app__landsvalidation_popup_bg {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: var(--color-grey-main-1);
    opacity: 0.8;
    width: 100%;
    height: 100%;
    z-index: 10002;
}

/* RESPONSIVE */

@media screen and (max-width: 550px) {
    .app__landsvalidation_container {
        bottom: 30px;
    }
}