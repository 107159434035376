.admin__users_container {
    max-height: calc(100% - 33px - 19px);
    overflow-y: auto;
    opacity: 1;
    transition: all 0.2s ease;
    padding-right: 4px;
    position: relative;
}

.app__admin_users_container {
    overflow-y: visible;
    position: relative;
}

.app__admin_users_title {
    font-weight: 600;
}

.app__admin_users_mail {
    color: lightgrey;
    font-size: 13px;
}

.app__admin_users_mail>img {
    height: 10px;
    margin-left: 4px;
}

.app__admin_users_separator {
    height: 1px;
    background-color: lightgrey;
    opacity: 0.4;
    margin: 6px 0px;
}

.app__admin_users_body {
    display: flex;
    align-items: center;
    padding: 3px 0;
    background-color: rgba(141, 141, 141, 0);
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.app__admin_users_body:hover {
    background-color: rgba(141, 141, 141, 0.1);
}

.app__admin_users_body2 {
    display: flex;
    align-items: center;
    width: 55%;
}

.app__admin_users_avatar {
    height: 32px;
    aspect-ratio: 1;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
}

.app__admin_users_team {
    /* opacity: 0.7; */
    width: 35%;
}

.app__admin_users_noteam {
    /* opacity: 0.7; */
    color: rgb(231 156 156);
    cursor: pointer;
    width: 35%;
    position: relative;
}

.app__admin_users_noteam_list {
    position: absolute;
    top: 0px;
    left: -10px;
    z-index: 100000;
    background-color: var(--color-blue-secondary-2);
    color: #FFF;
    border-radius: 8px;
    width: 100%;
    padding: 8px 10px;
    cursor: default;
}

.app__admin_users_noteam_list_item {
    cursor: pointer;
    margin-top: 4px;
    font-size: 12px;
    opacity: 0.7;
    transition: all 0.2s ease-in-out;
}

.app__admin_users_noteam_list_item:hover {
    opacity: 1;
}

.app__admin_users_capa {
    /* opacity: 0.7; */
    width: 10%;
    text-align: right;
}

.app__admin_users_labels {
    display: flex;
    color: grey;
    font-size: 11px;
    margin-bottom: 4px;
    padding-right: 4px;
}

.app_admin_users_label0 {
    width: 55%;
    padding-left: 38px;
}

.app_admin_users_label00 {
    width: 50%;
}

.app_admin_users_label1 {
    width: 35%;
}

.app_admin_users_label2 {
    width: 10%;
    text-align: right;
}

.app_admin_users_label3 {
    width: 15%;
    text-align: right;
}

.app__admin_users_modal{
    background-color: transparent;
    /* backdrop-filter: blur(2px); */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__admin_users_modal_box{
    max-width: 80%;
    /* height: 50%; */
    background-color: var(--color-blue-secondary-2);
    -webkit-box-shadow: 0px 0px 14px 3px rgba(8, 18, 32, 1);
    -moz-box-shadow: 0px 0px 14px 3px rgba(8, 18, 32, 1);
    box-shadow: 0px 0px 14px 3px rgba(8, 18, 32, 1);
    color: #FFF;
    font-size: 15px;
    font-weight: 500;
    border-radius: 8px;
    padding: 18px 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}