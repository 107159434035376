/* PLACEHOLDER */
.ruleeditor__node_ph_container {
    background-color: var(--color-blue-secondary-2);
    border: 1px dashed grey;
    border-radius: 6px;
    max-width: 180px;
    min-width: 180px;
    min-height: 105px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ruleeditor__node_ph_container_rule {
    background-color: var(--color-blue-secondary-2);
    border: 1px dashed grey;
    border-radius: 6px;
    max-width: 340px;
    min-width: 340px;
    min-height: 190px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ruleeditor__node_ph_container_rule_small {
    background-color: var(--color-blue-secondary-2);
    border: 1px dashed grey;
    border-radius: 6px;
    max-width: 340px;
    min-width: 340px;
    min-height: 92px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ruleeditor__node_ph_description {
    color: grey;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ruleeditor__node_ph_body {
    color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ruleeditor__node_ph_body_button {
    font-size: 24px;
    cursor: pointer;
}

/* PLACEHOLDER LIST */
.ruleeditor__node_ph_list_container {
    background-color: var(--color-blue-secondary-2);
    border: 1px solid grey;
    border-radius: 6px;
    position: absolute;
    left: 7px;
    top: 45px;
    max-width: 166px;
    min-width: 166px;
    padding: 4px;
}

.ruleeditor__node_ph_list_container_rule {
    background-color: var(--color-blue-secondary-2);
    border: 1px solid grey;
    border-radius: 6px;
    position: absolute;
    left: 10px;
    top: 95px;
    max-width: 320px;
    min-width: 320px;
    padding: 6px;
}

.ruleeditor__node_ph_list_container_close {
    position: absolute;
    top: -3px;
    right: 4px;
    height: 10px;
}

.ruleeditor__node_ph_list_container_close>img {
    height: 8px;
    cursor: pointer;
}

.ruleeditor__node_ph_list_container_label {
    color: #FFF;
    font-size: 10px;
    margin-bottom: 10px;
    margin-top: 4px;
}

.ruleeditor__node_ph_list {
    max-height: 130px;
    overflow-y: auto;
    position: relative;
}

.ruleeditor__node_ph_list_rule {
    max-height: 160px;
    overflow-y: auto;
    position: relative;
}

.ruleeditor__node_ph_list_group_container {
    position: relative;
}

.ruleeditor__node_ph_list_group_label {
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    height: 14px;
    color: grey;
    margin: 8px 0px 4px;
    position: sticky;
    top: -1px;
    background-color: var(--color-blue-secondary-2);
}

.ruleeditor__node_ph_list_group_label_top {
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    height: 14px;
    color: grey;
    margin: 0px 0px 4px;
    position: sticky;
    top: -1px;
    background-color: var(--color-blue-secondary-2);
}

.ruleeditor__node_ph_list_group_label_rule {
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    height: 14px;
    color: grey;
    margin: 14px 0px 4px;
    position: sticky;
    top: -1px;
    background-color: var(--color-blue-secondary-2);
}

.ruleeditor__node_ph_list_group_label_top_rule {
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    height: 14px;
    color: grey;
    margin: 0px 0px 4px;
    position: sticky;
    top: -1px;
    background-color: var(--color-blue-secondary-2);
}

.ruleeditor__node_ph_list_item {
    cursor: pointer;
    padding: 4px 6px;
    margin-top: 4px;
    border-radius: 4px;
    background-color: rgba(100, 100, 100, 0.2);
    transition: all 0.2s ease;
}

.ruleeditor__node_ph_list_item_dnd {
    cursor: grab;
    padding: 4px 6px;
    margin-top: 4px;
    border-radius: 4px;
    background-color: rgba(100, 100, 100, 0.2);
    transition: all 0.2s ease;
}

.ruleeditor__node_ph_list_item:hover {
    background-color: rgba(100, 100, 100, 0.5);
}

.ruleeditor__node_ph_list_item_label {
    color: #FFF;
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 6px;
}

.ruleeditor__node_ph_list_item_description {
    color: grey;
    font-size: 10px;
    line-height: 11px;
    margin-bottom: 2px;
}

.ruleeditor__node_ph_list_item_source {
    color: var(--color-green-main-1);
    font-size: 8px;
    font-style: italic;
    line-height: 10px;
    text-align: right;
}


/* CONTAINER */
.ruleeditor__node_container {
    background-color: var(--color-blue-secondary-2);
    border: 1px solid grey;
    border-radius: 6px;
    max-width: 180px;
    min-width: 180px;
    min-height: 105px;
    display: flex;
    flex-direction: column;
}

/* TITLE */
.ruleeditor__node_title {
    border-bottom: 1px solid grey;
    padding: 4px 10px;
    min-height: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: grab;
}

.ruleeditor__node_title_label {
    color: #FFF;
    font-size: 14px;
    line-height: 15px;
}

.ruleeditor__node_title_close>img {
    height: 9px;
    cursor: pointer;
}

.ruleeditor__node_center {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* DESCRIPTION */
.ruleeditor__node_description {
    margin-top: 6px;
    color: grey;
    font-size: 10px;
    line-height: 11px;
    padding: 2px 10px;
}

/* IMAGE */
.ruleeditor__node_img {
    position: absolute;
    right: 20px;
    bottom: 34px;
    width: 60px;
}

/* BODY */
.ruleeditor__node_body_box {
    width: 100%;
    display: flex;
}

.ruleeditor__node_body_right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    color: #FFF;
}

.ruleeditor__node_body_right_value {
    /* border: 1px solid lightgrey; */
    background-color: rgba(200, 200, 200, 0.1);
    border-radius: 4px;
    padding: 5px 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ruleeditor__node_body_right_value_main {
    font-size: 17px;
    font-weight: 600;
}

.ruleeditor__node_body_right_value_second {
    font-size: 12px;
}

.ruleeditor__node_body {
    padding: 2px 10px 10px 10px;
    /* padding: 2px 0px 10px 0px; */
}

.ruleeditor__node_body>input {
    width: 100%;
}

.ruleeditor__node_body>input[type=number] {
    -moz-appearance: textfield;
}

.ruleeditor__node_body>input[type=number]::-webkit-outer-spin-button,
.ruleeditor__node_body>input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* HANDLE */
.ruleeditor__handle_spacer {
    height: 29px;
}

.ruleeditor__handle_target {
    margin-top: 5px;
    color: #FFF;
    min-height: 14px;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.ruleeditor__handle_target_type {
    color: grey;
    font-size: 8px;
    font-style: italic;
    line-height: 10px;
    max-height: 10px;
    min-height: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    transition: color 0.2s ease;
}

.ruleeditor__handle_source {
    margin-top: 5px;
    color: #FFF;
    min-height: 14px;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
}

.ruleeditor__handle_source_type {
    color: grey;
    font-size: 8px;
    font-style: italic;
    line-height: 10px;
    max-height: 10px;
    min-height: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: right;
    transition: color 0.2s ease;
}

.ruleeditor__handle_type_valid {
    color: var(--color-green-main-1);
}

.react-flow__handle {
    background: #FFF !important;
    border: none !important;
    height: 8px !important;
    width: 8px !important;
}

.react-flow__node {
    cursor: auto;
}

/* INPUT */

.ruleeditor__handle_source_input {
    margin-top: 5px;
    color: #FFF;
    background-color: var(--color-blue-secondary-2);
    border-bottom: 1px solid rgba(100, 100, 100, 0.2);
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    min-height: 14px;
    font-size: 12px;
    font-family: var(--font-family-Manrope);
    text-align: right;
    transition: all 0.2s ease;
}

.ruleeditor__handle_source_input:hover {
    border-bottom: 1px solid rgba(100, 100, 100, 0.6);
}

.ruleeditor__handle_source_select {
    margin: 8px 0px 2px 0px;
    color: #FFF;
    background-color: var(--color-blue-secondary-2);
    /* border-bottom: 1px solid rgba(100, 100, 100, 0.2); */
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    min-height: 14px;
    font-size: 12px;
    font-family: var(--font-family-Manrope);
    transition: all 0.2s ease;
}

.ruleeditor__handle_source_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
}

.ruleeditor__input_title {
    position: inherit;
    font-size: 11px;
    line-height: 12px;
    font-weight: 600;
    color: #FFF;
    margin: 4px 0;
}

.ruleeditor__input_checkbox_container {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    margin-bottom: 3px
}

.ruleeditor__input_checkbox {
    appearance: auto;
    accent-color: var(--color-green-main-1);
    height: 11px;
}

.ruleeditor__input_checkbox_label {
    color: #FFF;
    font-size: 10px;
    line-height: 11px
}


/* RULE */
.ruleeditor__node_container_rule {
    background-color: var(--color-blue-secondary-2);
    border: 1px solid grey;
    border-radius: 6px;
    max-width: 340px;
    min-width: 340px;
    /* min-height: 105px; */
    display: flex;
    flex-direction: column;
}

.ruleeditor__node_container_rule_body {
    min-height: 100%;
    display: flex;
}

.ruleeditor__node_three_container {
    width: 180px;
    height: 160px;
    /* height: 100%; */
}

.ruleeditor__node_category_container {
    display: flex;
    gap: 6px;
    justify-content: center;
    font-size: 9px;
    margin-bottom: 4px;
    /* padding-bottom: 3px;
    border-bottom: 1px solid rgba(100, 100, 100, 0.3); */
}

.ruleeditor__node_category_selected {
    cursor: pointer;
    background-color: var(--color-green-main-1);
    padding: 0px 5px 1px;
    border-radius: 15px;
    color: var(--color-blue-main-1);
}

.ruleeditor__node_category_unselected {
    cursor: pointer;
    color: #FFF;
}




.ruleeditor_target_separator {
    height: 1px;
    width: 50px;
    background-color: grey;
    margin: 6px 0px;
}