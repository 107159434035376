/* GPU */

.app__ruleset_group_container {}

.app__ruleset_group_header {
    cursor: pointer;
    /* min-height: 30px; */
    display: flex;
    align-items: center;
    padding: 6px 0px;
}

.app__ruleset_group_header>div {
    line-height: 15px;
}

.app__ruleset_group__header_ico {
    transform: rotateZ(0deg);
    transition: all 0.4s ease;
}

.app__ruleset_group__header_ico_open {
    transform: rotateZ(90deg);
}

.app__ruleset_group {
    overflow: hidden;
    max-height: 0px;
    transition: all 0.4s ease;
}

.app__ruleset_group_open {
    overflow-y: auto;
    max-height: 400px;
}

.app__ruleset_file_container {
    display: flex;
    align-items: center;
    /* height: 30px; */
    padding: 0px 0px 4px 16px;
}

.app__ruleset_ico {
    width: 12px;
    margin-right: 4px;
}

.app__ruleset_ico>img {
    width: 100%;
}

.app__ruleset_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    font-size: 11px;
    font-weight: 600;
    line-height: 10px;
    border-radius: 12px;
    padding: 4px 10px;
    cursor: pointer;
    background-color: var(--color-green-main-1);
    color: #FFF;
    transition: all 0.2s ease;
}

.app__ruleset_download_container {
    display: flex;
    gap: 4px;
    cursor: pointer;
}

.app__ruleset_download_container:hover>div.app__ruleset_download_text {
    opacity: 1;
}

.app__ruleset_download_text {
    font-size: 12px;
    color: grey;
    opacity: 0;
    transition: all 0.2s ease;
}

.app__ruleset_download_ico>img {
    height: 14px;
}


/* DRAG HANDLE */
.drag-handle_category {
    /* background-color: lightgrey; */
    border-top: 2px solid lightgrey;
    height: 8px;
    width: 50px;
    opacity: 0.5;
    transition: opacity 0.2s ease;
}

.draggable-category:hover>.drag-handle_category {
    opacity: 1;
}

.drag-handle_item {
    /* background-color: lightgrey; */
    border-top: 2px solid lightgrey;
    height: 5px;
    width: 30px;
    opacity: 0.5;
    transition: opacity 0.2s ease;
}

.app__ruleset_title_container:hover>.drag-handle_item,
.app__ruleset_rule_container:hover>.drag-handle_item {
    opacity: 1;
}


/* PLACEHOLDER */
.app__ruleset_placeholder_container_box {
    width: 100%;
    display: flex;
    gap: 8px;
}

.app__ruleset_placeholder_container_box>div {
    width: 100%;
}

.app__ruleset_placeholder_container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.7rem;
    border: 1px dashed rgba(100, 100, 100, 0.15);
    margin-top: 8px;
    padding: 4px 6px 5px;
    color: grey;
    font-size: 12px;
    transition: all 0.2s ease;
}

.app__ruleset_placeholder_container:hover {
    border: 1px dashed rgba(100, 100, 100, 0.4);
    /* border: 1px dashed var(--color-green-main-1); */
}

/* DOCUMENT */
.draggable-category {
    background-color: #fff;
    border-radius: 0.7rem;
    box-shadow: 0 0 14px rgb(100 100 100 / 10%);
    padding: 14px;
    width: 100%;
    margin-bottom: 10px;
}

.draggable-category:hover>div.category-container>div.app__ruleset_document>div.app__ruleset_document_header>div.app__ruleset_document_button_container {
    opacity: 1;
}

.app__ruleset_document {
    position: relative;
}

.app__ruleset_document_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 20px;
    /* gap: 6px; */
}

.app__ruleset_document_title {
    font-size: 17px;
    line-height: 19px;
    font-weight: 700;
}

.app__ruleset_document_title_input {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid transparent;
    background-color: transparent;
    outline: 0;
    font-family: inherit;
    font-size: 17px;
    /* line-height: 21px; */
    font-weight: 700;
    transition: border-color 0.2s;
}

.app__ruleset_document_title_input:hover {
    border-bottom: 1px solid lightgrey;
}

.app__ruleset_document_title_numerotation{
    font-size: 17px;
    line-height: 19px;
    font-weight: 700;
    margin-bottom: 1px;
    margin-right: 2px;
}

.app__ruleset_document_button_container {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: -15px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.app__ruleset_document_button_container>img {
    height: 12px;
}

.app__ruleset_document_actions_container_old {
    z-index: 1000;
    background-color: var(--color-blue-secondary-2);
    border-radius: 0.7rem;
    box-shadow: 0 0 14px rgb(100 100 100 / 10%);
    padding: 12px 10px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    gap: 6px;
}

.app__ruleset_document_actions_container {
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    padding: 12px 60px 20px;
    position: absolute;
    top: -10px;
    left: -10px;
    height: calc(100% + 20px);
    width: calc(100% + 20px);
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    flex-direction: column;
    gap: 10px;
    animation: fade 0.3s ease;
}

.app__ruleset_title_actions_container {
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    padding: 3px 10px 3px 0px;
    position: absolute;
    top: -10px;
    left: -10px;
    height: calc(100% + 8px);
    width: calc(100% + 20px);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* align-items: center; */
    gap: 20px;
    animation: fade 0.3s ease;
}

.app__ruleset_rule_actions_container {
    z-index: 1000;
    background-color: rgba(29, 46, 60, 0.8);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    padding: 12px 60px 20px;
    position: absolute;
    top: -10px;
    left: -10px;
    height: calc(100% + 20px);
    width: calc(100% + 20px);
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    gap: 20px;
    animation: fade 0.3s ease;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.app__ruleset_document_action {
    background-color: var(--color-green-main-1);
    padding: 2px 8px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    color: #FFF;
    font-size: 12px;
    border-radius: 10px;
    cursor: pointer;
}


.app__ruleset_document_deletion_container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 14px;
}

.app__ruleset_document_deletion_container>div {
    width: 50%;
}


.test {
    /* background-color: aqua; */
    min-height: 2px;
}


/* TITLE */
.app__ruleset_title {
    position: relative;
}

.app__ruleset_title:hover>div.app__ruleset_title_header>div.app__ruleset_title_button_container {
    opacity: 1;
}

.app__ruleset_title:hover>div.app__ruleset_title_header>div.app__ruleset_title_button_container2>img {
    /* transform: translateX(0px); */
    opacity: 0.9;
}

.app__ruleset_title_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 10px; */
    /* gap: 6px; */
}

.app__ruleset_title_label {
    font-size: 15px;
    font-weight: 600;
}

.app__ruleset_title_label_input {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid transparent;
    background-color: transparent;
    outline: 0;
    font-family: inherit;
    font-size: 15px;
    /* line-height: 21px; */
    font-weight: 600;
    transition: border-color 0.2s;
    margin-right: 6px;
}

.app__ruleset_title_label_input:hover {
    border-bottom: 1px solid lightgrey;
}

.app__ruleset_title_label_numerotation{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 1px;
    margin-right: 2px;
}

.app__ruleset_title_button_container {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s ease;
}


.app__ruleset_title_button_container>img {
    height: 12px;
}

.app__ruleset_title_button_container2 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s ease;
    margin-right: 6px;
}

.app__ruleset_title_button_container2>img {
    height: 16px;
    /* transform: translateX(20px); */
    opacity: 0;
    transition: all 0.2s ease;
}

/* RULE */
.app__ruleset_rule {
    position: relative;
}

.app__ruleset_rule_container:hover>div>div.app__ruleset_rule>div.app__ruleset_title_header>div>div.app__ruleset_title_button_container {
    opacity: 1;
}

.app__ruleset_rule_label {
    font-size: 15px;
    font-weight: 800;
    margin-top: 4px;
}

.app__ruleset_rule_container {
    background: radial-gradient(circle, rgba(29, 46, 60, 1) 0%, rgba(25, 40, 64, 1) 110%);
    /* background-color: var(--color-blue-secondary-2); */
    color: #FFF;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 8px;
    /* overflow: hidden; */
    max-height: 1000px;
    transition: all 0.3s ease;
}

.app__ruleset_rule_container_invalid {
    background: #8f4645;
}

.app__ruleset_rule_container_hidden {
    max-height: 0px;
    padding: 0px 10px;
    margin-bottom: 0px;
    overflow: hidden;
}

.app__ruleset_rule_container_hidden>div.drag-handle_item {
    pointer-events: none;
    opacity: 0 !important;
}

.app__ruleset_rule_variable_container {}

.app__ruleset_rule_variable_box {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #FFF;
    font-size: 12px;
    line-height: 13px;
    height: 15px;
}

.app__ruleset_rule_variable_input_number {
    color: #FFF;
    background-color: var(--color-blue-secondary-2);
    border-bottom: 1px solid rgba(100, 100, 100, 0);
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    /* height: 13px; */
    font-size: 12px;
    font-family: var(--font-family-Manrope);
    transition: all 0.2s ease;
}

.app__ruleset_rule_variable_input_number:hover {
    border-bottom: 1px solid rgba(100, 100, 100, 0.6);
}

.app__ruleset_rule_separator {
    position: relative;
    width: calc(100% + 20px);
    left: -10px;
    margin: 6px 0px;
    border-top: 1px solid rgba(255, 255, 255, 0);
}

.app__ruleset_rule_condition_title_container {
    color: #FFF;
    font-size: 12px;
    line-height: 13px;
    font-weight: 500;
    margin-bottom: 4px;
}

.app__ruleset_rule_description_container {
    display: flex;
    gap: 10px;
}

.app__ruleset_rule_image {
    width: 140px;
    min-width: 140px;
}

.app__ruleset_rule_image>img {
    width: 100%;
}

.app__ruleset_rule_description_subcontainer {
    display: flex;
    /* align-items: center; */
}

.app__ruleset_rule_description {
    position: relative;
    color: lightgrey;
    font-size: 12px;
    line-height: 20px;
    cursor: default;
    white-space: pre-wrap;
}

.app__ruleset_rule_condition_container {
    color: lightgrey;
    font-size: 12px;
    line-height: 13px;
}

.app__ruleset_rule_condition_box_full {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    /* border: 1px solid rgba(255, 255, 255, 0.4); */
    background-color: rgba(200, 200, 200, 0.1);
    margin-top: 8px;
    padding: 5px 6px 6px;
}

.app__ruleset_rule_condition_box_full_invalid {
    /* background-color: #6f3736; */
}

.app__ruleset_rule_condition_box_full_header {
    display: flex;
    gap: 6px;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    margin-bottom: 2px;
    margin-top: 2px;
}

.app__ruleset_rule_condition_box_full_header>img {
    height: 8px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.app__ruleset_rule_condition_box_full:hover>div.app__ruleset_rule_condition_box_full_header>img {
    opacity: 1;
}

.app__ruleset_rule_condition_box_full_inactive {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    border: 1px dashed rgba(200, 200, 200, 0.2);
    margin-top: 8px;
    padding: 4px 6px 5px;
    transition: all 0.3s ease;
}

.app__ruleset_rule_condition_box_full_inactive>div {
    opacity: 0.7;
    transition: all 0.3s ease;
}

.app__ruleset_rule_condition_box_full_inactive:hover {
    border: 1px dashed rgba(200, 200, 200, 0.4);
    /* border: 1px dashed var(--color-green-main-1); */
}

.app__ruleset_rule_condition_box_full_inactive:hover>div {
    opacity: 1;

}

.app__ruleset_rule_condition_box {
    display: flex;
    gap: 6px;
    justify-content: space-between;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    margin-top: 4px;
    padding: 4px 6px 5px;
}

.app__ruleset_rule_condition_box>div {
    display: flex;
    gap: 6px;
}

.app__ruleset_rule_condition_box>img {
    height: 10px;
    cursor: pointer;
    margin-top: 5px;
}

.app__ruleset_rule_condition_link {
    /* color: #a3a8ab;
    font-size: 11.6px; */
    text-decoration: underline;
    cursor: pointer;
}

.app__ruleset_rule_condition_link:hover {
    color: var(--color-green-main-1);
}

.app__ruleset_rule_condition_result_green {
    position: absolute;
    top: 3px;
    right: 20px;
    color: #00C49A;
    font-weight: 600;
    font-size: 11px;
    padding: 1px 6px 3px 6px;
    border-radius: 10px;
    /* line-height: 14px; */
    background-color: #00926b70;
}

/* .app__ruleset_rule_condition_result_red {
    position: absolute;
    top: 3px;
    right: 20px;
    color: #ca6a68;
    font-weight: 600;
    font-size: 11px;
    padding: 1px 6px 3px 6px;
    border-radius: 10px;
    background-color: #dd1d1a4b;
} */

.app__ruleset_rule_condition_result_red {
    position: absolute;
    display: flex;
    align-items: center;
    top: 7px;
    right: 24px;
    color: #c58c8b;
    font-weight: 600;
    font-size: 10px;
    /* padding: 1px 8px 3px; */
    /* border-top-left-radius: 6px; */
    /* border-top-right-radius: 6px; */
    /* line-height: 14px; */
    /* background-color: #6f3736; */
    font-style: italic;
}

.app__ruleset_rule_condition_result_red>img {
    height: 11px;
    margin-right: 2px;
}

.app__ruleset_rule_result_placeholder {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 19.4px;
    max-height: 19px;
    margin: 0px 2px;
    padding: 0px 10px;
    border-radius: 10px;
    /* border: 1px solid rgba(200, 200, 200, 0.2); */
    color: #FFF;
    /* background-color: var(--color-blue-secondary-2); */
    background-color: rgba(200, 200, 200, 0.2);
    font-weight: 600;
}

.app__ruleset_rule_variable_placeholder {
    position: relative;
    display: inline-flex; /*TEST*/
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 19.4px;
    max-height: 19px;
    margin: 0px 2px;
    padding: 1px 10px 0px;
    border-radius: 10px;
    border: 1px solid var(--color-green-main-1);
    color: #FFF;
    /* background-color: var(--color-blue-secondary-2); */
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s ease;
}

.app__ruleset_rule_variable_placeholder>span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.app__ruleset_rule_variable_placeholder:hover {
    background-color: var(--color-green-main-1);
    color: var(--color-blue-secondary-2);
}

.app__ruleset_rule_variable_placeholder_drawing {
    background-color: var(--color-green-main-1);
    color: var(--color-blue-secondary-2);
}

.app__ruleset_rule_variable_placeholder_ico {
    position: relative;
    top: 1.5px;
    height: 12px;
    margin-right: 5px;
}

/* VARIABLE EDITION */
.app__ruleset_rule_description_hide {
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background-color: transparent;
    /* background-color: rgba(29, 46, 60, 0.5); */
    backdrop-filter: blur(1px);
    z-index: 999;
}


.app__ruleset_rule_variable_input_container {
    position: absolute;
    z-index: 1000;
    min-height: 19px;
    background-color: var(--color-green-main-1);
    /* background-color: orange; */
    border-radius: 10px;
    /* border: 1px solid var(--color-green-main-1); */
    color: var(--color-blue-main-0);
}

.app__ruleset_rule_variable_input_container_box {
    position: relative;
    min-height: 19px;
    background-color: var(--color-green-main-1);
    border-radius: 10px;
    padding: 0px 6px;
    font-family: var(--font-family-Manrope);
    color: var(--color-blue-secondary-2);
}

.app__ruleset_rule_variable_input_element_range {
    width: 100px;
    height: 6px;
    border-radius: 6px;
    background: #1d2e3c80;
    outline: none;
    -webkit-appearance: none;
}

.app__ruleset_rule_variable_input_element_range::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 8px;
    background: var(--color-blue-secondary-2);
    cursor: grab;
}

.app__ruleset_rule_variable_input_element_range::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 8px;
    background: var(--color-blue-secondary-2);
    cursor: grab;
}

.app__ruleset_rule_variable_input_element_number {
    max-height: 19px;
    min-width: 20.4px;
    line-height: 15px;
    background-color: transparent;
    font-size: 12px;
    line-height: 19px;
    font-family: var(--font-family-Manrope);
    color: var(--color-blue-secondary-2);
    border: none;
    border-radius: 10px;
    outline: none;
    text-align: center;
    -moz-appearance: textfield;
}

.app__ruleset_rule_variable_input_element_number::-webkit-outer-spin-button,
.app__ruleset_rule_variable_input_element_number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.app__ruleset_rule_variable_input_element_dropdown {
    background-color: transparent;
    /* max-height: 15px; */
    border: none;
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family-Manrope);
    outline: none;
    padding-left: 6px;
}

.app__ruleset_rule_variable_input_category_selected {
    cursor: pointer;
    background-color: var(--color-blue-main-0);
    padding: 1px 6px 2px;
    border-radius: 15px;
    color: lightgrey;
    font-size: 11px;
    font-weight: 500;
}

.app__ruleset_rule_variable_input_category_unselected {
    cursor: pointer;
    font-size: 11px;
    font-weight: 500;
}

.app__ruleset_rule_variable_input_element_checkbox {
    appearance: auto;
    /* accent-color: var(--color-green-main-1); */
    accent-color: #1d2e3c80;
    height: 11px;
    outline: none;
}