.toaster__container_main {
    background-color: transparent;
    width: 320px;
    min-height: 0px;
    position: fixed;
    top: 88px;
    right: 70px;
    z-index: 10001;
    display: flex;
    /* gap: 10px; */
    flex-direction: column;
    justify-content: flex-start;
}

.toaster__container {
    background-color: rgba(6, 33, 52, 0.9);
    border-radius: 6px;
    color: #FFF;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
    padding: 0px 12px;
    opacity: 1;
    max-height: 100px;
    transition: opacity 0.3s ease 1.4s, max-height 0.2s ease 1.8s, margin-bottom 0.2s ease 1.8s;
}

/* .toaster__container:last-child {
    margin-bottom: 0px;
} */

.toaster__container_done {
    opacity: 0;
    max-height: 0px;
    margin-bottom: 0px;
}

.toaster__container_hidden {
    display: none;
}

.toaster__container_error {
    background-color: #883137;
}

.toaster__title {
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    margin: 8px 0px 5px 0px;
}

.toaster__text {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    margin-bottom: 6px;
    white-space: pre-line;
}

.toaster__bottom {
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;
}

.toaster__progress_container {
    background-color: rgba(128, 128, 128, 0.3);
    width: calc(100% - 20px);
    height: 3px;
    border-radius: 3px;
    position: relative;
}

.toaster__progress_bar_ph {
    background-color: #FFF;
    width: 2%;
    height: 3px;
    border-radius: 3px;
    position: absolute;
    top: 0px;
    -webkit-animation: progress 5s forwards;
    animation: progress 5s forwards;
}

.toaster__progress_bar {
    background-color: #FFF;
    width: 1%;
    height: 3px;
    border-radius: 3px;
    transition: all 0.3s ease;
}

.toaster__progress_bar_full {
    width: 100%;
}


.toaster__progress_icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 20px;
    height: 10px;
}

.toaster__progress_icon>img {
    height: 10px;
    opacity: 0;
    -webkit-animation: fade 0.2s forwards 0.3s;
    animation: fade 0.2s forwards 0.3s;
}

.toaster__progress_loading {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border-top: 2px solid rgba(128, 128, 128, 0.3);
    border-right: 2px solid #FFF;
    border-bottom: 2px solid rgba(128, 128, 128, 0.3);
    border-left: 2px solid rgba(128, 128, 128, 0.3);
    animation: rotating 1s linear infinite;
}


.slide-left {
    -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}





/* ANIMATIONS */
@-webkit-keyframes slide-left {
    0% {
        /* opacity: 0; */
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }

    100% {
        /* opacity: 1; */
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@keyframes slide-left {
    0% {
        /* opacity: 0; */
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }

    100% {
        /* opacity: 1; */
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@-webkit-keyframes progress {
    0% {
        width: 2%;
    }

    /* 10% {
        width: 2%;
    } */

    100% {
        width: 90%;
    }
}

@keyframes progress {
    0% {
        width: 2%;
    }

    /* 10% {
        width: 2%;
    } */

    100% {
        width: 90%;
    }
}

@-webkit-keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


/* RESPONSIVE */

@media screen and (max-width: 550px) {
    .toaster__container_main {
        width: calc(100% - 40px);
        bottom: 80px;
        top: auto;
        left: 20px;
        justify-content: flex-end;
    }
}