/* CONTAINER */
.app__location_container {
    /* height: 130px; */
    position: relative;
    overflow: hidden;
    min-height: 105px;
}

.app__location_container:hover>img.app__location_background {
    opacity: 0.5;
}

.app__location_container:hover>div.app__location_body>div.app__location_button_container {
    opacity: 1;
}

.app__location_container>div {
    z-index: 11;
    position: inherit;
}

/* BACKGROUND */
.app__location_background_filter_1 {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10;
    height: 100%;
}

.app__location_background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 8;
    opacity: 0.8;
    transition: all 0.2s ease;
}

/* BODY */
.app__location_body {
    height: calc(100% - 38px);
    width: 100%;
    display: flex;
    justify-content: space-between;
}

/* LABELS */
.app__location_label_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.app__location_label_1 {
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 2px;
}

.app__location_label_2 {
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
}

.app__location_label_3 {
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
}

/* BUTTON */
.app__location_button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.2s ease;
}

.app__location_button2 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    border: 2px solid var(--color-blue-main-0);
    border-radius: 30px;
    height: 30px;
    font-weight: 600;
}

.app__location_button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;
    background-color: var(--color-green-main-1);
    color: #FFF;
    border-radius: 30px;
    height: 30px;
    font-weight: 600;
}