/* BACKGROUND */
.mobile__container {
    background: var(--gradient-blue-background);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* CENTER */
.mobile__center_container {
    width: 200px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.4s ease;
}

.mobile__center_container_hidden {
    opacity: 0;
}

/* LOGO */
.mobile__center_container img {
    width: 80%;
}

/* TITLE */
.mobile__title_container {
    color: #fff;
    font-size: 30px;
    line-height: 30px;
    height: 0px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    animation: logo_expand 0.6s ease forwards 2.8s;
}

@keyframes logo_expand {
    to {
        height: 40px;
    }
}

.mobile__title_container img {
    width: 90%;
    opacity: 0;
    animation: logo_show 0.8s ease forwards 3s;
}

@keyframes logo_show {
    to {
        opacity: 1;
    }
}

/* VERSION */
.mobile__version {
    color: var(--color-green-main-1);
    font-size: 10px;
    font-weight: 700;
    position: fixed;
    bottom: 40px;
}