.app__map {
    /* position: fixed; */
    /* right: 0; */
    /* width: calc(100% - var(--width-navbar) - var(--width-section)); */
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 10000;
    background-color: var(--color-grey-main-1);
    /* padding: 0px 20px 10px 0px; */
    opacity: 1;
    transition: opacity ease 0.4s;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
}

.app__map_hidden {
    opacity: 0;
}

.app__map_header_small {
    /* top: var(--height-header);
    height: calc(100% - 10px - var(--height-header)); */
}

.app__map_header_large {
    /* top: calc(var(--height-header) + 40px);
    height: calc(100% - 10px - var(--height-header) - 40px); */
}

.app__map_container {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    position: relative;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
}

/* BUTTONS */
.app__map_button_container {
    position: absolute;
    width: 29px;
    z-index: 3;
    /* top: 146px; */
    top: 107px;
    /* right: 24px; */
    right: 30px;
    /*_UI*/
}

.app__map_button_container2 {
    position: absolute;
    width: 29px;
    z-index: 3;
    top: 224px;
    /* right: 24px; */
    right: 30px;
    /*_UI*/
}

.app__map_button_container3 {
    position: absolute;
    width: 29px;
    z-index: 3;
    top: 263px;
    /* right: 24px; */
    right: 30px;
    /*_UI*/
}

.app__map_button_img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__map_button_img img {
    width: 60%;
}

.app__map_button_img_spinning {
    -webkit-animation:buttonimg_spin 2s linear infinite;
    -moz-animation:buttonimg_spin 2s linear infinite;
    animation:buttonimg_spin 2s linear infinite;
}

@-moz-keyframes buttonimg_spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes buttonimg_spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes buttonimg_spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.app__map_button_single {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 800;
    width: 29px;
    height: 29px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
    margin-bottom: 10px;
    color: #333333;
}

.app__map_button_single_large {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 800;
    height: 29px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
    margin-right: 10px;
    padding: 0 10px;
    color: #333333;
}

.app__map_button_single_inside {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 800;
    height: 29px;
    background-color: #fff;
    color: #333333;
    padding: 0 10px;
    border-right: 1px solid #ddd;
    transition: all 0.2s ease-in-out;
}

.app__map_button_active {
    /* background-color: #F2F2F2; */
    background-color: #062134 !important;
    color: #FFF !important;
}

.app__map_button_single_inside:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.app__map_button_single_inside:last-child {
    border: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.app__map_button_single_large:hover,
.app__map_button_single_inside:hover,
.app__map_button_single:hover {
    background-color: #F2F2F2;
}

/* LOADER */
.app__map_loader_container_loading {
    visibility: visible !important;
}

.app__map_loader_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f56e;
    visibility: hidden;
}

.app__map_loader {
    /* background-color: red; */
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-top: 3px solid var(--color-grey-main-1);
    /* border-right: 3px solid var(--color-blue-main-0); */
    border-right: 3px solid var(--color-green-main-1);
    border-bottom: 3px solid var(--color-grey-main-1);
    border-left: 3px solid var(--color-grey-main-1);
    animation: rotating 2s 0.5s linear infinite;
}

/* LAYERS */
.app__map_layer_container {
    position: absolute;
    z-index: 3;
    top: 0px;
    right: 39px;
    max-width: 325px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
    padding: 0 10px;
    width: max-content;
    overflow-x: auto;
    scroll-margin-right: 2px
}

.app__map_layer_container::-webkit-scrollbar {
    width: 7px;
    height: 0px;
    background-color: transparent;
}

.app__map_layer_container::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #FFF;
    box-shadow: inset 0 0 10px 10px #FFF;
    border: solid 2px #FFF;
}

.app__map_layer_container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    /* background-color: var(--color-blue-main-1); */
    background-color: lightgrey;
    box-shadow: inset 0 0 10px 10px lightgrey;
    border: solid 2px #FFF;
}

.app__map_layer_title_main {
    background-color: #FFF;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    color: #333333;
    position: sticky;
    top: 0;
    z-index: 101;
    padding: 10px 0;
}

.app__map_layer_title {
    background-color: #FFF;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: grey;
    position: sticky;
    top: 39px;
    z-index: 100;
}

.app__map_layer_spacer {
    height: 10px;
}

.app__map_layer {
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
}

.app__map_sublayer {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-left: 15px;
}

.app__map_layer_checkbox {
    /* margin-right: 5px; */
}

.app__map_layer_checkbox_noaccess {
    opacity: 0.3;
    pointer-events: none;
}

.app__map_layer_checkbox_element {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
}

.app__map_layer_checkbox_label {
    cursor: pointer;
    text-indent: -9999px;
    width: 30px;
    height: 17px;
    background: grey;
    display: block;
    border-radius: 17px;
    position: relative;
}

.app__map_layer_checkbox_label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 13px;
    height: 13px;
    background: #fff;
    border-radius: 50%;
    transition: 0.3s;
}

.app__map_layer_checkbox_element:checked+.app__map_layer_checkbox_label {
    background: var(--color-green-main-1);
}

.app__map_layer_checkbox_element:checked+.app__map_layer_checkbox_label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.app__map_layer_label {
    font-size: 13px;
    line-height: 13px;
    margin-left: 5px;
    margin-top: 2px;
}

/* SUBLAYER */
.app__map_sublayer_container {
    height: auto;
    max-height: 100px;
    overflow: hidden;
    transition: max-height 0.5s ease;
}

.app__map_sublayer_container_closed {
    max-height: 0px;
}

/* DRAW */
.app__map_button_container_trans {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    top: 0px;
    right: 39px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
    height: 29px;
}


/* POPUP */
.mapboxgl-popup-content {
    min-width: 180px;
    box-shadow: 0 1px 10px rgb(0 0 0 / 10%) !important;
}

.mapboxgl-popup-close-button {
    width: 15px;
    outline: none;
}

.scroll_custom::-webkit-scrollbar {
    width: 4px;
    height: 0px;
    background-color: var(--color-grey-main-1);
}

.scroll_custom::-webkit-scrollbar-thumb {
    border-radius: 2px;
    /* background-color: var(--color-blue-main-1); */
    background-color: grey;
}

.scroll_custom::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: #d1d1d1;
}


/* DRAW */
.app__map_drawing_info_description_mastercontainer {
    /* background-color: aqua; */
    height: 1px;
    width: calc(100vw - var(--width-section) - var(--width-navbar) - 55px);
    position: fixed;
    bottom: 50px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.app__map_drawing_info_container {
    background: #062134da;
    backdrop-filter: blur(6px);
    /* right: calc(100vw - var(--width-section) - var(--width-navbar) - 400px - 20px - 15px - 200px); */
    /* 400 = width | 20 = margin right | 15 = margin left | 200 = width/2 */
    /* free space = calc(100vw - var(--width-section) - var(--width-navbar) - 55px); */
    width: calc(100% - 40px);
    max-width: 380px;
    border-radius: 0.7rem;
    box-shadow: 0 0 14px rgb(100 100 100 / 20%);
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__map_drawing_info_title {
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
}

.app__map_drawing_info_description_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.app__map_drawing_info_description {
    color: lightgrey;
    font-size: 12px;
    line-height: 14px;
    /* text-align: center; */
}

.app__map_drawing_info_buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.app__map_drawing_button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px 1px;
    border: 1px solid var(--color-green-main-1);
    background-color: transparent;
    color: #FFF;
    border-radius: 24px;
    height: 24px;
    font-size: 12px;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
}

.app__map_drawing_button:hover {
    background-color: var(--color-green-main-1);
}

.app__map_drawing_button_disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px 1px;
    border: 1px solid grey;
    background-color: transparent;
    color: #FFF;
    border-radius: 24px;
    height: 24px;
    font-size: 12px;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
}

.app__map_drawing_button_disabled:hover {
    background-color: grey;
}

.app__map_drawing_info_buttons_main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid rgba(211, 211, 211, 0.5);
}


.app__map_drawing_button_main {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    background-color: var(--color-green-main-1);
    color: #FFF;
    border-radius: 30px;
    height: 30px;
    font-size: 13px;
    font-weight: 600;
}

.app__map_drawing_button_main_red {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    color: #FFF;
    background-color: #DD1C1A;
    border-radius: 30px;
    height: 30px;
    font-size: 13px;
    font-weight: 600;
}

/* RESPONSIVE */

@media screen and (max-width: 1050px) {
    /* .app__map {
        width: calc(100% - var(--width-navbar));
        height: calc(50% - 110px);
        padding: 0px 20px 0px 20px;
    } */

    .app__map_button_container {
        right: 30px;
    }

    .app__map_button_container2 {
        right: 30px;
    }
}

@media screen and (max-width: 550px) {
    .app__map_layer_container {
        max-width: calc(100vw - 140px);
        left: 39px;
    }

    .app__map_button_container2 {
        top: 10px !important;
        left: 30px;
    }

    .app__map_button_container2_step0 {
        top: 55px !important;
    }

    .app__map_button_container3 {
        top: 49px !important;
        left: 30px;
    }

    .app__map_button_container_trans {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
        top: 0px;
        left: 39px !important;
        right: auto;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
        height: 29px;
    }
}


/* MAPBOX CUSTOMISATION */
.mapboxgl-canvas {
    outline: none;
}