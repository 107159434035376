.app__filter_container {
    /* background-color: aqua; */
    /* padding: 10px; */
    display: flex;
    align-items: flex-end;
    width: auto;
    /* height: 70px; */
}

/* BODY */
.app__filter_body_container {
    overflow: hidden;
}

.app__filter_body {
    display: flex;
    align-items: flex-end;
    transition: all 0.4s ease;
    transform: translateX(-900px);
    opacity: 0;
}

.app__filter_body_visible {
    transform: translateX(0px);
    opacity: 1;
}

/* INPUT */

.app__filter_input_container {
    width: 400px;
}

.app__filter_reset {
    height: 14px;
    position: absolute;
    top: Opx;
    right: 0px;
    cursor: pointer;
}

.common__input_field_dark2 {
    font-family: inherit;
    width: 100%;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 2px solid #9b9b9b;
    border-left: 0px;
    border-radius: 0;
    outline: 0;
    font-size: 16px;
    color: var(--color-blue-main-0);
    padding: 7px 0 6px;
    background: transparent;
    transition: border-color 0.2s ease-in-out;
}

.common__input_field_dark2::placeholder {
    color: transparent;
}

.common__input_field_dark2:placeholder-shown~.form__label {
    font-size: 16px;
    cursor: text;
    top: 20px;
}

.common__input_field_dark2:focus {
    padding-bottom: 6px;
    /* font-weight: 700; */
    border-top: 0px;
    border-right: 0px;
    border-bottom: 2px solid var(--color-blue-main-0);
    border-left: 0px;
}

.common__input_field_dark2:focus~.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: var(--color-blue-main-0);
    font-weight: 700;
}

/* MY CAPA */

.app__filter_creator_container {
    display: flex;
    position: relative;
    background-color: #9b9b9b44;
    height: 34px;
    margin-left: 20px;
    border-radius: 17px;
}

.app__filter_creator_1 {
    color: var(--color-blue-main-0);
    z-index: 100;
    width: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.4s ease;
}

.app__filter_creator_2 {
    color: var(--color-blue-main-0);
    z-index: 100;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.4s ease;
}

.app__filter_creator_selected {
    color: #fff;
}

.app__filter_indicator {
    z-index: 99;
    background-color: var(--color-blue-main-0);
    ;
    position: absolute;
    top: 0px;
    height: 34px;
    border-radius: 17px;
    transition: all 0.3s ease;
}

.app__filter_indicator_1 {
    left: 0px;
    width: 190px;
}

.app__filter_indicator_2 {
    left: 190px;
    width: 220px;
}

/* ICO */

.app__filter_ico_container {
    width: 28px;
    height: 34px;
    margin-right: 16px;
    transform: translateY(10px);
    position: relative;
    cursor: pointer;
}

.app__filter_ico_container img {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: all 0.3s ease;
}

.app__filter_ico_circle_1_moved {
    transform: translateX(8px);
}

.app__filter_ico_circle_2_moved {
    transform: translateX(-10px);
}

.app__filter_ico_circle_3_moved {
    transform: translateX(13px);
}

/* RESPONSIVE */

@media screen and (max-width: 1050px) {
    .app__filter_container {
        flex-direction: column;
        align-items: flex-start;
    }

    .app__filter_body {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        height: 0px;
    }

    .app__filter_body_visible {
        height: 98px;
    }

    .app__filter_input_container {
        width: 410px;
    }

    .app__filter_creator_container {
        margin-left: 0;
        margin-top: 10px;
    }
}

@media screen and (max-width: 550px) {
    .app__filter_container {
        width: 260px;
    }

    .app__filter_body_container {
        width: 100%;
    }

    .app__filter_input_container {
        /* width: calc(100vw - 40px); */
        width: 100%;
    }

    .app__filter_creator_container {
        width: 100%;
    }

    .app__filter_creator_1 {
        /* width: calc((100vw - 40px) / 2 - 10px); */
        width: 45%;
        font-size: 11.5px;
        line-height: 12px;
    }

    .app__filter_creator_2 {
        /* width: calc((100vw - 40px) / 2 + 10px); */
        width: 55%;
        font-size: 11.5px;
        line-height: 12px;
    }

    .app__filter_indicator_1 {
        left: 0px;
        /* width: calc((100vw - 40px) / 2 - 10px); */
        width: 45%;
    }

    .app__filter_indicator_2 {
        /* left: calc((100vw - 40px) / 2 - 10px); */
        left: 45%;
        /* width: calc((100vw - 40px) / 2 + 10px); */
        width: 55%;
    }
}