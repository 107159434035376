/* CONTAINER */
.app__bound_container {
    width: 100%;
}

.app_bound_col0 {
    width: 25%;
}

.app_bound_col1 {
    width: 20%;
}

.app_bound_col2 {
    flex: 1;
}

.app_bound_col00 {
    width: 65%;
}

.app_bound_col10 {
    width: 35%;
}

/* TITLE */
.app__bound_title_container {
    display: flex;
    height: 15px;
}

.app__bound_title {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: grey;
}

/* BODY */
.app__bound_body_container {
    display: flex;
    align-items: center;
    height: 40px;
}

.app__bound_body_container_small {
    display: flex;
    align-items: center;
    height: 30px;
}

.app__bound_body_value_main {
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
}

.app__bound_body_value_submain {
    font-size: 13px;
    line-height: 14px;
    font-weight: 600;
}

.app__bound_body_value {
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
}

.app__bound_body_value_second {
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: grey;
}

/* TYPE */
.app__bound_type_container {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    gap: 4px;
}

.app__type_color {
    width: 12px;
    height: 12px;
    border-radius: 6px;
}

.color_sep {
    background-color: #004861;
}

.color_pub {
    background-color: #007384;
}

.color_nc {
    background-color: #00a097;
}

/* LINE */
.app__bound_line_container {
    background-color: rgb(211, 211, 211);
    width: 100%;
    height: 1px;
}

/* MODIFY BUTTON */

.app__bound_type_modify_button_container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0;
    transition: all 0.4s ease;
}

.app__bound_type_modify_button_container:hover {
    opacity: 1;
}

/* MODIFY */
.app__bound_body_value_container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.app__bound_type_modify_container {
    background-color: var(--color-blue-secondary-2);
    border-radius: 0.5rem;
    box-shadow: 0 0 14px rgb(100 100 100 / 10%);
    padding: 8px;
    position: absolute;
    top: 4px;
    left: -4px;
    width: calc(100% + 4px);
    z-index: 100;
    display: flex;
    flex-direction: column;
}

.app__bound_type_modify_title_big {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
    color: #FFF;
}

.app__bound_type_modify_title {
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: grey;
    margin: 0 0 6px 0;
}

.app__bound_type_modify_select {
    color: #FFF;
    background-color: var(--color-blue-secondary-2);
    /* border-bottom: 1px solid rgba(100, 100, 100, 0.2); */
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    min-height: 14px;
    font-size: 12px;
    font-family: var(--font-family-Manrope);
    transition: all 0.2s ease;
}

.app__bound_type_modify_buttons_container {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.app__bound_type_modify_checkbox_container {
    max-height: 75px;
    overflow-y: auto;
}

.app__bound_type_modify_checkbox_line {
    display: flex;
    gap: 4px;
}

.app__bound_type_modify_checkbox {
    appearance: auto;
    accent-color: var(--color-green-main-1);
}

.app__bound_type_modify_checkbox_label {
    color: #FFF;
    font-size: 12px;
}

.app__bound_type_modify_input_text {
    margin: 0px 4px;
    color: #FFF;
    background-color: var(--color-blue-secondary-2);
    border-bottom: 1px solid rgba(100, 100, 100, 0.2);
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    min-height: 14px;
    font-size: 12px;
    font-family: var(--font-family-Manrope);
    transition: all 0.2s ease;
}

.app__bound_type_modify_input_text:hover {
    border-bottom: 1px solid rgba(100, 100, 100, 0.6);

}