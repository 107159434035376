/* BODY */
.auth__resetform {
    background-color: var(--color-blue-secondary-2);
    width: 400px;
    border-radius: 10px;
    padding: 20px;
    /* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    z-index: 100;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s 1s, opacity 0.5s 0.5s linear;
}

.auth__resetform_hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 0.5s 0.5s linear;
}

/* TITLE */
.auth__resetform h5 {
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 0.6px;
    color: #fff;
    text-align: center;
    margin: 20px 0;
}

/* FORM */
.auth__resetform form {
    display: flex;
    flex-direction: column;
}

.auth__resetform_box_password {
    margin-top: 16px;
}

/* BUTTON */
.auth__resetform_box_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0 0 0;
}

.auth__resetform_button {
    outline: none;
    height: 40px;
    text-align: center;
    width: 100%;
    border-radius: 40px;
    background: #1ecd97;
    border: 2px solid #1ecd97;
    color: #fff;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.25s ease;
}

.loading {
    background: transparent;
    width: 40px;
    border-color: #9b9b9b;
    border-width: 3px;
    font-size: 0;
    border-left-color: #1ecd97;
    animation: rotating 2s 0.25s linear infinite;
}


.loadedSuccess {
    /* width: 40px; */
    font-size: 13px;
    color: white;
    background: #1ecd97;
    border-color: #1ecd97;
}

.loadedFail {
    /* width: 40px; */
    font-size: 13px;
    color: white;
    background: #FF5964;
    border-color: #FF5964;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.auth__resetform_message {
    color: #FF5964;
    font-size: 12px;
    font-weight: 800;
    text-align: center;
    margin: 10px 0 0 0;
}

/* LINK */
.auth__resetform_link {
    color: #9b9b9b;
    cursor: pointer;
    font-size: 12px;
    text-align: center;
    margin: 20px 0 0 0;
}

.auth__resetform_link:hover {
    color: #1ecd97;
}

/* FINAL */
.auth__resetform_final {
    /* background-color: #FF5964; */
    position: absolute;
    z-index: 99;
    /*left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    opacity: 0;
}

.auth__resetform_final h1 {
    color: #1ecd97;
}

.auth__resetform_final h4 {
    text-align: center;
    margin-top: 0;
}

.auth__resetform_final_visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.4s linear 0.5s;
}