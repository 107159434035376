.admin__capas_container {
    max-height: calc(100% - 33px - 19px);
    overflow-y: auto;
    opacity: 1;
    transition: all 0.2s ease;
    padding-right: 4px;
    position: relative;
}

.app__admin_capa_body2 {
    display: flex;
    margin-top: 10px;
    line-height: 10px;
}

.app__admin_capa_body_modal {
    background-color: var(--color-blue-secondary-2);
    border-radius: 4px;
    position: absolute;
    width: 100%;
    height: calc(100% - 7px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    color: lightgrey;
}

.app__admin_capa_body_modal_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.app__admin_capa_body {
    padding: 2px 0 8px;
    background-color: rgba(141, 141, 141, 0);
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.app__admin_capa_body:hover {
    background-color: rgba(141, 141, 141, 0.1);
}

/* .app__admin_capa_body:hover>div.app__admin_capa_body2>div.app__admin_capa_col2>a.app__admin_capa_button { */
.app__admin_capa_body:hover>div.app__admin_capa_body2>div.app__admin_capa_col2 {
    opacity: 1;
}

.app__admin_capa_col0 {
    width: 55%;
    color: lightgrey;
    font-size: 12px;
}

.app__admin_capa_col00 {
    width: 55%;
}

.app__admin_capa_col1 {
    width: 25%;
}

.app__admin_capa_col2 {
    width: 20%;
    /* text-align: right; */
    position: relative;
    display: flex;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.app__admin_capa_type_container {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 12px;
    margin-bottom: 6px;
    background-color: rgba(100, 100, 100, 0.2);
    border-radius: 20px;
    height: 26px;
    padding: 0px 12px;
    color: lightgrey;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.app__admin_capa_type {
    z-index: 100;
    cursor: pointer;
    transition: all 0.2s ease;
}

.app__admin_capa_type_selected {
    color: #FFF;
}

.app__admin_capa_type_indicator {
    z-index: 90;
    background-color: rgba(100, 100, 100, 0.3);
    position: absolute;
    top: 0px;
    left: 0px;
    height: 26px;
    width: 51px;
    border-radius: 20px;
    transition: all 0.2s ease;
}

.app__admin_capa_map_container {
    background-color: azure;
    width: 100%;
    height: calc(100% - 33px);
    overflow: hidden;
    border-radius: 10px;
}

.app__admin_capa_button {
    background-color: var(--color-green-main-1);
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    /* height: 40px; */
    /* width: 140px; */
    width: fit-content;
    min-height: 26px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding: 4px 10px;
    position: absolute;
    bottom: 8px;
    left: 0;
    z-index: 999;
}

.app__admin_capa_button>img {
    height: 16px;
}

.app__admin_capa_button_green {
    background-color: var(--color-green-main-1);
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    /* height: 40px; */
    /* width: 140px; */
    width: fit-content;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding: 4px 10px;
}

.app__admin_capa_button_hidden {
    background-color: grey !important;
    cursor: default !important;
    pointer-events: none !important;
}

.app__admin_capa_button_red {
    background-color: #DD1C1A;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    /* height: 40px; */
    /* width: 140px; */
    width: fit-content;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding: 4px 10px;
}

.app__admin_capa_button_green {
    background-color: var(--color-green-main-1);
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    /* height: 40px; */
    /* width: 140px; */
    width: fit-content;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding: 4px 10px;
}

.app__admin_capa_button_small {
    color: lightgrey;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    bottom: -6px;
    left: 20px;
    text-align: center;
    width: 73px;
}

.app__admin_capa_top_container {
    display: flex;
    gap: 8px;
}

.app__admin_capa_top_button {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 18px;
    height: 24px;
    /* width: 160px; */
    border-radius: 20px;
    /* border: 1px solid var(--color-green-main-1); */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
    opacity: 1;
    padding: 0px 10px;
}

.app__admin_capa_top_button:hover {
    background-color: var(--color-green-main-1);
    color: #fff;
}

.app__admin_capa_import_container {
    background-color: var(--color-blue-secondary-2);
    width: 100%;
    height: calc(100% - 33px);
    overflow: hidden;
    border-radius: 8px;
    padding: 10px;
    position: relative;
}

.app__admin_capa_import_txt {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    color: rgb(148, 147, 147);
    outline: none;
    resize: none;
}

.app__admin_capa_import_buttons_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
}

.app__admin_capa_import_button_green {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 18px;
    height: 24px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding: 0px 10px;
    background-color: var(--color-green-main-1);
    color: #fff;
}

.app__admin_capa_import_button_red {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 18px;
    height: 24px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding: 0px 10px;
    background-color: #DD1C1A;
    color: #fff;
}

.app__admin_capa_import_button_disabled {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 18px;
    height: 24px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: default;
    padding: 0px 10px;
    background-color: grey;
    color: #fff;
}

.app__admin_capa_select {
    position: inherit;
    /* font-size: 11px;
    line-height: 13px;
    font-weight: 400; */
    color: lightgrey;
    border: none;
    background-color: var(--color-blue-secondary-2);
    font-family: var(--font-family-Manrope);
    cursor: pointer;
    outline: none;
}