.app__risks_list_title_container {
    display: flex;
    align-items: flex-end;
}

.app__risks_list_item_container {
    display: flex;
    align-items: center;
}

.app__risks_title {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: grey;
}

.app__risks_list_col_0 {
    width: 44px;
}

.app__risks_list_col_1 {
    /* width: 30%; */
    flex: 1;
}

.app__risks_list_col_2 {
    /* width: 30%; */
    flex: 1;
}

.app__risks_list_col_3 {
    /* width: 20%; */
    flex: 0.6;
}

.app__risks_list_col_4 {
    /* width: 20%; */
    flex: 0.6;
}

.app__risks_list_col_5 {
    /* width: 20%; */
    flex: 0.3;
}

.app__risk_value_main {
    font-size: 16px;
    line-height: 17px;
    font-weight: 700;
}

.app__risk_value_second {
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
}

.app__risk_data_button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: 70px;
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
    border-radius: 8px;
    padding: 0 6px;
    cursor: pointer;
    background-color: var(--color-green-main-1);
    color: #fff;
    transition: all 0.2s ease;
}