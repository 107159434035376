.common__input_container {
    position: relative;
    padding: 10px 0 0;
    /* margin-top: 16px; */
    width: 100%;
}

.common__input_field {
    font-family: inherit;
    width: 100%;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 2px solid #9b9b9b;
    border-left: 0px;
    border-radius: 0;
    outline: 0;
    font-size: 16px;
    color: #fff;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}

.common__input_field_dark {
    font-family: inherit;
    width: 100%;
    border-top: 0px;
    border-right: 0px;
    /* border-bottom: 2px solid #9b9b9b; */
    border-bottom: 2px solid transparent;
    border-left: 0px;
    border-radius: 0;
    outline: 0;
    font-size: 16px;
    color: var(--color-blue-main-0);
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}

.common__input_field::placeholder {
    color: transparent;
}

.common__input_field_dark::placeholder {
    color: transparent;
}

.common__input_field:placeholder-shown~.form__label {
    font-size: 16px;
    cursor: text;
    top: 20px;
}

.common__input_field_dark:placeholder-shown~.form__label {
    font-size: 16px;
    cursor: text;
    top: 20px;
}

.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: #9b9b9b;
}

.common__input_field:focus {
    padding-bottom: 6px;
    /* font-weight: 700; */
    border-top: 0px;
    border-right: 0px;
    border-bottom-width: 3px;
    border-left: 0px;
    border-image: linear-gradient(to right, #11998e, #38ef7d);
    border-image-slice: 1;
}

.common__input_field_dark:focus {
    padding-bottom: 6px;
    /* font-weight: 700; */
    border-top: 0px;
    border-right: 0px;
    border-bottom: 2px solid var(--color-blue-main-0);
    border-left: 0px;
}

.common__input_field:focus~.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: #11998e;
    font-weight: 700;
}

.common__input_field_dark:focus~.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: var(--color-blue-main-0);
    font-weight: 700;
}

.auth_field_ico {
    height: 14px;
    position: absolute;
    top: 24px;
    right: 0px;
    cursor: pointer;
}