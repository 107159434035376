/* CONTAINER */

.app__rulecatalog_back {
    position: fixed;
    top: 0px;
    left: var(--width-navbar);
    width: calc(100vw - var(--width-navbar));
    height: 100vh;
    background-color: rgba(245, 245, 245, 0.6);
    /* backdrop-filter: blur(2px); */
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
}

.app__rulecatalog_container {
    background-color: #081220;
    /* background: var(--gradient-blue-background); */
    height: 100%;
    width: 100%;
    -webkit-box-shadow: 0 0 14px rgba(243, 10, 10, 0.4);
    box-shadow: 0 0 14px rgba(100, 100, 100, 0.6);
    border-radius: 1.3rem;
    /* padding: 12px 20px 20px; */
    padding: 12px 0px 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}

/* HEADER */

.app__rulecatalog_close_container {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
}

.app__rulecatalog_close_container>img {
    height: 14px;
}

.app__rulecatalog_header_container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 0 18px 0;
}

.app__rulecatalog_header_subcontainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.app__rulecatalog_header_label {
    color: #F1F1F1;
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
    letter-spacing: 0.04em;
    text-align: center;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

.app__rulecatalog_header_label_focus {
    opacity: 1;
}

.app__rulecatalog_header_indicator {
    height: 3px;
    width: 60px;
    background-color: #F1F1F1;
    border-radius: 3px;
    position: absolute;
    bottom: -6px;
    opacity: 0;
    transition: all 0.3s ease-out;
    animation: 0.4s fade 0.3s forwards;
}

.app__rulecatalog_header_indicator_hidden {
    height: 3px;
    width: 60px;
    background-color: #F1F1F1;
    border-radius: 3px;
    position: absolute;
    bottom: -6px;
    opacity: 0;
    transition: all 0.3s ease-out;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.app__rulecatalog_type_btn {
    cursor: pointer;
}

.app__rulecatalog_type_btn_selected {
    background-color: var(--color-green-main-1);
    padding: 0px 6px;
    border-radius: 15px;
    color: var(--color-blue-main-1);
}


/* BODY */
.app__rulecatalog_body_container {
    width: 100%;
    flex: 1;
    display: flex;
    gap: 10px;
    overflow: hidden;
    position: relative;
}

.app__rulecatalog_section {
    /* border: 2px solid red; */
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    transform: translateX(0px);
    transition: transform 0.4s ease-out;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    padding: 0px 20px;
}

.app__rulecatalog_section_moved_0 {
    transform: translateX(0px);
}

.app__rulecatalog_section_moved_1 {
    transform: translateX(calc(-100% - 10px));
}

.app__rulecatalog_section_moved_2 {
    transform: translateX(calc(-200% - 20px));
}

.app__rulecatalog_subsection {
    overflow-y: auto;
}

/* FILTER */
.app__rulecatalog_filter_container {
    max-width: 400px;
    width: 100%;
}

.app__rulecatalog_filter {
    font-family: inherit;
    width: 100%;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    border-left: 0px;
    border-radius: 0;
    outline: none;
    font-size: 14px;
    color: #FFF;
    padding: 6px 0px 4px;
    background: transparent;
    transition: border-color 0.2s;
}

.app__rulecatalog_filter:hover {
    border-bottom: 1px solid rgba(128, 128, 128, 0.4);
}

.app__rulecatalog_filter:focus {
    border-top: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.5);
    border-left: 0px;
}

.app__rulecatalog_filter::placeholder {
    color: transparent;
}

.app__rulecatalog_filter_label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: grey;
}

.app__rulecatalog_filter:placeholder-shown~.app__rulecatalog_filter_label {
    font-size: 14px;
    cursor: text;
    opacity: 0.5;
    top: 20px;
}

.app__rulecatalog_filter:focus~.app__rulecatalog_filter_label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: grey;
    opacity: 1;
}

.app__rulecatalog_filter_ico {
    height: 12px;
    position: absolute;
    top: 20px;
    right: 0px;
    cursor: pointer;
    opacity: 0.6;
    transition: all 0.2s ease;
}

.app__rulecatalog_filter_ico_hidden {
    opacity: 0;
}

/* MESSAGE */
.app__rulecatalog_message {
    color: #FFF;
    text-align: center;
}

/* RULE EDITOR */
/* .app__ruleeditor_zone {
    width: 100%;
    /* height: 100%; */
/* max-height: 100%; */
/*flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    border: 1px solid red;
} */

/* .app__ruleeditor_list_container {
    border: 1px solid grey;
    border-radius: 0.7rem;
    width: 250px;
    max-width: 250px;
    height: 100%;
    max-height: 100%;
    padding: 6px;
    margin-left: 10px;
    position: relative;
    overflow-y: auto;
    opacity: 1;
    transition: max-width 0.4s ease, opacity 0.2s ease 0.4s;
} */

.app__ruleeditor_list_container_hidden {
    max-width: 0px;
    opacity: 0;
    /* overflow: hidden; */
    transition: opacity 0.2s ease, max-width 0.4s ease 0.2s;
}

.app__ruleeditor__container {
    border: 1px solid grey;
    border-radius: 0.7rem;
    /* width: 100%; */
    height: 100%;
    flex: 1;
    position: relative;
}


/* BUTTONS */
.app__rulecatalog_buttons_container {
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    width: 100%;
    height: 50px;
}

.app__rulecatalog_button {
    background-color: var(--color-green-main-1);
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    height: 36px;
    /* width: 140px; */
    padding: 0px 16px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.4s ease;
}

.app__rulecatalog_button_hidden {
    background-color: grey;
    pointer-events: none;
}


/* RULE */
.app__rulecatalog_rule_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, calc((100vw - 280px)/3));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.app__rulecatalog_rule_grid_pop {
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
    display: flex;
    overflow-x: auto;
    gap: 20px;
    /* margin-bottom: 10px; */
    padding-bottom: 4px;
}

.app__rulecatalog_rule_grid_pop>div:first-child {
    margin-left: 20px;
}

.app__rulecatalog_rule_grid_pop>div:last-child {
    margin-right: 20px;
}

.app__rulecatalog_rule_grid_pop>div {
    width: calc((100vw - 280px)/3);
    min-width: calc((100vw - 280px)/3);
}

.app__rulecatalog_rule_grid_pop::-webkit-scrollbar {
    width: 0px;
    height: 4px;
    margin: 0px 20px;
}

.app__rulecatalog_rule_grid_pop::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: rgba(100, 100, 100, 0);
    transition: 0.2s ease;
    margin: 0px 20px;

}

.app__rulecatalog_rule_grid_pop:hover::-webkit-scrollbar-track {
    background-color: rgba(100, 100, 100, 0.2);
}

.app__rulecatalog_rule_grid_pop::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(100, 100, 100, 0);
    transition: 0.2s ease;
    margin: 0px 20px;

}

.app__rulecatalog_rule_grid_pop:hover::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.5);
}

.app__rulecatalog_title_container {
    /* background: var(--gradient-blue-background); */
    background-color: #081220;
    /* min-height: 26px; */
    padding-bottom: 4px;
    position: sticky;
    top: 0px;
    z-index: 100;
}

.app__rulecatalog_title {
    position: inherit;
    font-size: 13px;
    line-height: 14px;
    font-weight: 600;
    color: grey;
    margin-bottom: 4px;
}

.app__rulecatalog_rule_container {
    position: relative;
    background-color: var(--color-blue-secondary-2);
    /* height: 60px; */
    height: fit-content;
    /* width: 552px; */
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid;
    border-color: var(--color-blue-secondary-2);
    transition: border-color 0.3s ease;
}

.app__rulecatalog_rule_container_pop {
    position: relative;
    background: var(--gradient-green-bar);
    width: 100%;
    border-radius: 10px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    border: 1px solid;
    opacity: 0.8;
    transition: all 0.3s ease;
}

.app__rulecatalog_rule_container_pop:hover {
    /* border-color: grey; */
    opacity: 1;
}

.app__rulecatalog_rule_container:hover>div.app__rulecatalog_rule_box>div.app__rulecatalog_rule_body_right>div.app__rulecatalog_rule_button_container,
.app__rulecatalog_rule_container_pop:hover>div.app__rulecatalog_rule_box>div.app__rulecatalog_rule_body_right>div.app__rulecatalog_rule_button_container {
    opacity: 1;
}

.app__rulecatalog_rule_container:hover>div.app__rulecatalog_rule_box>div.app__rulecatalog_rule_body_right>div.app__rulecatalog_rule_image_container,
.app__rulecatalog_rule_container_pop:hover>div.app__rulecatalog_rule_box>div.app__rulecatalog_rule_body_right>div.app__rulecatalog_rule_image_container_pop {
    opacity: 0;
}


.app__rulecatalog_rule_box {
    flex: 1;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
}

.app__rulecatalog_rule_body {
    flex: 1;
    max-width: calc(100% - 145px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.app__rulecatalog_rule_title {
    color: #fff;
    font-size: 15px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}

.app__rulecatalog_rule_title_pop {
    color: var(--color-blue-main-0);
    font-size: 17px;
    line-height: 17px;
    font-weight: 800;
    margin-bottom: 10px;
}

.app__rulecatalog_rule_description {
    /* display: flex; */
    position: relative;
    color: lightgrey;
    font-size: 12px;
    line-height: 15px;
    cursor: default;
    white-space: break-spaces;
    max-width: 100%;
}

.app__rulecatalog_rule_description_variable {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 11px;
    height: 16px;
    margin: 0px 2px;
    padding: 1px 8px 2px;
    border-radius: 10px;
    border: 1px solid var(--color-green-main-1);
    /* background-color: var(--color-blue-secondary-2); */
    background-color: transparent;
}

.app__rulecatalog_rule_description_pop {
    position: relative;
    color: var(--color-blue-main-0);
    font-size: 12px;
    line-height: 13px;
    font-weight: 700;
    cursor: default;
}

.app__rulecatalog_rule_description_input {
    margin: 0px 2px;
    padding: 0px 6px;
    border-radius: 20px;
    border: 1px solid var(--color-green-main-1);
}

.app__rulecatalog_rule_description_input>input {
    text-align: center;
}

.app__rulecatalog_rule_description_input>input::-webkit-outer-spin-button,
.app__rulecatalog_rule_description_input>input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.app__rulecatalog_rule_description_input>input[type=number] {
    -moz-appearance: textfield;
}

.app__rulecatalog_rule_button_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 145px;
    height: 100%;
    min-height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 6px;
    opacity: 0;
    transition: opacity 0.3s ease;
    /* margin-bottom: 16px; */
}

.app__rulecatalog_rule_button_container_pop {
    position: absolute;
    top: 0;
    left: 0;
    width: 145px;
    height: 100%;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 6px;
    opacity: 1;
    transition: opacity 0.3s ease;
    /* margin-bottom: 16px; */
}

.app__rulecatalog_rule_button_box {
    display: flex;
    gap: 10px;
}

.app__rulecatalog_rule_button {
    background-color: var(--color-green-main-1);
    color: #fff;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    height: 30px;
    padding: 0px 16px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.app__rulecatalog_rule_button_small {
    /* color:  var(--color-green-main-1); */
    color:  #FFF;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.app__rulecatalog_rule_button_pop {
    background-color: var(--color-blue-main-0);
    color: #fff;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    height: 30px;
    padding: 0px 16px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.app__rulecatalog_rule_button>img {
    height: 16px;
}

.button_red_color {
    background-color: #DD1C1A;
}

.app__rulecatalog_footer {
    margin-top: 10px;
    height: 30px;
    width: 100%;
    display: flex;
}

.app__rulecatalog_footer_userinfos_time {
    color: lightgrey;
    font-size: 10px;
    line-height: 10px;
}

.app__rulecatalog_footer_userinfos_name {
    color: lightgrey;
    font-size: 12px;
    line-height: 12px;
    font-weight: 800;
}

.app__rulecatalog_rule_delete_container {
    z-index: 1000;
    background-color: var(--color-blue-secondary-2);
    border-radius: 12px;
    padding: 12px 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 6px;
}

.app__rulecatalog_rule_delete_container_buttons {
    display: flex;
    justify-content: center;
    gap: 8px;
}


/* RESPONSIVE */

@media screen and (max-width: 1400px) {
    .app__rulecatalog_rule_grid {
        grid-template-columns: repeat(auto-fit, calc((100vw - 260px)/2));
    }

    .app__rulecatalog_rule_grid_pop>div {
        width: calc((100vw - 260px)/2);
        min-width: calc((100vw - 260px)/2);
        /* width: calc((100vw - 340px)/2);
        min-width: calc((100vw - 340px)/2); */
    }
}

@media screen and (max-width: 900px) {
    .app__rulecatalog_back {
        padding: 40px;
    }

    .app__rulecatalog_rule_grid {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }

    .app__rulecatalog_rule_grid_pop>div {
        width: 80%;
        min-width: 80%;
    }
}

@media screen and (max-width: 550px) {
    .app__rulecatalog_back {
        top: 0px;
        left: 0px;
        width: 100vw;
        height: calc(100vh - var(--width-navbar) - 20px);
        padding: 20px;
    }

    .app__rulecatalog_header_container {
        justify-content: space-evenly;
        gap: 30px;
    }

    .app__rulecatalog_rule_container {
        flex-direction: column;
    }

    .app__rulecatalog_rule_button_container {
        width: 100%;
        flex-direction: row;
        opacity: 1;
        margin-top: 10px;
    }
}


.app__ruleeditor_zone {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    border: 1px solid red;
}




.app__rulecatalog_ruleditor {
    position: relative;
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
}

.app__rulecatalog_ruleditor_data_container {
    width: 100%;
    height: auto;
}

.app__rulecatalog_ruleditor_data_subcontainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.app__rulecatalog_ruleditor_data_subcontainer_3 {
    /* width: 32%; */
    flex: 1;
    height: auto;
    display: flex;
    flex-direction: column;
}

.app__rulecatalog_image_container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    aspect-ratio: 1;
}

.app__rulecatalog_image_container_box {
    width: 100%;
    aspect-ratio: 1;
    border: 1px solid rgba(211, 211, 211, 0.5);
    /* background-color: rgba(211, 211, 211, 0.2); */
    border-radius: 6px;
}

.app__rulecatalog_image_container_box_modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    aspect-ratio: 1;
    background-color: rgba(29, 46, 60, 0.5);
    backdrop-filter: blur(4px);
    color: lightgrey;
    text-align: center;
    font-size: 11px;
    border-radius: 6px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.app__rulecatalog_image_container_box_modal:hover {
    opacity: 1;
}


.app__rulecatalog_image_placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 1;
    border: 1px dashed rgba(211, 211, 211, 1);
    border-radius: 6px;
    color: lightgrey;
    text-align: center;
    font-size: 11px;
    opacity: 0.5;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    padding: 6px;
}

.app__rulecatalog_image_placeholder:hover {
    opacity: 0.9;
}

.app__rulecatalog_image_placeholder_loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 1;
    border: 1px dashed rgba(211, 211, 211, 1);
    border-radius: 6px;
    color: lightgrey;
    text-align: center;
    font-size: 11px;
    opacity: 0.9;
    transition: all 0.2s ease-in-out;
    cursor: default;
    pointer-events: none;
}

.app__rulecatalog_ruleditor_compo_container {
    position: relative;
    width: 100%;
    flex: 1;
}


.app__rulecatalog_ruleditor_compo_container_sized {
    /* give the outermost container a predefined size */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.app__rulecatalog_ruleditor_compo_container_sized_section {
    flex-grow: 1;
    display: flex;
    /* for Firefox */
    min-height: 0;
}

.app__ruleeditor_list_container {
    max-width: 250px;
    flex-grow: 1;
    overflow-y: auto;
    padding-left: 8px;
    padding-right: 4px;
    /* for Firefox */
    min-height: 0;
}

/* SECTION DATA SCROLL BARS */
.app__ruleeditor_list_container::-webkit-scrollbar,
.app__rulecatalog_section::-webkit-scrollbar,
.app__rulecatalog_subsection::-webkit-scrollbar,
.app__rulecatalog_textarea::-webkit-scrollbar {
    width: 4px;
    height: 0px;
}

.app__ruleeditor_list_container::-webkit-scrollbar-track,
.app__rulecatalog_subsection::-webkit-scrollbar-track,
.app__rulecatalog_section::-webkit-scrollbar-track,
.app__rulecatalog_textarea::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: rgba(100, 100, 100, 0.2);
}

.app__ruleeditor_list_container::-webkit-scrollbar-thumb,
.app__rulecatalog_subsection::-webkit-scrollbar-thumb,
.app__rulecatalog_section::-webkit-scrollbar-thumb,
.app__rulecatalog_textarea::-webkit-scrollbar-thumb {
    border-radius: 2px;
    /* background-color: grey; */
    background-color: rgba(100, 100, 100, 0.5);
}

.ruleeditor__node_ph_list_group_title {
    position: sticky;
    background-color: #081220;
    top: 0px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: grey;
    height: 20px;
    /* margin: 0 0 4px 0; */
}

.app__rulecatalog_import_container {
    position: relative;
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
}

.app__rulecatalog_textarea {
    margin-top: 6px;
    width: 100%;
    height: 100%;
    border: 1px solid grey;
    border-radius: 0.7rem;
    padding: 12px;
    background-color: transparent;
    color: rgb(148, 147, 147);
    outline: none;
    resize: none;
}

.app__rulecatalog_import_preview_container {
    background-color: var(--color-blue-secondary-2);
    -webkit-box-shadow: 0px 0px 14px 3px rgba(8, 18, 32, 1);
    -moz-box-shadow: 0px 0px 14px 3px rgba(8, 18, 32, 1);
    box-shadow: 0px 0px 14px 3px rgba(8, 18, 32, 1);
    height: -moz-fit-content;
    height: fit-content;
    min-height: 40px;
    width: 50%;
    max-width: 400px;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid;
    border-color: var(--color-blue-secondary-2);
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.2s ease-in-out;
}

.app__rulecatalog_import_preview_message {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: grey;
}

.app__rulecatalog_import_preview_message_invalid {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #FF5964;
}

.app__rulecatalog_rule_preview_body {
    display: flex;
}

.app__rulecatalog_rule_preview_image_container {
    width: 40%;
}

.app__rulecatalog_rule_preview_image_container>img {
    width: 100%;
}

.app__rulecatalog_rule_body_right {
    width: 145px;
    max-width: 145px;
    position: relative;
    min-height: 40px;
}

.app__rulecatalog_rule_image_container {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.app__rulecatalog_rule_image_container>img {
    width: 100%;
}

.app__rulecatalog_rule_image_container_pop {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.app__rulecatalog_rule_image_container_pop>img {
    width: 100%;
    /* filter: invert(75%) hue-rotate(90deg); */
    /* filter: grayscale(80%) invert(75%); */
    filter: hue-rotate(190deg) saturate(500%) grayscale(100%) invert(25%);
}