.app__buildings_value_main {
    font-size: 17px;
    line-height: 17px;
    font-weight: 700;
    margin-bottom: 3px;
}

.app__buildings_subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* .app__buildings_checkbox_container {
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative;
    bottom: 15px;
} */

.app__buildings_checkbox_label {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    color: #062134;
}

.app__buildings_checkbox {
    appearance: auto;
    /* accent-color: var(--color-green-main-1); */
    accent-color: #1d2e3c80;
    height: 11px;
    outline: none;
    cursor: pointer;
}

.app__buildings_value_second {
    display: flex;
    font-size: 14px;
    line-height: 15px;
    font-weight: 600;
    /* min-width: 150px; */
    /* display: flex;
    justify-content: flex-start;
    align-items: flex-end; */
}

.app__buildings_value_second_big {
    font-size: 17px;
    line-height: 18px;
    font-weight: 600;
}

.app__buildings_value_second_small {
    font-size: 11px;
    margin-left: 5px;
    margin-top: 2px;
    line-height: 12px;
}

.app__buildings_value_second_small2 {
    font-size: 12px;
    line-height: 12px;
}

.app__buildings_title {
    font-size: 11px;
    line-height: 12px;
    font-weight: 500;
    color: grey;
}

.app__buildings_tag {
    /* color: #fff; */
    /* background-color: var(--color-blue-main-0); */
    /* background-color: var(--color-green-main-1); */
    border: 1px solid var(--color-blue-main-0);
    font-size: 11px;
    padding: 1px 6px 2px;
    border-radius: 4px;
    margin-right: 4px;
}

.app__buildings_body {
    display: flex;

}

.app__buildings_svg {
    width: 26%;
    min-width: 26%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.app__buildings_infos {
    position: relative;
    width: 72%;
    min-width: 72%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* padding-top: 6px; */
}

.app__buildings_info_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 170px);
    grid-column-gap: 10px;
    grid-row-gap: 14px;
    justify-content: space-between;
}

.app__buildings_info_grid_long {
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-content: space-between;
}

.app__buildings_info_title {
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: grey;
    margin-bottom: 6px;
}

.app__buildings_info_card {
    display: flex;
}

.app__buildings_info_card>img {
    margin-right: 8px;
    height: 24px;
}

.app__buildings_info_card>div {
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.app__buildings_info_card_main {
    display: flex;
    flex-direction: column;
    gap: 2px;
    /* justify-content: center; */
    /* align-items: center; */
    border: 1px solid #062134;
    padding: 4px 8px;
    margin-left: 2px;
    border-radius: 6px;
}

.app__buidings_coef_body {
    display: flex;
    align-items: center;
    gap: 14px;
}

.app__buildings_chart {
    width: 200px;
    height: 150px;
    position: relative;
}

.app__buildings_chart_value {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #062134;
    top: 30px;
    left: 60px;
    width: 80px;
    height: 90px;
    /* background-color: aqua; */
}

.app__buidings_coef_text {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.app__buildings_infos_menu {
    display: flex;
    gap: 20px;
    /* justify-content: space-between; */
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
    position: relative;
    /* border-bottom: 1px solid #06213438; */
    height: 26px;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: visible;
}

.app__buildings_infos_menu_line {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: lightgrey;
}


.app__buildings_infos_menu_full {
    justify-content: space-between;
}

.app__buildings_infos_menu_item {
    min-width: max-content;
    opacity: 0.7;
    transition: all 0.2s ease;
    cursor: pointer;
    position: relative;
}

.app__buildings_infos_menu_item_selected {
    opacity: 1;
}

.app__buildings_infos_menu_indicator {
    height: 2px;
    border-radius: 2px;
    background-color: #062134;
    width: 80px;
    position: absolute;
    top: 16px;
    transition: all 0.2s ease;
}

.app__buildings_infos_menu::-webkit-scrollbar {
    width: 0px;
    height: 3px;
}

.app__buildings_infos_menu::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: rgba(100, 100, 100, 0);
    transition: all 0.2s ease;

}

.app__buildings_infos_menu:hover::-webkit-scrollbar-track {
    background-color: rgba(100, 100, 100, 0.2);
}

.app__buildings_infos_menu::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(100, 100, 100, 0);
    transition: all 0.2s ease;

}

.app__buildings_infos_menu:hover::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.5);
}

.app__buildings_infos_frame {
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    display: flex;
    overflow: hidden;
}

.app__buildings_infos_subframe {
    min-width: 100%;
    height: 100%;
    border: 1px solid #FFF;
    transform: translateX(0px);
    transition: all 0.3s ease;
}

.app__buildings_info_dpe_img {
    width: 98px;
    margin-right: 10px;
}

.app__buildings_info_dpe_img>img {
    width: 100%;
}

.app__buildings_info_dpe_data {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: calc(100% - 95px);
}

.app__buildings_info_dpe_data_split {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.app__buildings_range {
    width: 100%;
    margin-top: 5px;
}

.app__buildings_value_second_range_min {
    position: absolute;
    bottom: -6px;
    left: 20px;
    color: #bfbbbb;
    font-size: 10px;
    font-weight: 600;
}

.app__buildings_value_second_range_max {
    position: absolute;
    bottom: -6px;
    right: 20px;
    color: #bfbbbb;
    font-size: 10px;
    font-weight: 600;
}

.app__buildings_value_second_range_mean {
    position: absolute;
    bottom: 0px;
    left: 80px;
    font-size: 11px;
    font-weight: 600;
    color: #FFF;
    background-color: #062134;
    padding: 1px 5px;
    border-radius: 4px;
}

.app__buildings_description {
    font-size: 12px;
    line-height: 13px;
}

.app__buildings_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}

.app__buildings_bottom {
    width: 100%;
    border-top: 1px solid lightgrey;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__buildings_info_status {
    display: flex;
    gap: 18px;
    background-color: #06213414;
    padding: 3px 10px;
    border-radius: 20px;
    position: relative;
}

.app__buildings_info_status_item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    color: #062134;
    z-index: 88;
    min-width: 86px;
    width: 86px;
}

.app__buildings_info_status_item_selected {
    color: #FFF;
}

.app__buildings_info_status_item>img {
    height: 14px;
    margin-right: 4px;
}

.app__buildings_info_status_indicator {
    position: absolute;
    height: 24px;
    width: 100px;
    background-color: #062134;
    top: 0px;
    left: 0px;
    border-radius: 20px;
    z-index: 87;
    transition: all 0.2s ease;
}

.app__buildings_info_status_data_container {
    margin-top: 12px;
    width: 100%;
    display: flex;
    gap: 18px;
    align-items: center;
    justify-content: center;
}

.app__sectiondata_buildings_container {
    max-height: 6000px;
    overflow: hidden;
    transition: max-height 0.6s ease;
}

.app__sectiondata_buildings_container_small {
    max-height: 640px;
}

.app__buildings_input_block {
    display: flex;
    justify-content: flex-start;
    justify-items: left;
    position: relative;
}

.app__buildings_input {
    outline: none;
    border: none;
    font-family: var(--font-family-Manrope);
    font-size: 15px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: -0.6px;
    height: 17px;
    width: 116px;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease;
    color: #007384;
    background-color: transparent;
}

.app__buildings_input:hover {
    /* border-bottom: 1px solid lightgrey; */
    border-bottom: 1px solid #007384;
}

.app__buildings_input:focus {
    /* border-bottom: 1px solid lightgrey; */
    border-bottom: 1px solid #007384;
}

.app__buildings_input:focus~.app__buildings_input_unit>.app__buildings_input_unit_visible {
    color: transparent;
}

.app__buildings_input_unit {
    position: absolute;
    top: 1px;
    left: 0px;
    font-family: var(--font-family-Manrope);
    font-size: 15px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: -0.6px;
    color: transparent;
    z-index: -1;
}

.app__buildings_input_unit_visible {
    color: #007384;
    font-size: 14px;
    transition: all 0.2s ease;
}

.app__buildings_checkbox_container {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    cursor: pointer;
    margin-top: 2px;
}

.app__buildings_checkbox {
    appearance: auto;
    /* accent-color: var(--color-green-main-1); */
    accent-color: #007384;
    height: 12px;
    outline: none;
}

.app__buildings_checkbox_label {
    color: #007384;
    font-size: 13px;
    line-height: 13px;
    cursor: pointer;
}