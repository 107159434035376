.app__modification {
    display: flex;
    height: auto;
    max-height: 200px;
    overflow: hidden;
    opacity: 1;
    transition: max-height 0.6s, opacity 0.4s;
}

.app__modification_hidden {
    max-height: 0px;
    opacity: 0;
    transition: opacity 0.4s, max-height 0.6s;
}

/* LINE */

.app__modification_line_container {
    position: relative;
    width: 14px;
    height: auto;
}

.app__modification_line_container_hidden {
    width: 0px;
    overflow: hidden;
}

.app__modification_line_point {
    background-color: var(--color-blue-main-0);
    position: absolute;
    top: calc(13px - 3px);
    left: 0px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.app__modification_line_top {
    background-color: var(--color-blue-main-0);
    position: absolute;
    top: 0px;
    left: 2px;
    width: 2px;
    height: 13px;
}

.app__modification_line_bottom {
    background-color: var(--color-blue-main-0);
    position: absolute;
    top: 13px;
    left: 2px;
    width: 2px;
    height: 100%;
}

.app__modification_showmore_line_point-1 {
    background-color: var(--color-blue-main-0);
    position: absolute;
    top: 8px;
    left: 2px;
    width: 2px;
    height: 2px;
    border-radius: 50%;
}

.app__modification_showmore_line_point-2 {
    background-color: var(--color-blue-main-0);
    position: absolute;
    top: 12px;
    left: 2px;
    width: 2px;
    height: 2px;
    border-radius: 50%;
}

.app__modification_showmore_line_point-3 {
    background-color: var(--color-blue-main-0);
    position: absolute;
    top: 16px;
    left: 2px;
    width: 2px;
    height: 2px;
    border-radius: 50%;
}

.app__modification_showmore_line_top {
    background-color: var(--color-blue-main-0);
    position: absolute;
    top: 0px;
    left: 2px;
    width: 2px;
    height: 6px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.app__modification_showmore_line_bottom {
    background-color: var(--color-blue-main-0);
    position: absolute;
    top: 20px;
    left: 2px;
    width: 2px;
    height: 16px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

/* USER DATA */

.app__modification_user_container {
    display: flex;
    margin-bottom: 1px;
}

/* USER DATA IMG */

.app__modification_user_img_container {
    height: 26px;
    width: 26px;
    margin-right: 4px;
}

.app__modification_user_img_container img {
    height: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
}

/* USER DATA TEXT */

.app__modification_user_data_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.app__modification_user_data_time {
    font-size: 10px;
    line-height: 10px;
}

.app__modification_user_data_person {
    font-size: 12px;
    line-height: 12px;
    font-weight: 800;
}

/* COMMENT */

.app__modification_comment_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.app__modification_comment_container_nomargin {
    margin-bottom: 0px;
}

.app__modification_comment_action {
    font-style: italic;
    font-size: 11px;
    line-height: 11px;
    margin-right: 4px;
}

.app__modification_comment_value {
    font-style: italic;
    font-size: 10px;
    line-height: 10px;
    padding: 1px 4px 2px 3px;
    border: 1px solid var(--color-blue-main-0);
    border-radius: 4px;
}

.app__modification_comment_value_link {
    font-style: italic;
    font-size: 10px;
    line-height: 10px;
    padding: 1px 4px 2px 3px;
    border: 1px solid var(--color-blue-main-0);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.app__modification_comment_value_link:hover {
    color: #FFF;
    background-color: var(--color-blue-main-0);
}

/* SHOW MORE */
.app__modification_showmore_container {
    display: flex;
    height: 36px;
    overflow: hidden;
    opacity: 1;
    transition: all 0.4s ease 0.2s;
}

.app__modification_showmore_container_hidden {
    height: 0px;
    opacity: 0;
    transition: all 0.1s ease 0s;
}

.app__modification_showmore_text {
    padding-top: 7px;
    font-size: 11px;
    line-height: 12px;
    color: grey;
    cursor: pointer;
    transition: color 0.2s;
}

.app__modification_showmore_text:hover {
    color: var(--color-blue-main-0)
}

/* RESPONSIVE */

@media screen and (max-width: 550px) {
    .app__modification_comment_container {
        flex-direction: column;
        align-items: flex-start;
    }

    .app__modification_comment_action {
        margin-right: 0px;
        margin-bottom: 2px;
    }
}