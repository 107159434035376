.app__header {
    /* background-color: red; */
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    /* height: var(--width-navbar); */
    background: var(--color-grey-main-1);
    color: var(--color-blue-main-1);
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
}

/* .app__header_padding_small {
    padding: 10px 20px 10px calc(20px + var(--width-navbar));
} */

/* .app__header_padding_large {
    padding: 10px 60px 10px calc(60px + var(--width-navbar));
} */

.app__header_link {
    text-decoration: underline;
}

.app__header_dark {
    background: transparent;
    color: #FFF;
    padding: 10px 50px;
}

/* INFO */
.app__header_info_container {
    height: var(--width-navbar);
    /* background-color: bisque; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}


/* INFO LOGO & TITLE */
.app__header_info_title_container {
    height: 100%;
    display: flex;
}

.app__header_info_logo_container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 60px;
    padding-right: 12px;
}

.app__header_info_logo_container img {
    width: 90%;
}

.app__header_info_title {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.app__header_info_title_container h1 {
    font-family: var(--font-family-Prompt);
    /* color: var(--color-blue-main-1); */
    font-size: 28px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: 0.1em;
}

.app__header_info_title_container h2 {
    font-family: var(--font-family-Manrope);
    /* color: var(--color-blue-main-1); */
    font-size: 17px;
    line-height: 16px;
    font-weight: 900;
    letter-spacing: 0.04em;
}

.app__header_info_studytitle {
    /* color: grey; */
    opacity: 0.5;
}

/* INFO USER BASIC */
.app__header_info_user_container {
    position: relative;
}

.app__header_info_user_container_visible {
    z-index: 1111;
    background-color: rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0px 0px 20px 2px rgba(6, 33, 52, 0.2);
    box-shadow: 0px 0px 20px 2px rgba(6, 33, 52, 0.2);
    padding: 60px 10px 10px;
    position: absolute;
    border-radius: 16px;
    top: 0px;
    right: 0px;
    width: 100%;
    min-width: 180px;
    transition: background-color 0.5s, box-shadow 0.5s;
}

.app__header_info_user_container_visible div.app__header_info_user_basic_container div.app__header_info_user_basic_text {
    opacity: 1;
}

.app__header__info_dark {
    background-color: var(--color-blue-secondary-2);
    -webkit-box-shadow: 0px 0px 14px 3px rgba(8, 18, 32, 1);
    -moz-box-shadow: 0px 0px 14px 3px rgba(8, 18, 32, 1);
    box-shadow: 0px 0px 14px 3px rgba(8, 18, 32, 1);
}

.app__header_info_user_container_hidden {
    z-index: 109;
    background-color: rgba(255, 255, 255, 0);
    -webkit-box-shadow: 0px 0px 20px 2px rgba(6, 33, 52, 0.2);
    box-shadow: 0px 0px 20px 2px rgba(6, 33, 52, 0);
    transition: background-color 0.5s, box-shadow 0.5s;
}

.app__header_info_user_basic_container {
    /* background-color: aqua; */
    height: var(--width-navbar);
    width: 100%;
    /* min-width: 180px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1112;
    padding: 0px 0px 0px 12px;
    transition: all 0.1s ease;
}

.app__header_info_user_basic_container_focus {
    padding: 0px 12px;
    /* justify-content: space-between; */
    transition: all 0.3s 0.1s ease;
    cursor: default;
}

.app__header_info_user_basic_text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    margin-right: 10px;
    height: 30px;
    margin-right: 12px;
    opacity: 1;
    transition: all 0.1s ease;
}

.app__header_info_user_basic_text_focus {
    /* align-items: flex-start; */
}

.app__header_info_user_basic_text_name {
    display: flex;
    font-family: var(--font-family-Manrope);
    /* color: var(--color-blue-main-1); */
    font-size: 13px;
    line-height: 12px;
    font-weight: 900;
    letter-spacing: 0.04em;
}

.app__header_info_user_basic_text_group {
    display: flex;
    font-family: var(--font-family-Manrope);
    /* color: var(--color-blue-main-1); */
    font-size: 11px;
    line-height: 10px;
    font-weight: 900;
    letter-spacing: 0.04em;
    opacity: 0.8;
}

.app__header_info_user_basic_img {
    display: flex;
    align-items: center;
}

.app__header_info_user_basic_img img {
    height: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
    object-fit: cover;
}

/* INFO USER ADVANCED */
.app__header_info_user_advanced_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
}

.app__header_info_user_advanced_visible {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 0.5s 0.3s linear;
}

.app__header_info_user_advanced_hidden {
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s 1s, opacity 0.5s linear;
}

.app__header_info_user_advanced_spacer {
    background-color: var(--color-blue-main-0);
    opacity: 0.1;
    height: 1px;
}

.app__header_info_user_advanced_spacer_dark {
    background-color: lightgrey;
    opacity: 0.4;
}

.app__header_info_user_advanced_link {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 14px;
    /* background-color: #FFF; */
    /* color: var(--color-blue-main-0); */
    height: 15px;
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
}

.app__header_info_user_advanced_button {
    transition: all 0.2s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    background-color: transparent;
    border: 1px solid #DD1C1A;
    color: #DD1C1A;
    border-radius: 30px;
    height: 30px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 4px;
}

.app__header_info_user_advanced_button:hover {
    color: #fff;
    background-color: #DD1C1A;
}


/* BANNER */
.app__header_banner_container {
    position: relative;
    z-index: 110;
    height: 40px;
    /* height: 40px; */
    /* background-color: darkblue; */
    background: var(--gradient-green-bar);
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
}

.app__header_banner_text {
    font-size: 15px;
}

.app__header_banner_text_mail {
    font-size: 14px;
    font-weight: 700;
}

.app__header_banner_button {
    padding: 6px 16px;
    border-radius: 20px;
    font-size: 13px;
    color: #fff;
    background-color: var(--color-blue-main-0);
    cursor: pointer;
}


/* RESPONSIVE */

@media screen and (max-width: 1050px) {}

@media screen and (max-width: 700px) {}

@media screen and (max-width: 550px) {
    /* .app__header {
        background: var(--gradient-blue-background);
    } */

    .app__header_padding_small {
        padding: 10px 20px 10px 20px;
    }

    .app__header_padding_large {
        padding: 10px 20px 10px 20px;
    }

    .app__header_info_user_basic_text {
        opacity: 0;
    }

    .app__header_info_user_basic_text_focus {
        opacity: 1;
        transition: all 0.2s 0.2s ease;
    }

    .app__header_info_title_container {
        align-items: center;
    }

    .app__header_info_logo_container {
        /* display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        width: 46px;
        margin-right: 10px; */
        display: none;
    }

    .app__header_info_title h1 {
        /* display: none; */
        font-size: 20px;
        line-height: 20px;
    }

    .app__header_info_user_container {
        position: absolute;
        top: 10px;
        right: 20px;
    }

    .app__header_dark {
        padding-left: 20px;
    }
}