.landing__partners {
    display: flex;
    justify-content: space-evenly;
    z-index: 1000;
}

.landing__partners_container {
    height: 15vw;
    max-height: 160px;
    width: 15vw;
    max-width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing__partners_container img {
    height: 100%;
}

/* RESPONSIVE */

/* @media screen and (max-width: 1050px) {} */

@media screen and (max-width: 700px) {
    .landing__partners_container {
        height: 20vw;
        width: 20vw;
    }
}

/* @media screen and (max-width: 550px) {} */