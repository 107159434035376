.modal__background {
    background-color: rgba(6, 33, 52, 0.6);
    position: fixed;
    z-index: 10000;
    height: 100%;
    /* height: 100vh; */
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__container {
    /* height: 300px;
    width: 300px; */
    background-color: #fff;
    border-radius: 1rem;
    -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    padding: 30px 20px;
    margin: 30px;
}

/* CLOSE */
.modal__close_container {
    position: absolute;
    top: 14px;
    right: 14px;
}

.modal__close_container img {
    height: 14px;
}

/* BODY */
.modal__body_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--color-blue-main-0);
}

.modal__body_logo {
    height: 50px;
}

.modal__body_container h3 {
    margin: 10px 0 20px 0;
}

.modal__body_text {
    margin-bottom: 14px;
}

.modal__body_instruction {
    font-weight: 700;
}

.modal__body_instruction img {
    width: 13px;
    vertical-align: bottom;
    transform: translateY(-3.8px);
    margin: 0 2px;
}