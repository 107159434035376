.auth__activateform {
    position: absolute;
    /* left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 360px; */
}

.auth__activateform h1 {
    color: #1ecd97;
}

.auth__activateform h4 {
    text-align: center;
}

/* BUTTON */
.auth__activateform_button {
    margin: 16px 0;
    outline: none;
    height: 40px;
    width: 230px;
    text-align: center;
    /* width: 100%; */
    border-radius: 40px;
    background: #1ecd97;
    border: 2px solid #1ecd97;
    color: #fff;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.25s ease;
}

.loading {
    background: transparent;
    width: 40px;
    border-color: #9b9b9b;
    border-width: 3px;
    font-size: 0;
    border-left-color: #1ecd97;
    animation: rotating 2s 0.25s linear infinite;
}


.loadedSuccess {
    /* width: 40px; */
    font-size: 13px;
    color: white;
    background: #1ecd97;
    border-color: #1ecd97;
}

.loadedFail {
    /* width: 40px; */
    font-size: 13px;
    color: white;
    background: #FF5964;
    border-color: #FF5964;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.auth__authform_message {
    color: #FF5964;
    font-size: 12px;
    font-weight: 800;
    text-align: center;
    margin: 10px 0 0 0;
}