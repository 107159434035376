.app__context_commodities_detail_container {
    margin: 0px 0px 12px 20px;
    max-height: 4000px;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
}

.app__context_commodities_detail_container_hidden {
    max-height: 0px;
}

.app__context_commodities_label {
    font-size: 13px;
    line-height: 14px;
    font-weight: 600;
    margin: 20px 0 4px;
}

.app__context_commodities_titles_container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    gap: 6px;
    padding-bottom: 2px;
    border-bottom: 1px solid rgba(211, 211, 211, 0.6);
}

.app__context_commodities_title {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: grey;
    position: relative;
}


.app__context_commodities_title_3:nth-child(1) {
    width: 50%;
}

.app__context_commodities_title_3:nth-child(2) {
    width: 30%;
}

.app__context_commodities_title_3:nth-child(3) {
    flex: 1;
}

.app__context_commodities_title_2:nth-child(1) {
    width: 80%;
}

.app__context_commodities_title_2:nth-child(2) {
    flex: 1;
}


.app__context_commodities_values_container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 6px;
    margin: 2px 0 0 0;
    padding-bottom: 2px;
    border-bottom: 1px solid rgba(211, 211, 211, 0.6);
    min-height: 21px;
}

.app__context_commodities_value {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    /* opacity: 0.8; */
}

.app__context_commodities_value_color {
    background-color: #062134;
    color: #FFF;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 11px;
}