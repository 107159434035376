.landing__home {
    background: var(--color-blue-main-1);
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    padding: 1rem 6rem;
}

.gradient__text {
    font-weight: 800;
    background: var(--gradient-green-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* SECTION DATA SCROLL BARS */
body {
    /* background: var(--color-blue-main-1); */
}

body::-webkit-scrollbar {
    width: 4px;
    background-color: var(--color-blue-main-1);
}

body::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: var(--color-blue-main-1);
}

body::-webkit-scrollbar-thumb {
    border-radius: 8px;
    /* background-color: grey; */
    background-color: rgba(211, 211, 211, 0.5);
}

/* RESPONSIVE */

/* @media screen and (max-width: 1050px) {
} */

@media screen and (max-width: 700px) {
    .section__padding {
        padding: 1rem 3rem;
    }

    .section__margin {
        padding: 1rem 3rem;
    }
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 1rem 2rem;
    }

    .section__margin {
        padding: 1rem 2rem;
    }
}