.app__land_container {
    /* background-color: aqua; */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 0px !important;
    padding: 8px 14px 10px 14px !important;
}

/* IMAGE */
.app__land_img_container {
    background-color: beige;
    width: 45px;
    height: 45px;
}

/* DATA */
.app__land_data_container {
    flex: 1;
}

.app__land_data_container:hover>div.app__land_data_main_container>div.app__land_data_main_close {
    opacity: 1;
}

/* DATA MAIN */
.app__land_data_main_container {
    width: 100%;
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
}

.app__land_data_main_label {
    font-weight: 700;
}

.app__land_data_main_close {
    display: flex;
    align-items: flex-start;
    padding-top: 6px;
    opacity: 0;
    transition: all 0.2s ease;
}

.app__land_data_main_close img {
    width: 10px;
    cursor: pointer;
}

/* DATA DETAIL */
.app__land_data_detail_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.app__land_data_detail_box {
    display: flex;
    flex-direction: column;
}

.app__land_data_detail_title {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: grey;
}

.app__land_data_detail_value {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
}

/* BUTTON */
.app__land_data_button2 {
    display: flex;
    align-items: center;
    height: 14px;
    width: auto;
    font-size: 10px;
    line-height: 10px;
    border: 1px solid var(--color-blue-main-0);
    border-radius: 8px;
    padding: 0 6px;
    cursor: pointer;
    background-color: #fff;
    color: var(--color-blue-main-0);
    transition: all 0.2s ease;
}

.app__land_data_button2:hover {
    background-color: var(--color-blue-main-0);
    color: #fff;
}

.app__land_data_button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: auto;
    font-size: 11px;
    font-weight: 600;
    line-height: 11px;
    border-radius: 8px;
    padding: 3px 8px 4px 8px;
    cursor: pointer;
    background-color: var(--color-green-main-1);
    color: #fff;
    transition: all 0.2s ease;
}

.app__sectiondata_lands_container {
    max-height: 6000px;
    overflow: hidden;
    transition: max-height 0.6s ease;
}

.app__sectiondata_lands_container_small {
    max-height: 350px;
}

/* SELECT */
.app__sectiondata_lands_select_container {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 4px;
}

.app__sectiondata_lands_select_label {
    font-size: 11px;
    line-height: 13px;
    font-weight: 400;
    color: grey;
}

.app__sectiondata_lands_select {
    position: inherit;
    font-size: 11px;
    line-height: 13px;
    font-weight: 400;
    color: grey;
    border: none;
    background-color: transparent;
    font-family: var(--font-family-Manrope);
    cursor: pointer;
    outline: none;
}