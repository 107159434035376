/* --- TOP PART --- */
.app__card_container {
    opacity: 1;
    transition: all 0.3s ease;
}

.app__card_container_deleted {
    opacity: 0;
    pointer-events: none;
}

/* CONTAINER */
.app__card_top_container {
    color: white;
    height: 35%;
    background: var(--gradient-blue-background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    transition: height 0.4s ease;
    position: relative;
}

/* TITLE */
.app__card_top_title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 0px;
    opacity: 0;
    transition: height 0.4s ease, opacity 0.2s ease;
}

.app__card_top_title h5 {
    font-size: 17px;
    line-height: 19px;
    font-weight: 700;
    text-align: center;
    padding: 0px 8px;
}

/* IMAGE */
.app__card_top_img {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    height: 90%;
    max-height: 170px;
    transition: height 0.4s ease;
}

.app__card_top_img img,
.app__card_top_img svg {
    height: 100%;
}

/* BUTTON */
.app__card_top_button_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    height: 0px;
    opacity: 0;
    transition: height 0.4s ease, opacity 0.2s ease;
}

.app__card_top_button {
    background-color: var(--color-green-main-1);
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    width: 110px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.app__card_top_button_small {
    /* background-color: #DD1C1A; */
    background-color: #DD1C1A;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.app__card_top_button_small_green {
    /* background-color: #DD1C1A; */
    background-color: var(--color-green-main-1);
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.app__card_top_button_small_green>img,
.app__card_top_button_small>img {
    height: 18px;
}

.app__card_top_validation_container {
    background-color: var(--color-blue-secondary-2);
    width: 220px;
    /* height: 100px; */
    border-radius: 20px;
    position: absolute;
    bottom: 25px;
    padding: 14px 10px;
    /* box-shadow: 0 0 14px rgb(100 100 100 / 20%); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.app__card_top_validation_text {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 10px;
}

.app__card_top_validation_button_red {
    background-color: #DD1C1A;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    height: 30px;
    width: 120px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.app__card_top_validation_button_green {
    background-color: var(--color-green-main-1);
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    height: 30px;
    width: 120px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}


/* --- BOTTOM PART --- */



/* --- HOVER PART --- */

.app__card_container:hover .app__card_top_container {
    height: 100%;
    transition: height 0.4s ease;
}

.app__card_container:hover .app__card_top_title {
    height: 20%;
    opacity: 1;
    transition: height 0.4s ease, opacity 0.3s ease 0.4s;
}

.app__card_container:hover .app__card_top_button_container {
    height: 30%;
    opacity: 1;
    transition: height 0.4s ease, opacity 0.3s ease 0.4s;
}