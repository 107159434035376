.combi__container {
    position: relative;
    width: 100%;
    min-height: 100%;
}

.combi__launch_button {
    background-color: var(--color-green-main-1);
    border: 2px solid var(--color-green-main-1);
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    /* width: 140px; */
    padding: 0px 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.combi__launch_button_white {
    background-color: transparent;
    border: 2px solid var(--color-green-main-1);
    color: var(--color-green-main-1);
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    /* width: 140px; */
    padding: 0px 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.combi__grid_container {
    /* background-color: aqua; */
    display: flex;
    width: calc(100% + 40px);
    position: relative;
    gap: 30px;
    overflow-x: hidden;
    left: -20px;
    padding-left: 20px;
}

.combi__grid {
    /* background-color: #d951536e; */
    min-width: calc(100% - 20px);
    height: fit-content;
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 280px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
}

.combi__card {
    background-color: #fff;
    border-radius: 0.7rem;
    box-shadow: 0 0 14px rgb(100 100 100 / 10%);
    width: 100%;
    height: 130px;
    padding: 10px 10px 10px 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    cursor: pointer;
    /* transition: all 0.2s ease; */
}

.combi__card:hover>div.combi__card_svg {
    transform: scale(1.03);
}

/* .combi__card:hover>div.combi__card_info>div.combi__card_info_body>div.combi__card_info_body_section_dynamic {
    height: 32px;
} */

.combi__card_selected>div.combi__card_svg {
    transform: scale(1.03);
}

.combi__card_selected>div.combi__card_info>div.combi__card_info_body>div.combi__card_info_body_section_dynamic {
    height: 32px;
}

.combi__card_selected {
    background-color: #062134;
}

.combi__card_selected>div.combi__card_info {
    color: #fff;
}

.combi__card_invalid {
    /* background-color: #b83a3a; */
    /* border: 2px solid #f55555; */
    border: 2px solid #d95154;
}

.combi__card_unavailable {
    opacity: 0.5;
    cursor: default;
}

.combi__card_unavailable:hover>div.combi__card_svg {
    transform: scale(1) !important;
}

.combi__card_unavailable:hover>div.combi__card_info>div.combi__card_info_body>div.combi__card_unavailable_message {
    opacity: 1;
}

.combi__card_unavailable_message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    color: rgb(40, 40, 40);
    /* color: #d95154; */
    font-size: 11px;
    line-height: 12px;
    font-weight: 800;
    text-align: center;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    z-index: 100;
}

.combi__card_body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.combi__card_svg {
    width: 40%;
    min-width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
}

.combi__card_svg>svg {
    display: block;
    width: 100%;
    max-height: 100%;
    aspect-ratio: 1;
}


.combi__card_info {
    height: 100%;
    width: 56%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;
    position: relative;
}

.combi__card_info_title {
    font-size: 15px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 4px;
}

.combi__card_info_body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-bottom: 8px; */
    width: 100%;
    height: 100%;
}

.combi__card_info_body_section_separator {
    width: 1px;
    height: 30px;
    background-color: grey;
    opacity: 0.5;
}

.combi__card_info_body_section {
    width: 100%;
    height: 32px;
    /* background-color: #d95154; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
}

.combi__card_info_body_section_dynamic {
    width: 100%;
    height: 0px;
    /* background-color: #d95154; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    overflow: hidden;
    transition: height 0.3s 0.1s ease-in-out;
}

.combi__card_info_body_section_hidden {
    height: 0px;
}

.combi__card_info_body_kpi_container {
    width: 42%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.combi__card_info_body_kpi_legend {
    font-size: 9px;
    font-weight: 600;
    color: grey;
}

.combi__card_info_legend {
    font-size: 11px;
    line-height: 12px;
    font-weight: 500;
    color: grey;
    margin-top: 5px;
}

.combi__card_info_body_kpi_value {
    font-weight: 700;
    font-size: 14px;
}

.combi__card_info_text {
    font-size: 13px;
    line-height: 15px;
}

.app__sectiondata_widget_sticky {
    position: sticky;
    width: calc(100% + 40px);
    top: 10px;
    z-index: 89;
    background-color: var(--color-grey-main-1);
    transform: translateX(-20px);
    padding: 0 20px;
}

.combi__title_sticky {
    width: 100%;
    min-height: 40px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 30px;
    z-index: 88;
    border-bottom: 1px solid rgba(211, 211, 211, 0.6);
}

.combi__title_sticky_img_show {
    opacity: 0.5 !important;
    pointer-events: auto !important;
}

.combi__title_sticky_img {
    width: 40px;
    position: absolute;
    left: calc(50% - 20px);
    bottom: -18px;
    opacity: 0;
    transition: all 0.3s ease;
    cursor: pointer;
    pointer-events: none;
}

.combi__title_sticky_img:hover {
    opacity: 1 !important;
}

.combi__title_sticky_img>img {
    width: 100%;
}

.combi_title_container {
    width: calc(100% + 40px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 0px 20px;
    background-color: var(--color-grey-main-1);
}

.app__combi_zone_menu_container {
    width: calc(100% + 40px);
    background-color: var(--color-grey-main-1);
    padding: 0px 20px;
}

.app__combi_zone_menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding-bottom: 1px;
    font-size: 12px;
    font-weight: 600;
    /* border-bottom: 1px solid rgba(211, 211, 211, 0.6); */
}

.app__combi_zone_menu_title {
    color: grey;
    transition: all 0.2s ease;
    cursor: pointer;
    position: relative;
}

.app__combi_zone_menu_title_selected {
    color: var(--color-blue-main-0);
}

.app__combi_zone_menu_indicator {
    width: 100px;
    height: 2px;
    background-color: var(--color-blue-main-0);
    position: absolute;
    left: 0px;
    bottom: 0px;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
}

/* SELECTED */

.buildable__selected_container_placeholder {
    background-color: transparent;
    border-radius: 0.7rem;
    border: 1px dashed grey;
    width: 100%;
    height: 200px;
    padding: 10px 10px 10px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    font-size: 13.5px;
}

.buildable__selected_container {
    position: relative;
    background-color: #fff;
    border-radius: 0.7rem;
    border: 1px solid #FFF;
    box-shadow: 0 0 14px rgb(100 100 100 / 10%);
    width: 100%;
    height: 200px;
    padding: 10px 10px 0px 12px;
    display: flex;
    justify-content: space-between;
}

.buildable__selected_close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 12px;
    height: 12px;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.2s ease;
}

.buildable__selected_close:hover {
    opacity: 1;
}

.buildable__selected_close>img {
    height: 100%;
}

.buildable__selected_title {
    font-size: 17px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}

.buildable__selected_info {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.buildable__selected_menu {
    /* background-color: aqua; */
    position: relative;
    max-width: 100%;
    height: 23px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    overflow-x: auto;
    overflow-y: visible;
    margin-bottom: 4px;
    /* overflow-y: hidden; */
    white-space: nowrap;
}

.buildiable__selected_menu_border {
    position: absolute;
    top: 18px;
    height: 0px;
    width: 100%;
    min-width: 100%;
    border-bottom: 1px solid lightgrey;
}

.buildable__selected_menu_item {
    font-size: 14px;
    line-height: 15px;
    font-weight: 600;
    color: grey;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.buildable__selected_menu_item_selected {
    /* font-weight: 700; */
    color: #062134;
}

.buildable__selected_menu_indicator {
    background-color: #062134;
    height: 2px;
    width: 33px;
    border-radius: 2px;
    position: absolute;
    top: 16px;
    left: 0px;
    transition: all 0.2s ease-in-out;
}

.buildable__selected_section_container {
    /* border: 1px solid red; */
    width: 100%;
    flex: 1;
    display: flex;
    overflow-x: hidden;
}

.buildable__selected_section {
    /* border: 1px solid blue; */
    min-width: 100%;
    min-height: 100%;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.buildable__selected_section_text_container {
    width: 99%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    position: relative;
}

.buildable__selected_section_optim_container {
    margin-top: 12px;
    width: 100px;
    height: 100px;
    /* background-color: aqua; */
    position: relative;
}

.buildable__selected_section_optim_text_container {
    position: absolute;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.buildable__selected_section_optim_text {
    font-size: 28px;
    line-height: 26px;
    font-weight: 700;
    letter-spacing: -1.5px;
    color: #062134;
    cursor: help;
}

.buildable__selected_section_optim_text_legend {
    font-size: 10px;
    line-height: 9px;
    font-weight: 600;
    color: grey;
    cursor: help;
}

.buildable__selected_section_other_container {
    flex: 1;
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;
}

.buildable__selected_section_other_subcontainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buildable__selected_section_other_kpi_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 36%;
}

.buildable__selected_section_other_kpi_value {
    font-weight: 700;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
}

.buildable__selected_section_other_kpi_legend {
    font-size: 9px;
    line-height: 9px;
    font-weight: 600;
    color: grey;
    text-align: center;
    margin-top: 4px
}

.buildable__selected_section_text_box {
    display: flex;
    flex-direction: column;
}

.buildable__selected_section_text {
    line-height: 16px;
    margin-bottom: 6px;
}

.buildable__selected_section_text_info {
    line-height: 11px;
    font-size: 11px;
}

.buildable__selected_section_text_value {
    line-height: 15px;
    font-size: 15px;
    font-weight: 700;
}



.buildable__selected_svg {
    width: 40%;
    min-width: 40%;
    height: calc(100% - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.buildable__selected_svg>svg {
    display: block;
    width: 100%;
    max-height: 100%;
    aspect-ratio: 1;
}

.buildable__selected_section_table_container {
    max-width: 99%;
    /* background-color: #d95154; */
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.buildable__selected_section_table_container::-webkit-scrollbar {
    display: none;
}

.buildable__selected_section_table_header {
    display: flex;
    background-color: #062134;
    color: #FFF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    width: 99%;
}

.buildable__selected_section_table_header_col {
    padding-left: 6px;
    font-size: 11px;
    margin: 2px 0;
}

.buildable__selected_section_table {
    display: flex;
    border-bottom: solid 1px #062134;
    border-left: solid 1px #062134;
    border-right: solid 1px #062134;
    width: 100%;
}

.buildable__selected_section_table:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-bottom: 10px;
}

.buildable__selected_section_table_col {
    font-size: 12px;
    padding-left: 6px;
}





/* SCROLLBAR */
.buildable__selected_menu::-webkit-scrollbar {
    width: 0px;
    height: 4px;
}

.buildable__selected_menu::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: rgba(100, 100, 100, 0);
    transition: 0.2s ease;

}

.buildable__selected_menu:hover::-webkit-scrollbar-track {
    background-color: rgba(100, 100, 100, 0.2);
}

.buildable__selected_menu::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(100, 100, 100, 0);
    transition: 0.2s ease;

}

.buildable__selected_menu:hover::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.5);
}


/* WIDGET MESSAGE */


.app__sectiondata_widget_message_info {
    position: absolute;
    right: 6px;
    top: 6px;
    z-index: 85;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    background: #d95154;
    color: #ffffff;
    /* background: #99c7ce; */
    /* background: #007384; */
    /* width: 100%; */
    width: 20px;
    min-height: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 1px 1px #0000001a;
    animation: pulse-animation 3s infinite;
    transition: all 0.4s 0.2s ease-in-out;
}

.app__sectiondata_widget_message_info:hover {
    width: calc(100% - 12px);
    /* min-height: 100%; */
    animation: none;
    transition: width 0.2s ease-in-out, min-height 0.2s 0.2s ease-in-out;
}

/* @keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(125, 35, 38, 0.2);
    }

    60% {
        box-shadow: 0 0 0 10px rgba(125, 35, 38, 0);
    }

    100% {
        box-shadow: 0 0 0 10px rgba(125, 35, 38, 0);
    }
} */

.app__sectiondata_widget_message_info_ico {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 20px;
    max-height: 20px;
    overflow: hidden;
    opacity: 1;
    transition: all 0.4s 0.7s ease-in-out;
}

.app__sectiondata_widget_message_info_ico>div {
    margin-bottom: 4px;
}

.app__sectiondata_widget_message_info:hover>div.app__sectiondata_widget_message_info_ico {
    max-width: 0px;
    max-height: 0px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.app__sectiondata_widget_message_info_text {
    /* position: absolute; */
    padding: 0px;
    /* color: #7d2326; */
    /* color: #007384; */
    font-size: 12px;
    line-height: 13px;
    font-weight: 500;
    overflow: hidden;
    max-width: 100%;
    max-height: 0px;
    opacity: 0;
    transition: all 0.4s 0.2s ease-in-out, max-height 0.3s ease-in-out, opacity 0.2s ease-in-out;
}

.app__sectiondata_widget_message_info:hover>div.app__sectiondata_widget_message_info_text {
    /* max-width: 100%; */
    max-height: 400px;
    padding: 8px 6px;
    opacity: 1;
    transition: padding 0s 0.2s ease-in-out, max-height 0.2s 0.2s ease-in-out, opacity 0.2s 0.4s ease-in-out;
}

.app__sectiondata_widget_message_info_text_title {
    font-size: 13px;
    font-weight: 900;
    margin-bottom: 5px;
}




@media screen and (max-width: 550px) {
    .combi_title_container {
        flex-direction: column;
        align-items: flex-start;
    }

    .combi_title_container>div.app__sectiondata_widget_title:first-child {
        margin-bottom: 4px;
    }

    .combi__grid {
        width: 100%;
        display: flex;
        gap: 10px;
        flex-direction: column;

    }

    .combi__grid_container {
        display: flex;
        width: calc(100% + 20px);
        left: 0px;
        padding-left: 0px;
    }

    .buildable__selected_section_other_container {
        margin-left: 0px;
    }
}