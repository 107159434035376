/* SECTION SEPARATOR */
.dashboard__sectionseparator {
    /* background-color: aqua; */
    height: 30px;
    width: 180px;
    position: fixed;
    top: 50px;
    left: 100px;
}

/* SECTION DATA */
.dashboard__sectiondata {
    /* background-color: red; */
    height: calc(100vh - 80px);
    position: fixed;
    left: var(--width-navbar);
    top: 80px;
    transition: opacity 0.4s ease;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    width: calc(100vw - var(--width-navbar));
}

.dashboard__sectiondata_container {
    /* background-color: violet; */
    width: 100%;
    height: auto;
}

/* SECTION DATA SCROLL BARS */
.dashboard__sectiondata::-webkit-scrollbar,
.dashboard__sectiondata_container::-webkit-scrollbar {
    width: 6px;
    height: 0px;
    background-color: var(--color-grey-main-1);
}

.dashboard__sectiondata::-webkit-scrollbar-track,
.dashboard__sectiondata_container::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #d1d1d1;
}

.dashboard__sectiondata::-webkit-scrollbar-thumb,
.dashboard__sectiondata_container::-webkit-scrollbar-thumb {
    border-radius: 3px;
    /* background-color: var(--color-blue-main-1); */
    background-color: grey;
}

/* SECTION DATA TITLE */
.dashboard__sectiondata_title_container {
    margin-bottom: 20px;
    padding: 0px 60px;
    height: 30px;
    background-color: var(--color-grey-main-1);
    position: sticky;
    top: 0px;
    z-index: 90;
}

.dashboard__sectiondata_title_container h3 {
    font-family: var(--font-family-Manrope);
    color: var(--color-blue-main-1);
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
    letter-spacing: 0.04em;
}

.dashboard__sectiondata_title_bar {
    background-color: var(--color-green-main-1);
    height: 4px;
    width: 30px;
    margin: 10px 0;
}

/* SECTION DATA BODY */
.dashboard__sectiondata_body_container {
    /* background-color: aquamarine; */
    padding: 0px 60px 20px 60px;
    margin-bottom: 50px;
    height: auto;
    min-height: calc(100vh - 180px);
}

.dashboard__sectiondata_container:last-of-type div.dashboard__sectiondata_body_container {
    margin-bottom: 0px;
    min-height: calc(100vh - 110px);
    /* min-height: calc(100vh - 90px); */
}

.dashboard__container_dark {
    background-color: var(--color-blue-main-1) !important;
    overflow: hidden;
}

.dashboard__container_body {
    position: relative;
}

.dashboard__container_img {
    height: 150px;
    /* height: 100%; */
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 1rem;
    overflow: hidden;
}

.dashboard__container_img>img {
    height: 100%;
    position: relative;
    border-radius: 1rem;
}

.dashboard__container_img_logo {
    height: 110px !important;
    margin: 20px 20px 0 0;
    -webkit-filter: grayscale(100%) invert(100%);
    filter: grayscale(100%) invert(100%);
}


/* USER DATA */

.dashboard__user_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dashboard__value_container {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.dashboard__value_text {
    font-size: 60px;
    line-height: 58px;
    font-weight: 700;
}

.dashboard__value_unit {
    font-weight: 600;
    line-height: 12px;
}

/* TEAM DATA */

.dashboard__team_name {
    color: #FFF;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 13px;
}

.dashboard__team_preview_list {
    display: flex;
    position: relative;
}

.dashboard__team_preview {
    position: absolute;
    height: 36px;
    width: 36px;
    border-radius: 36px;
    overflow: hidden;
    border: 1px solid #FFF;
}

.dashboard__team_preview>img {
    height: 100%;
}

.dashboard__team_member_list {
    min-height: 194px;
    max-height: 194px;
    overflow-y: auto;
}

.dashboard__team_member_container {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 10px;
    padding: 10px 0px;
    border-top: 1px solid lightgrey;
}

.dashboard__team_member_container:first-child {
    border-top: 1px solid #FFF;
}

.dashboard__team_member_img {
    height: 30px;
    width: 30px;
    min-width: 30px;
    border-radius: 30px;
    overflow: hidden;
}

.dashboard__team_member_img>img {
    height: 100%;
}

.dashboard__team_member_text {
    font-weight: 600;
}

.dashboard__team_member_col0 {}

.dashboard__team_member_col1 {
    min-width: 150px;
    width: 30%;
}

.dashboard__team_member_col2 {
    flex: 1;
}

.dashboard__team_member_col3 {
    width: 100px;
    text-align: right;
}


.dashboard__team_stats_container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex: 1;
}

.dashboard__team_stats_data_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    /* padding-bottom: 20px; */
    flex: 0.7;
}

.dashboard__team_stats_data_subcontainer {
    display: flex;
    justify-content: space-between;
}

.dashboard__team_stats_map_container {
    /* background-color: #88FF9F; */
    flex: 1;
    min-height: 100px;
    min-width: 100px;
    max-width: 70%;
    border-radius: 0.6rem;
    overflow: hidden;
}


/* STUDY LIST */

.dashboard__study_list_container {
    height: calc(100% - 24px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 14px;
}

.dashboard__study_list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    flex: 1;
    overflow-y: hidden;
    height: 100%;
}

.dashboard__study_container {
    /* background-color: #FFF; */
    background: var(--gradient-blue-background);
    border-radius: 1rem;
    box-shadow: 0 0 14px rgb(100 100 100 / 10%);
    padding: 10px 14px;
    min-height: 80px;
    height: 100%;
    max-height: 120px;
    width: 100%;
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.dashboard__study_empty {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.dashboard__study_land_container {
    min-height: 60px;
    height: 100%;
    width: 60px;
    /* margin-left: 4px; */
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.dashboard__study_land_container>img {
    /* height: 100%; */
    width: 100%;
}

.dashboard__study_land_container>svg {
    height: 100%;
    /* width: 100%; */
}

.dashboard__study_info_title {
    font-size: 15px;
    font-weight: 600;
    line-height: 15px;
    margin-bottom: 6px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.dashboard__study_info_container {
    color: #FFF;
    display: flex;
    flex-direction: column;
}

.dashboard__study_new_container {
    width: 100%;
    height: 40px;
}

.dashboard__study_new_btn {
    background-color: #FFF;
    color: var(--color-green-main-1);
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    width: 100%;
    border-radius: 20px;
    border: 2px solid var(--color-green-main-1);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
}

.dashboard__study_new_btn:hover {
    background-color: var(--color-green-main-1);
    color: #fff;
}

/* body {
    background-color: var(--color-grey-main-1);
} */




.app__section_footer_mobile {
    height: 80px;
    display: none;
}



.app__section_hide {
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    min-height: 100px;
    background-color: rgba(245, 245, 245, 0.8);
    backdrop-filter: blur(5px);
    position: absolute;
    left: -10px;
    top: -10px;
    z-index: 80;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.app__section_hide_buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
}



/* SECTION 1 */
.app__section1_container {
    /* background-color: #88FF9F; */
    width: 100%;
    height: 100%;
    display: grid;
    /* grid-template-columns: 1fr 1.5fr 1fr; */
    grid-template-columns: minmax(1fr, auto) minmax(1.5fr, auto) minmax(1fr, auto);
    /* grid-template-rows: 1fr 1fr 1fr 1fr; */
    grid-template-rows: minmax(1fr, auto) minmax(1fr, auto) minmax(1fr, auto) minmax(1fr, auto);
    grid-gap: 1rem;
    grid-template-areas:
        "user_data team_data capa"
        "user_data team_list capa"
        "user_stats team_list capa"
        "user_stats team_list capa";
}

.app__section1_user_data {
    grid-area: user_data;
}

.app__section1_user_stats {
    grid-area: user_stats;
}

.app__section1_team_data {
    grid-area: team_data;
}

.app__section1_team_list {
    grid-area: team_list;
}

.app__section1_capa {
    grid-area: capa;
}

.app__widget {
    background-color: #fff;
    border-radius: 1rem;
    -webkit-box-shadow: 0 0 14px rgba(100, 100, 100, 0.1);
    box-shadow: 0 0 14px rgba(100, 100, 100, 0.1);
    padding: 20px;
}

/* DASHBOARD */
.app__section_dashboard {
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: 100%;
    width: 100%;
}

.app__section_body_col12 {
    display: flex;
    gap: 20px;
    height: 100%;
    width: 75%;
}

.app__section_body_col1 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    width: 40%;
    max-width: 400px;
    min-width: 290px;
}

.app__section_body_col1_row1 {}

.app__section_body_col1_row2 {
    min-height: 150px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.app__section_body_col2 {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    /* width: 60%; */
    flex: 1;
}

.app__section_body_col2_row1 {
    height: 150px;
    min-height: 150px;
}

.app__section_body_col2_row2 {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.app__section_body_col3 {
    /* height: 100%; */
    width: 25%;
    flex: 1;
    position: relative;
}








/* USER DATA */
.app__userdata_avatar_container {
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    position: relative;
    /* justify-content: space-between; */
    /* align-items: center; */
}

.app__userdata_avatar_container:hover div.app__userdata_avatar {
    opacity: 0.2;
}

.app__userdata_avatar_container:hover div.app__userdata_avatar_container_buttons {
    visibility: visible;
    opacity: 1;
}

.app__userdata_avatar {
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.app__userdata_avatar img {
    width: 100%;
    max-width: 200px;
    aspect-ratio: 1;
    border-radius: 50%;
    margin: 4px 0px;
    object-fit: cover;
}

.app__userdata_avatar_container_buttons {
    background-color: transparent;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.1s, opacity 0.3s ease;
    backdrop-filter: blur(4px);
}

.dashboard__barchart_container {
    width: 100%;
    height: calc(100% - 116px);
    max-height: 180px
}



/* .app__userdata_avatar_container img {
    width: 50%;
    max-width: 120px;
    height: 50%;
    border-radius: 50%;
    margin-bottom: 10px;
} */



.app__userdata_avatar_container button {
    font-family: var(--font-family-Manrope);
    font-size: 14px;
    /* color: #fff; */
    width: 100%;
    padding: 2px 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
}

.app__userdata_avatar_container label {
    font-family: var(--font-family-Manrope);
    font-size: 13px;
    color: #fff;
    /* width: 100%; */
    padding: 5px 10px;
    margin-top: 5px;
    background-color: var(--color-blue-main-0);
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    text-align: center;
}

.app__userdata_avatar_container input {
    display: none;
}

.app__userdata_info_container {
    /* background-color: red; */
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.app__userdata_info_subcontainer {
    /* background-color: red; */
    width: 100%;
    display: flex;
    gap: 10px;
}


/* FILTER */

.app__section_filter_container {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 20px;
    position: relative;
}


/* CARD */

.app__section_card_container_space {
    display: grid;
    grid-template-columns: repeat(auto-fit, 260px);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    justify-content: space-between;
    position: relative;
}

.app__section_card_container_left {
    display: grid;
    grid-template-columns: repeat(auto-fit, 260px);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    justify-content: flex-start;
    position: relative;
}

.app__card_container {
    background-color: white;
    border-radius: 1rem;
    height: 340px;
    width: 260px;
    /* width: 100%; */
    overflow: hidden;
    -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}

.app__card_container h5 {
    font-size: 17px;
    line-height: 19px;
    font-weight: 700;
}



/* CARD BOTTOM ZONE */

.app__card_bottom_container {
    /* background-color: red; */
    height: 65%;
    padding: 10px 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.app__card__bottom_body {
    display: flex;
    flex-direction: column;
    /* background-color: beige; */
    width: 100%;
}

.app__card_bottom_tags {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3px;
    width: 100%;
    flex: 1;
}

.app__card_bottom_tags span {
    /* color: var(--color-blue-main-1); */
    color: white;
    font-size: 11px;
    padding: 2px 6px;
    margin-right: 6px;
    /* border: 1px var(--color-blue-main-1) solid; */
    border-radius: 5px;
    background-color: var(--color-green-main-1);

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.app__card_bottom_title {
    margin: 8px 0 6px 0;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.app__card_bottom_description {
    font-size: 13px;
    white-space: pre-line;
}

.app__card_bottom_footer {
    /* background-color: #6FFEDB; */
    height: 30px;
    width: 100%;
    display: flex;
}

.app__card_bottom_footer_userpp {
    display: flex;
    align-items: center;
}

.app__card_bottom_footer_userpp img {
    margin-right: 6px;
    height: 24px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
}

.app__card_bottom_footer_userinfos {
    /* background-color: blueviolet; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.app__card_bottom_footer_userinfos_name {
    font-size: 12px;
    line-height: 12px;
    font-weight: 800;
}

.app__card_bottom_footer_userinfos_time {
    font-size: 10px;
    line-height: 10px;
}

/* LOADING */
.dashboard__sectiondata_loading_container {
    height: calc(100vh - 180px);
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* RESPONSIVE */

@media screen and (max-width: 1050px) {
    .app__section1_container {
        grid-template-columns: minmax(1fr, auto) minmax(1.5fr, auto);
        grid-template-rows: minmax(1fr, auto) minmax(1fr, auto) minmax(1fr, auto) minmax(1fr, auto) minmax(2fr, auto);
        grid-gap: 1rem;
        grid-template-areas:
            "user_data team_data"
            "user_data team_list"
            "user_stats team_list"
            "user_stats team_list"
            "capa capa"
            "capa capa";
    }

    .app__section_dashboard {
        flex-direction: column;
    }

    .app__section_body_col12 {
        width: 100%;
        height: auto;
    }

    .app__section_body_col1 {
        height: auto;
    }

    .app__section_body_col2 {
        height: auto;
    }

    .app__section_body_col3 {
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 700px) {}

@media screen and (max-width: 550px) {
    .app__section1_container {
        grid-template-columns: 1fr;
        grid-template-rows: minmax(1fr, auto) minmax(1fr, auto) minmax(0.5fr, auto) minmax(2fr, auto) minmax(2fr, auto);
        grid-gap: 1rem;
        grid-template-areas:
            "user_data"
            "user_stats"
            "team_data"
            "team_list"
            "capa";
    }

    .app__section {
        height: fit-content;
    }

    .app__section_footer_mobile {
        display: block;
    }

    .app__section_body_container {
        padding: 0;
    }

    .app__section_body_col12 {
        flex-direction: column;
        height: auto;
    }

    .app__section_body_col1 {
        width: 100%;
        height: auto;
    }

    .app__section_body_col2 {
        width: 100%;
        height: auto;
    }

    .app__section_body_col2 {
        width: 100%;
        height: auto;
    }

    .app__section_card_container_space {
        justify-content: space-around;
    }

    .app__section_card_container_left {
        justify-content: space-around;
    }

    .dashboard__value_text {
        font-size: 50px;
        line-height: 48px;
        font-weight: 700;
    }

    .dashboard__barchart_container {
        width: 100%;
        height: 150px;
    }
}



/* USER DATA */
.user_container {
    /* background-color: red; */
    width: 100%;
    height: 200px;
}

.user_avatar_container {
    background-color: #88FF9F;
    width: 100%;
    height: 200px;
    display: flex;
}

.user_avatar_container:hover div.user_avatar_controls {
    width: 50%;
    opacity: 1;
}


.user_avatar_image {
    background-color: green;
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user_avatar_image img {
    width: 100%;
    max-width: 200px;
    border-radius: 50%;
}

.user_avatar_controls {
    overflow: hidden;
    width: 0%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
}


.user_avatar_controls button {
    font-family: var(--font-family-Manrope);
    font-size: 14px;
    line-height: 16px;
    /* color: #fff; */
    width: 100%;
    padding: 2px 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
}

.user_avatar_controls span {
    font-family: var(--font-family-Manrope);
    font-size: 13px;
    color: grey;
}

.user_avatar_controls label {
    font-family: var(--font-family-Manrope);
    font-size: 13px;
    color: #fff;
    /* width: 100%; */
    padding: 5px 10px;
    margin-top: 5px;
    background-color: #062134;
    border: none;
    border-radius: 1rem;
    cursor: pointer;
    text-align: center;
}

.user_avatar_controls input {
    display: none;
}













/* RESPONSIVE */

@media screen and (max-width: 1340px) {
    .app__section_body_col3 {
        display: none;
    }

    .app__section_body_col12 {
        width: 100%;
    }
}


@media screen and (max-width: 700px) {
    .dashboard__sectiondata_title_container {
        padding: 0 30px;
    }

    .dashboard__sectiondata_body_container {
        padding: 0px 30px 20px 30px;
    }
}


@media screen and (max-width: 550px) {

    .dashboard__user_container {
        flex-direction: row;
    }

    .app__userdata_info_container {
        justify-content: space-around;
    }

    .app__userdata_info_subcontainer {
        flex-direction: column;
    }

    .dashboard__team_member_list {
        min-height: auto;
    }

    /* SECTION DATA */
    .dashboard__sectiondata {
        /* width: 100%;
        height: calc(50vh - 110px);
        left: 0px; */
        /* HORIZONTAL SCROLL */
        position: fixed;
        width: 100vw;
        left: 0px;
        top: 80px;
        height: calc(100vh - 160px);
        flex-direction: row;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        overflow-y: hidden;
    }

    .dashboard__sectiondata_container {
        /* HORIZONTAL SCROLL */
        min-width: 100vw;
        overflow-y: auto;
        scroll-snap-align: center;
    }

    .dashboard__sectiondata_title_container {
        padding: 0 20px;
        top: 0px;
    }

    .dashboard__sectiondata_body_container {
        margin-bottom: 0px;
        padding: 0px 20px 20px 20px;
        min-height: calc(100vh - 180px - 80px) !important;
    }

    .dashboard__sectiondata_loading_container {
        height: calc(100vh - 180px - 80px);
    }


    /* SECTION SEPARATOR */
    .dashboard__sectionseparator {
        left: calc(50vw - 90px);
    }


    /* SECTION MAP */
    .dashboard__sectionmap {
        left: 0px;
        height: calc(50vh - 80px);
        width: 100vw;
    }

    /* FILTER */
    .app__section_filter_container {
        display: flex;
        justify-content: center;
    }
}