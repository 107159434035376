.auth__auth {
    background: var(--gradient-blue-background);
    height: 100vh;
}

.auth__header {
    padding: 1rem 6rem;
    width: 100%;
    height: 97px;
    /* background: red; */
}

.auth__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.auth__header-logo {
    /* background-color: green; */
    cursor: pointer;
}

.auth__header-logo_box img {
    width: 60px;
}

.auth__header-close {
    /* background: aqua; */
}

.auth__body_container {
    /* background-color: red; */
    /* background-color: var(--color-blue-secondary-2); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 100px);
    width: 100%;
    /* position: absolute; */
    /* left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
}

.auth__body_container_visible {
    visibility: visible;
    opacity: 1;
    margin-top: 0px;
    transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.auth__body_container_hidden {
    visibility: hidden;
    opacity: 0;
    margin-top: 0px;
    transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.auth__cause {
    color: #FF5964;
    font-size: 12px;
    font-weight: 800;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.auth__cause_ico {
    font-size: 16px;
}

.auth__footer {
    width: 100%;
    color: var(--color-green-main-1);
    font-size: 8.5px;
    font-weight: 700;
    margin: 10px 0;
    text-align: center;
}



/* RESPONSIVE */

@media screen and (max-width: 1050px) {}

@media screen and (max-width: 700px) {
    .auth__header {
        padding: 1rem 3rem;
    }
}

@media screen and (max-width: 550px) {
    .auth__header {
        display: none;
    }

    .auth__body_container {
        height: 100%;
    }
}