.app__header_indicators_container_main {
    display: flex;
    justify-content: space-between;
    width: calc(100vw - 100px);
    position: relative;
}

.app__header_indicators_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.app__header_indicators_element {
    background-color: var(--color-blue-secondary-2);
    border-radius: 8px;
    /* height: 80px; */
    width: 20%;
    padding: 20px 14px;
    /* box-shadow: 0 0 14px rgba(200, 200, 200, 0.1); */
}

.app__header_indicators_element_green {
    /* background: var(--gradient-green-bar); */
    /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
    background: linear-gradient(-45deg, var(--color-green-secondary-1), var(--color-green-secondary-2), var(--color-green-secondary-1));
    background-size: 400% 400%;
    border-radius: 8px;
    /* height: 80px; */
    width: 34%;
    padding: 20px 14px;
    color: var(--color-blue-main-0);
    display: flex;
    justify-content: space-between;
    animation: gradient_anim 15s ease infinite;
}

@keyframes gradient_anim {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.app__header_indicators_subelement {
    width: 48%;
}

.app__header_indicators_element_title {
    color: lightgrey;
    font-weight: 600;
    font-size: 13px;
}

.app__header_indicators_element_title_green {
    color: var(--color-blue-main-0);
    font-weight: 700;
    font-size: 13px;
}

.app__header_indicators_element_value {
    margin-top: 14px;
    font-size: 40px;
    line-height: 40px;
    font-weight: 600;
}

.app__header_indicators_element_value_green {
    margin-top: 14px;
    font-size: 46px;
    line-height: 40px;
    font-weight: 700;
}

.app__header_indicators_element_value_green_small {
    margin-top: 14px;
    font-size: 22px;
    line-height: 40px;
    font-weight: 700;
}

.app__header_indicators_element_subvalue {
    margin-top: 14px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
}



/* RESPONSIVE */

@media screen and (max-width: 900px) {

    .app__header_indicators_container {
        width: fit-content;
        overflow-x: auto;
    }

    .app__header_indicators_container_main {
        width: 100vw;
        left: -50px;
        justify-content: flex-start;
        overflow-x: auto;
    }

    .app__header_indicators_element_green {
        margin-left: 50px;
        width: 40vw;
        min-width: 40vw;
    }

    .app__header_indicators_container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 20px;
    }

    .app__header_indicators_element {
        width: 40vw;
        min-width: 40vw;
    }
}

@media screen and (max-width: 550px) {

    .app__header_indicators_container_main {
        width: 100vw;
        left: -20px;
    }

    .app__header_indicators_element_green {
        margin-left: 20px;
        width: calc(100vw - 40px);
        min-width: calc(100vw - 40px);
    }

    .app__header_indicators_element {
        width: calc(100vw - 40px);
        min-width: calc(100vw - 40px);
    }

    .app__header_indicators_element:last-child {
        margin-right: 20px;

    }
}