.app__admin__container {
    background: var(--gradient-blue-background);
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    color: #fff;
    padding: 100px 50px 0px;
    position: relative;
}

.app__admin_title {
    font-size: 17px;
    font-weight: 700;
    margin: 0px 0px 10px 0px;
}

.app__admin_detail_container {
    /* background-color: orange; */
    width: 100%;
    height: calc(100vh - 260px);
    /* overflow: hidden; */
    /* flex: 1; */
    margin-top: 26px;
    display: flex;
    gap: 2%;
    position: relative;
}

.app__admin_detail_teams_container {
    /* background-color: aquamarine; */
    width: calc(56% + 8px);
    height: 100%;
    position: relative;
}

.app__admin_detail_other_container {
    /* background-color: brown; */
    width: calc(42% - 8px);
}

.app__admin_detail_users_container {
    /* background-color: beige; */
    width: 100%;
    height: calc(50% - 36px);
    margin-bottom: 36px;
    position: relative;
}

.app__admin_detail_capas_container {
    /* background-color: brown; */
    width: 100%;
    height: 50%;
}




/* LOADING */
.app__admin_loading_container {
    position: absolute;
    top: 80;
    left: 0;
    width: 100vw;
    height: calc(100vh - 180px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__admin_loading_modal {
    background-color: var(--color-blue-secondary-2);
    border-radius: 8px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 18px;
}

.app__admin_loading_title {
    font-size: 16px;
    line-height: 17px;
    font-weight: 600;
    margin-bottom: 12px;
    text-align: center;
}

.app__admin_loading_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__admin_loading_item_circle {
    height: 12px;
    width: 12px;
    border-radius: 12px;
    border-top: 2px solid var(--color-green-main-1);
    border-left: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    margin-right: 10px;
    animation: rotating 1s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__admin_loading_item_done {
    height: 12px;
    width: 12px;
    border-radius: 12px;
    border: 2px solid var(--color-green-main-1);
    background-color: var(--color-green-main-1);
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__admin_loading_item_circle>img,
.app__admin_loading_item_done>img {
    height: 8px;
}


.app__admin_detail_selection_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.app__admin_detail_selection_subcontainer {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;

    background-color: var(--color-blue-secondary-2);
    border-radius: 20px;
    /* height: 26px; */
    width: auto;
    padding: 6px 14px;
    position: relative;

    font-size: 13px;
    font-weight: 600;
}

.app__admin_detail_selection {
    z-index: 31;
    color: lightgrey;
    transition: all 0.2s ease;
}

.app__admin_detail_selection_selected {
    color: #fff;
}

.app__admin_detail_selector {
    height: 30px;
    width: 50px;
    border-radius: 20px;
    background-color: rgba(100, 100, 100, 0.3);

    z-index: 30;

    position: absolute;
    top: 0;
    left: 0;

    transition: all 0.2s ease;
}


/* RESPONSIVE */

@media screen and (max-width: 1200px) {

    .app__admin_detail_container {
        flex-direction: column;
        margin-top: 16px;
        max-height: calc(100vh - 310px);
        overflow-y: hidden;
    }

    .app__admin_detail_teams_container {
        width: 100%;
    }

    .app__admin_detail_other_container {
        width: 100%;
        height: 100%;
    }

    .app__admin_detail_capas_container {
        height: calc(100vh - 330px);
    }

    .app__admin_detail_users_container {
        height: 100%;
    }
}

@media screen and (max-width: 550px) {

    .app__admin_loading_modal {
        max-width: calc(100vw - 40px);
    }

    .app__admin__container {
        padding: 100px 20px 0px;
    }
}