@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@200;300;400;500;600;700;800&display=swap');

:root {
    /* FONTS */
    --font-family-Manrope: 'Manrope', sans-serif;
    --font-family-Roboto: 'Manrope', sans-serif;
    --font-family-Prompt: 'Prompt', sans-serif;

    /* COLORS */
    --color-green-main-1: #1ecd97;
    --color-green-secondary-1: #88ff9f;
    --color-green-secondary-2: #6ffedb;

    --color-blue-main-0: #062134;
    --color-blue-main-1: #040C18;
    --color-blue-main-2: #031B34;
    --color-blue-main-3: #042c54;
    --color-blue-secondary-1: #81AFDD;
    --color-blue-secondary-2: #1D2E3C;

    --color-grey-main-1: #F5F5F5;

    /* GRADIENTS */
    --gradient-green-text: linear-gradient(90deg, var(--color-green-secondary-1) 2%, var(--color-green-secondary-2) 100%);
    --gradient-green-bar: linear-gradient(100deg, var(--color-green-secondary-1) -15%, var(--color-green-secondary-2) 100%);

    --gradient-blue-background: radial-gradient(circle at 70% 90%, var(--color-blue-main-0) 0%, var(--color-blue-main-1) 100%);
    --gradient-blue-background2: radial-gradient(circle at 70% 90%, var(--color-blue-main-0) 0%, var(--color-blue-main-3) 100%);

    /* GEOMETRY */
    --width-navbar: 60px;
    --width-section: 620px;

    --height-header: calc(20px + var(--width-navbar));

    /* BREAK POINTS */
    --breakpoint-tablet: 700px;
    --breakpoint-phone: 550px;
}