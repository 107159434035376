/* SECTION DATA */
.app__sectiondata {
    background-color: var(--color-grey-main-1);
    height: calc(100vh - 80px);
    position: fixed;
    left: var(--width-navbar);
    top: 80px;
    transition: opacity 0.4s ease;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    /* TO GET SCROLL BAR ON RIGHT OF SCREEN */
    width: calc(100vw - var(--width-navbar));
}

.app__sectiondata_UI {
    /* background-color: rgba(255, 166, 0, 0.411); */
    height: calc(100vh - 80px);
    position: fixed;
    left: calc(var(--width-navbar) + 20px);
    top: 90px;
    transition: opacity 0.4s ease;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    /* TO GET SCROLL BAR ON RIGHT OF SCREEN */
    width: calc(var(--width-section) + 30px);
    z-index: 2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 15px 15px 0px 15px;
    transform: none;
    /* opacity: 1 !important; */
    opacity: 1;
    transition: all 0.8s;
}

.app__sectiondata_hidden_UI {
    transform: translateX(-630px);
    opacity: 0 !important;
}

.app__section_data_disabled {
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
}

.app__sectiondata_container {
    /* background-color: violet; */
    /* width: 100%; */
    height: auto;
    /* TO GET SCROLL BAR ON RIGHT OF SCREEN */
    width: var(--width-section);
}

.app__sectiondata_container_UI {
    background-color: rgba(245, 245, 245, 1);
    /* backdrop-filter: blur(10px); */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(100, 100, 100, 0.1);
    /* width: 100%; */
    height: auto;
    /* TO GET SCROLL BAR ON RIGHT OF SCREEN */
    width: calc(var(--width-section) + 0px);
    margin-bottom: 50px;

    display: flex;
    flex-direction: column;
    position: relative;
}

.app__sectiondata_container_UI:last-child {
    margin-bottom: 35px;
}

/* SECTION DATA SCROLL BARS */
.app__sectiondata::-webkit-scrollbar,
.app__sectiondata_container::-webkit-scrollbar {
    width: 6px;
    height: 0px;
    background-color: var(--color-grey-main-1);
}

.app__sectiondata_UI::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: var(--color-grey-main-1);
}

.app__sectiondata::-webkit-scrollbar-track,
.app__sectiondata_container::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #d1d1d1;
}

.app__sectiondata_UI::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #d1d1d1;
}

.app__sectiondata::-webkit-scrollbar-thumb,
.app__sectiondata_container::-webkit-scrollbar-thumb {
    border-radius: 3px;
    /* background-color: var(--color-blue-main-1); */
    background-color: grey;
}

.app__sectiondata_UI::-webkit-scrollbar-thumb {
    border-radius: 3px;
    /* background-color: var(--color-blue-main-1); */
    background-color: grey;
}

/* SECTION DATA TITLE */
.app__sectiondata_title_container {
    margin-bottom: 20px;
    padding: 0px 10px 0px 20px;
    height: 30px;
    width: calc(100% + 10px);
    background-color: var(--color-grey-main-1);
    position: sticky;
    top: 0px;
    z-index: 90;
}

.app__sectiondata_title_container_UI {
    margin-bottom: 20px;
    padding: 0px 10px 0px 20px;
    height: 45px;
    width: 100%;
    background-color: var(--color-grey-main-1);
    /* background-color: rgba(245, 245, 245, 0.95); */
    /* background-color: transparent; */
    /* backdrop-filter: blur(10px); */
    position: sticky;
    top: -15px;
    z-index: 90;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.app__sectiondata_title_subcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app__sectiondata_title_container:hover>div.app__sectiondata_title_subcontainer>div.app__sectiondata_title_reload {
    opacity: 1;
}

.app__sectiondata_title_subcontainer h3 {
    font-family: var(--font-family-Manrope);
    color: var(--color-blue-main-1);
    font-size: 16px;
    line-height: 16px;
    font-weight: 800;
    letter-spacing: 0.04em;
}


.app__sectiondata_title_reload {
    display: flex;
    align-items: center;
    position: relative;
}

/* .app__sectiondata_title_reload:hover,
.app__sectiondata_title_reload:hover>div.app__sectiondata_title_reload_text {
    opacity: 1;
} */

.app__sectiondata_title_reload_text {
    color: grey;
    font-size: 11px;
    line-height: 12px;
    font-weight: 500;
    /* margin-right: 10px; */
    min-width: 200px;
    text-align: right;
    opacity: 0;
    transition: all 0.2s ease;
    cursor: default;
    position: absolute;
    top: 2px;
    right: 22px;
}

.app__sectiondata_title_reload_img_loading {
    pointer-events: none !important;
    cursor: default !important;
    -webkit-animation: reload_spin 2s linear infinite;
    -moz-animation: reload_spin 2s linear infinite;
    animation: reload_spin 2s linear infinite;
    opacity: 1 !important;
}

.app__sectiondata_title_reload_img {
    height: 16px;
    cursor: pointer;
    opacity: 0.3;
    transition: all 0.2s ease;
}

.app__sectiondata_title_reload_img:hover,
.app__sectiondata_title_reload_img:hover~.app__sectiondata_title_reload_text {
    opacity: 1;
}

@-moz-keyframes reload_spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes reload_spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes reload_spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.app__sectiondata_title_bar {
    background-color: var(--color-green-main-1);
    height: 4px;
    width: 30px;
    margin-top: 6px;
}

.app__sectiondata_title_label {
    height: 20px;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.03em;
}

/* SECTION DATA BODY */
.app__sectiondata_body_container {
    /* background-color: aquamarine; */
    padding: 0px 0px 20px 20px;
    margin-bottom: 50px;
    height: auto;
    min-height: calc(100vh - 180px);
}

.app__sectiondata_body_container_UI {
    /* background-color: aquamarine; */
    padding: 0px 20px 20px 20px;
    height: auto;
    min-height: calc(100vh - 195px);
    display: flex;
    flex-direction: column;
    position: relative;
}

.app__sectiondata_container:last-of-type div.app__sectiondata_body_container {
    margin-bottom: 0px;
    min-height: calc(100vh - 130px);
}

/* .app__sectiondata_container_UI:last-of-type div.app__sectiondata_body_container_UI {
    min-height: calc(100vh - 170px);
} */


/* LOADING */
.app__sectiondata_loading_container {
    height: calc(100vh - 220px);
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__sectiondata_loading_container_full {
    height: calc(100vh - 150px);
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__sectiondata_loading_container_end {
    height: calc(100vh - 170px);
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__sectiondata_loading_container_box {
    height: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__sectiondata_loading_container_medium1 {
    height: calc(100vh - 180px - 170px);
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__sectiondata_loading_container_medium2 {
    height: calc(100vh - 400px);
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__sectiondata_loading_container_small {
    height: 300px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__sectiondata_loading_logo>img {
    opacity: 0.4;
    height: 100px;
}

.app__sectiondata_loading {
    /* background-color: red; */
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-top: 3px solid rgb(200, 200, 200);
    /* border-right: 3px solid var(--color-blue-main-0); */
    border-right: 3px solid var(--color-green-main-1);
    border-bottom: 3px solid rgb(200, 200, 200);
    border-left: 3px solid rgb(200, 200, 200);
    animation: rotating 2s linear infinite;
}

.app__sectiondata_loading_text {
    color: rgb(173, 172, 172);
    font-weight: 600;
    text-align: center;
}

/* WIDGET */

.app__sectiondata_widget_top {
    display: flex;
    align-items: flex-start;
}

.app__sectiondata_widget_bottom {
    display: flex;
    align-items: flex-end;
}

.app__sectiondata_widget_middle {
    display: flex;
    align-items: center;
}

/* SECTION DATA WIDGET LAYOUTS */
/* LAYOUTS A => COLUMNS */
.app__sectiondata_widget_layout_A_auto {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.app__sectiondata_widget_layout_A_auto_flex1 {
    flex: 1;
}

.app__sectiondata_widget_layout_A {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.app__sectiondata_widget_layout_A> :nth-child(1) {
    width: calc(50% - 10px);
}

.app__sectiondata_widget_layout_A> :nth-child(2) {
    width: calc(50% - 10px);
}

.app__sectiondata_widget_layout_A1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    position: relative;
}

.app__sectiondata_widget_layout_A1> :nth-child(1) {
    width: calc(34% - 10px);
    position: relative;
}

.app__sectiondata_widget_layout_A1> :nth-child(2) {
    width: calc(66% - 10px);
    position: relative;
}

.app__sectiondata_widget_layout_A2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.app__sectiondata_widget_layout_A2> :nth-child(1) {
    width: 66%;
}

.app__sectiondata_widget_layout_A2> :nth-child(2) {
    width: 34%;
}

.app__sectiondata_widget_layout_A3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.app__sectiondata_widget_layout_A3> :nth-child(1) {
    width: 40%;
}

.app__sectiondata_widget_layout_A3> :nth-child(2) {
    width: 60%;
}

/* LAYOUTS B => ROWS */
.app__sectiondata_widget_layout_B {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


/* SECTION DATA WIDGETS */
.app__sectiondata__widget {
    background-color: #fff;
    border-radius: 0.7rem;
    -webkit-box-shadow: 0 0 14px rgba(100, 100, 100, 0.1);
    box-shadow: 0 0 14px rgba(100, 100, 100, 0.1);
    padding: 14px;
    width: 100%;
    height: auto;
    /* min-height: 110px; */
}

.app__sectiondata__widget_params {
    padding: 10px 0px;
    width: 100%;
    height: auto;
}

.app__sectiondata__widget_warning {
    color: #DD1C1A;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* background-color: #CE8383; */
    background-color: #DD1C1A55;
    border-radius: 0.7rem;
    -webkit-box-shadow: 0 0 14px rgba(100, 100, 100, 0.1);
    box-shadow: 0 0 14px rgba(100, 100, 100, 0.1);
    padding: 14px;
    width: 100%;
    height: auto;
}

.app__sectiondata_widget_title_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.app__sectiondata_widget_title_subcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__sectiondata_widget_title {
    position: inherit;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: grey;
    margin: 0 0 10px 0;
}

.app__sectiondata_widget_title_dropdown {
    position: inherit;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: grey;
    border: none;
    background-color: transparent;
    margin-bottom: 8px;
    font-family: var(--font-family-Manrope);
    cursor: pointer;
    outline: none;
}

.app__sectiondata_widget_layer {
    display: flex;
    gap: 5px;
}

.app__sectiondata_widget_layer img {
    height: 17px;
    opacity: 0.5;
}

.app__sectiondata_widget_submenu_container {
    position: relative;
}

.app__sectiondata_widget_submenu_container>img {
    opacity: 1;
    cursor: pointer;
}

.app__sectiondata_widget_submenu {
    position: absolute;
    top: 18px;
    right: -2px;
    display: none;
    z-index: 89;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 14px rgba(100, 100, 100, 0.4);
    padding: 10px;
}

.app__sectiondata_widget_submenu_opened {
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: max-content;
}

.app__sectiondata_widget_submenu_placeholder {
    height: 18px;
    width: 100%;
    background-color: transparent;
    position: absolute;
    top: -18px;
    right: 0px;
}

.app__sectiondata_widget_submenu_item {
    font-size: 12px;
    font-weight: 600;
    opacity: 0.7;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.app__sectiondata_widget_submenu_item:hover {
    opacity: 1;
}

.app__sectiondata_widget_H1 {
    font-weight: 800;
    font-size: 20px !important;
}

.app__sectiondata_widget_spacer_none {
    /* height: 0px; */
    transition: height 0.4s ease;
}

.app__sectiondata_widget_spacer {
    min-height: 20px;
}

.app__sectiondata_widget_spacer_0 {
    min-height: 0px;
}

.app__sectiondata_widget_spacer_1 {
    min-height: 1px;
}

.app__sectiondata_widget_spacer_5 {
    min-height: 5px;
}

.app__sectiondata_widget_spacer_10 {
    min-height: 10px;
}

.app__sectiondata_widget_spacer_15 {
    min-height: 15px;
}

.app__sectiondata_widget_spacer_20 {
    min-height: 20px;
}

.app__sectiondata_widget_spacer_30 {
    min-height: 30px;
}

.app__sectiondata_widget_spacer_40 {
    min-height: 40px;
}

.app__sectiondata_widget_spacer_50 {
    min-height: 50px;
}

/* WIDGET VALUE */

.app__sectiondata_widget_value_container_horizontal {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.app__sectiondata_widget_value_text {
    font-size: 50px;
    line-height: 48px;
    font-weight: 600;
    letter-spacing: -2px;
}

.app__sectiondata_widget_value_text_medium {
    display: flex;
    align-items: center;
    font-size: 40px;
    line-height: 38px;
    font-weight: 600;
    letter-spacing: -2px;
}

.app__sectiondata_widget_value_text_small {
    font-size: 30px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: -1px;
}

.app__sectiondata_widget_value_text_xsmall {
    font-size: 20px;
    line-height: 18px;
    font-weight: 600;
}

.app__sectiondata_widget_value_legend {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
}

.app__sectiondata_widget_value_legend_medium {
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
}

.app__sectiondata_widget_value_legend_small {
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    font-style: italic;
}

.app__sectiondata_widget_inputSingleLine {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid transparent;
    background-color: transparent;
    outline: 0;
    font-family: inherit;
    font-size: inherit;
    transition: border-color 0.2s;
}

.app__sectiondata_widget_inputSingleLine:hover {
    border-bottom: 1px solid var(--color-blue-main-0);
}

.app__sectiondata_widget_inputSingleLine:focus {
    border-bottom: 1px solid var(--color-blue-main-0);
}

.app__sectiondata_widget_inputSingleLine:invalid {
    border-bottom: 1px solid red;
}

.app__sectiondata_widget_inputMultipleLine {
    width: 100%;
    height: auto;
    min-height: 20px;
    resize: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid transparent;
    background-color: transparent;
    outline: 0;
    font-family: inherit;
    font-size: inherit;
    transition: border-color 0.2s;
}

.app__sectiondata_widget_inputMultipleLine:hover {
    border-bottom: 1px solid var(--color-blue-main-0);
}

.app__sectiondata_widget_inputMultipleLine:focus {
    border-bottom: 1px solid var(--color-blue-main-0);
}

.app__sectiondata_widget_inputSingleLine:invalid {
    border-bottom: 1px solid red;
}

.app__sectiondata_widget_chart_container {
    /* width: 200px; */
    flex: 1;
}

/* WIDGET SCALE */
.app__sectiondata_widget_scale {
    width: 100%;
    display: flex;
    gap: 3px;
    /* justify-content: space-between; */
}

.app__sectiondata_widget_scale_item {
    border: 1px solid var(--color-blue-main-0);
    border-radius: 4px;
    padding: 4px 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0.5;
}

.app__sectiondata_widget_scale_item_main {
    border: 2px solid var(--color-blue-main-0);
    opacity: 1;
    box-shadow: 0 0 10px rgba(100, 100, 100, 0.7);
}

.app__sectiondata_widget_scale_item_title {
    font-weight: 500;
    line-height: 14px;
}

.app__sectiondata_widget_scale_item_description {
    font-size: 11px;
    line-height: 11px;
}

/* WIDGET LIST */
.app__sectiondata_list_item_body {
    padding: 10px 0px;
}

.app__sectiondata_list_line_separator {
    background-color: rgb(211, 211, 211);
    width: 100%;
    height: 1px;
}

/* WIDGET INFO */
.app__sectiondata_widget_info_container {
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.app__sectiondata_widget_info_icon {
    /* background-color: rgb(170, 170, 170); */
    background-color: #FFF;
    position: absolute;
    z-index: 9;
    right: 0px;
    width: 17px;
    height: 17px;
    /* color: var(--color-grey-main-1); */
    color: grey;
    font-size: 12px;
    line-height: 17px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 17px;
    cursor: default;
}

.app__sectiondata_widget_info_text {
    box-shadow: 0 0 14px rgb(100 100 100 / 10%);
    background-color: transparent;
    position: relative;
    z-index: 10;
    height: 17px;
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 17px;
    max-width: 17px;
    font-size: 11px;
    font-style: italic;
    line-height: 17px;
    overflow: hidden;
    color: transparent;
    padding: 0px 8px;
    cursor: default;
    transition: color 0.2s ease, max-width 0.4s ease 0.1s, background-color 0.2s ease 0.4s;
}

.app__sectiondata_widget_info_text:hover {
    /* background-color: rgb(170, 170, 170); */
    background-color: #FFF;
    /* color: var(--color-grey-main-1); */
    color: grey;
    max-width: 100%;
    transition: color 0.2s ease 0.2s, max-width 0.6s ease, background-color 0s ease;
}


/* SECTION SEPARATOR */
.app__sectionseparator {
    /* background-color: aqua; */
    background-color: var(--color-grey-main-1);
    height: 30px;
    width: 180px;
    position: fixed;
    top: 50px;
    left: calc(50vw - 60px);
    z-index: 10;
    /* transition: top 0.2s ease; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.app__sectionseparator_handler {
    height: 4px;
    width: 140px;
    border-radius: 2px;
    background-color: grey;
    cursor: n-resize;
    opacity: 0.6;
    position: absolute;
    top: 13px;
    transition: opacity 0.2s ease, translate 0.2s ease-in-out;
}

.app__sectionseparator_handler_bounce_up {
    animation: bounce_up 10s infinite ease;
}

.app__sectionseparator_handler_bounce_down {
    animation: bounce_down 10s infinite ease;
}

.app__sectionseparator_handler:hover {
    opacity: 1;
}

.app__sectionseparator_message {
    position: relative;
    font-size: 11px;
    line-height: 12px;
    overflow: hidden;
    user-select: none;
    height: 0px;
    color: grey;
    opacity: 0;
    transition: height 0.1s ease, opacity 0.2s ease;
}

.app__sectionseparator_message_bounce {
    animation: text_anim 10s infinite ease;
}

.app__sectionseparator_message_anim_top {
    background-color: var(--color-grey-main-1);
    width: 100%;
    height: 10%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    /* animation: go_down 10s infinite ease; */
}

.app__sectionseparator_message_anim_bottom {
    background-color: var(--color-grey-main-1);
    width: 100%;
    height: 10%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    /* animation: go_up 10s infinite ease; */
}

@keyframes bounce_up {
    0% {
        transform: translateY(0px);
    }

    30% {
        transform: translateY(0px);
    }

    40% {
        transform: translateY(-6px);
    }

    60% {
        transform: translateY(-6px);
    }

    70% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes bounce_down {
    0% {
        transform: translateY(0px);
    }

    30% {
        transform: translateY(0px);
    }

    40% {
        transform: translateY(6px);
    }

    60% {
        transform: translateY(6px);
    }

    70% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes go_up {
    0% {
        height: 100%;
        top: 0px;
        bottom: auto;
    }

    8% {
        height: 0%;
        top: 0px;
        bottom: auto;
    }

    92% {
        height: 0%;
        top: auto;
        bottom: 0px;
    }

    100% {
        height: 100%;
        top: auto;
        bottom: 0px;
    }
}

@keyframes go_down {
    0% {
        height: 100%;
        top: auto;
        bottom: 0px;
    }

    8% {
        height: 0%;
        top: auto;
        bottom: 0px;
    }

    92% {
        height: 0%;
        top: 0px;
        bottom: auto;
    }

    100% {
        height: 100%;
        top: 0px;
        bottom: auto;
    }
}

@keyframes text_anim {

    /* 0% {
        opacity: 0.2;
    }

    8% {
        opacity: 1;
    }

    92% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    } */
    0% {
        opacity: 0;
    }

    30% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.app__sectiondata_beta_tag {
    background-color: #062134;
    padding: 1px 8px 2px 8px;
    font-size: 9px;
    color: #FFF;
    border-radius: 12px;
    position: absolute;
    /* top: 8px;
    right: 8px; */
    opacity: 1 !important;
    cursor: help;
}


/* SECTION MAP */
.app__sectionmap {
    /* background-color: var(--color-grey-main-1); */
    /* background-color: green; */
    position: fixed;
    top: 80px;
    /* right: 0px; */
    height: calc(100vh - 80px);
    /* width: calc(100vw - var(--width-navbar) - var(--width-section)); */
    transition: opacity 0.4s ease;
    /* padding: 0px 20px 20px 20px; */
    /* TO GET SCROLL BAR ON RIGHT OF SCREEN */
    right: 6px;
    width: calc(100vw - var(--width-navbar) - var(--width-section) - 6px);
    padding: 0px 14px 20px 20px;
    overflow: hidden;
}

.app__sectionmap_UI {
    /* background-color: var(--color-grey-main-1); */
    /* background-color: green; */
    position: fixed;
    left: 60px;
    top: 80px;
    /* right: 0px; */
    height: calc(100vh - 80px);
    /* width: calc(100vw - var(--width-navbar) - var(--width-section)); */
    transition: opacity 0.4s ease;
    /* padding: 0px 20px 20px 20px; */
    /* TO GET SCROLL BAR ON RIGHT OF SCREEN */
    right: 6px;
    width: calc(100vw - var(--width-navbar));
    padding: 0px 20px 20px 20px;
    overflow: hidden;
}

.app__sectionmap_test {
    width: 100%;
    height: 100%;
    background-color: red;
    border-radius: 14px;
}


/* ACCESS */
.app__sectiondata_noaccess_container {
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.app__sectiondata_noaccess_container_centered {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
}

.app__sectiondata_noaccess_text {
    color: rgb(173, 172, 172);
    font-weight: 600;
    text-align: center;
}

.app__sectiondata_noaccess_text_small {
    color: rgb(173, 172, 172);
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
    padding: 0 40px;
    margin-top: 4px;
}

.app__sectiondata_noaccess_text_note {
    color: var(--color-green-main-1);
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
    padding: 0 50px;
}

.app__update_capa_modal_container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    background-color: rgba(100, 100, 100, 0.2);
}

.app__update_capa_modal {
    width: calc(100% - 60px - 100px);
    height: calc(100% - 100px);
    margin-left: 60px;
    border-radius: 14px;
    background-color: #062134;
}

.app__tag {
    background-color: #062134;
    border-radius: 10px;
    padding: 1px 8px 3px;
    font-size: 8px;
    color: #FFF;
}


/* RESPONSIVE */
@media screen and (max-width: 1050px) {

    /* SECTION DATA */
    .app__sectiondata,
    .app__sectiondata_UI {
        width: calc(100vw - var(--width-navbar));
        height: calc(50vh - 30px);
        left: var(--width-navbar);
        top: calc(50vh + 30px);

        padding: 0px;
    }

    .app__sectiondata_container,
    .app__sectiondata_container_UI {
        width: 100%;
    }

    .app__sectiondata_body_container {
        height: auto;
        min-height: 0px;
        padding: 0px 20px 20px 20px;
    }

    .app__sectiondata_container:last-of-type div.app__sectiondata_body_container,
    .app__sectiondata_container_UI:last-of-type div.app__sectiondata_body_container {
        min-height: 0px;
    }

    .app__sectiondata_title_container,
    .app__sectiondata_title_container_UI {
        width: 100%;
        top: 0px;
    }

    .app__sectiondata_loading_container {
        height: 200px;
    }

    .app__sectiondata_loading_container_medium1 {
        height: 200px;
    }


    /* SECTION SEPARATOR */
    .app__sectionseparator {
        top: 50vh;
        /* width: calc(100vw - var(--width-navbar));
        left: var(--width-navbar); */
    }


    /* SECTION MAP */
    .app__sectionmap,
    .app__sectionmap_UI {
        left: var(--width-navbar);
        height: calc(50vh - 80px);
        width: calc(100vw - var(--width-navbar));
        padding: 0px 20px;
    }

}

@media screen and (max-width: 550px) {

    /* SECTION DATA */
    .app__sectiondata,
    .app__sectiondata_UI {
        /* width: 100%;
        height: calc(50vh - 110px);
        left: 0px; */
        /* HORIZONTAL SCROLL */
        width: 100vw;
        left: 0px;
        flex-direction: row;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        overflow-y: hidden;
        transition: none;
        transition: opacity 0.4s ease;
        border-radius: 0px;
    }

    .app__sectiondata_title_container,
    .app__sectiondata_title_container_UI {
        border-radius: 0px;
    }

    .app__sectiondata_container,
    .app__sectiondata_container_UI {
        /* HORIZONTAL SCROLL */
        min-width: 100vw;
        overflow-y: auto;
        scroll-snap-align: center;
        box-shadow: none;
        margin-bottom: 0px;
        border-radius: 0px;
    }

    .app__sectiondata_container_UI:last-child {
        margin-bottom: 0px;
    }

    .app__sectiondata_body_container,
    .app__sectiondata_body_container_UI {
        margin-bottom: 0px;
        /* padding-bottom: 0px; */
        min-height: calc(100% - 50px);
    }

    .app__sectiondata_widget_scale_item {
        padding: 4px 4px;
    }

    .app__sectiondata_loading_container_medium2 {
        height: calc(100% - 140px);
    }

    /* SECTION SEPARATOR */
    .app__sectionseparator {
        left: calc(50vw - 90px);
    }

    .app__sectionseparator_handler {
        width: 110px;
    }

    .app__sectionseparator_message {
        font-size: 9px;
    }


    /* SECTION MAP */
    .app__sectionmap,
    .app__sectionmap_UI {
        left: 0px;
        height: calc(50vh - 80px);
        width: 100vw;
    }

}







.app_block_test_1 {
    background-color: blue;
    width: 100%;
    height: 300px;
}

.app_block_test_2 {
    background-color: orange;
    width: 100%;
    height: 300px;
}