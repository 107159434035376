.titletext__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    margin: 1rem;
}

.titletext__text_container {
    flex: 1;
    max-width: 390px;
    display: flex;
}

.titletext__text_container p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-blue-secondary-1);
}